export const getTestimonialQuery = `query getTestimonials {
    getTestimonials{
      testimonials{
        id
        studentName
        studentInfo
        description
        rating
        createdAt
      }
    }
  }`

  export const insertSubscriber = `mutation insertSubscriber($input: NewSubscriber!){
    insertSubscriber(newSubscriber: $input){
      status
      error{
        message
      }
    }
  }`;

export const getKickers = `query getKickers {
  getKickers{
    kickers{
      id,
      heading
      subheading
    }
  }
}`;