import React from "react";
import ThankYou from "../components/StaticComponent/ThankYou";

const ThankYouPage = () => {
  return  <div className='w-[100%] bg-white border-4 border-white' >
           <ThankYou/>
         </div>;
};

export default ThankYouPage;
