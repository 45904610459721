import React from "react";
import "./AboutUs.module.css";
import bannerDesktop from "../../assets/aboutus/desktop-about.png";
import bannerMob from "../../assets/aboutus/mobile-about.png";
import mission from "../../assets/aboutus/MaskGroup-26.png";
import offericon1 from "../../assets/aboutus/Group-1844.png";
import offericon2 from "../../assets/aboutus/Group-1842.png";
import offericon3 from "../../assets/aboutus/Group-1846.png";
import offericon4 from "../../assets/aboutus/Group-1848.png";
import girlimage from "../../assets/aboutus/girl-laptop.png";
import { PLAY_STORE_URL } from "../../constants";


const Aboutus = () => {
  const navigateToPlayStore = () => {
    window.open(PLAY_STORE_URL, "_blank");
  };
  return (
    <>
      <div className="about-bannner md:pt-[0px] xs:pt-[60px]">
        <picture>
          <source media="(max-width:767px)" srcSet={bannerMob} />
          <img src={bannerDesktop} alt="Banner" className="w-full" />
        </picture>
      </div>

      <div className="about-top-section lg:pt-[60px] lg:pb-[150px] pt-8 pb-8">
        <div className="container-wrap-w">
          <div className="grid lg:grid-cols-5 gap-4">
            <div className="col-span-2">
              <h1 className="font-poppinsBold text-[#111F58] fs-50w leading-none">
                About Us
              </h1>
            </div>
            <div className="col-span-3">
              <p className="font-poppinsRegular fs-30w text-[#000000]">
                At Maths.Engineering, we're revolutionizing the way engineering
                students master mathematics. Our platform is the go-to
                destination for learners seeking to demystify complex
                engineering math concepts through clear, concise, and engaging
                animated videos.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ourmission-wrap bg-[#EAEEF9] lg:py-11 mb-[5rem] pt-8 pb-8">
        <div className="container-wrap-w">
          <div className="grid lg:grid-cols-7 lg:gap-5 xs:gap-3 items-center">
            <div className="col-span-3">
              <div className="font-poppinsBold text-[#111F58] fs-40w leading-none lg:mb-4 mb-3">
                Our Mission
              </div>
              <p className="font-poppinsRegular fs-25w text-[#000000]">
                Our mission is to make engineering mathematics accessible and
                enjoyable for students across the globe. We believe in
                empowering learners by providing them with high-quality
                educational content that's not only easy to understand but also
                directly aligned with their university syllabus.
              </p>
            </div>
            <div></div>
            <div className="col-span-3">
              <div className="image-wrap ml-auto lg:mt-[-5rem] mb-[-5rem]">
                <img src={mission} className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="whatwe-offer lg:pt-[50px] lg:pb-[150px] pt-8 pb-[6rem]">
        <div className="container-wrap-w">
          <div className="font-poppinsBold text-[#111F58] fs-50w leading-none lg:mb-[3rem] mb-8">
            What We Offer
          </div>
          <div className="grid lg:grid-cols-2 lg:gap-[4.5rem] gap-8">
            <div className="offer-items ">
              <div className="offerimg mb-5">
                <img
                  src={offericon1}
                  alt="offers"
                  className="xl:w-[120px] lg::w-[110px] xs:w-[90px]"
                />
              </div>
              <p className="mb-3 leading-none font-poppinsSemibold fs-40w text-[#111F58] wrap-balance">
                Tailored Learning:
              </p>
              <p className="mb-8 font-poppinsRegular fs-25w text-[#000000] wrap-balance max-w-[95%] ">
                Courses meticulously crafted to match the syllabus of leading
                engineering universities, ensuring relevant and targeted
                learning.
              </p>
              <div className="border-[#D1DEDE] border-b-1"></div>
            </div>
            <div className="offer-items lg:mt-[4rem]">
              <div className="offerimg mb-5">
                <img
                  src={offericon2}
                  alt="offers"
                  className="xl:w-[120px] lg::w-[110px] xs:w-[90px]"
                />
              </div>
              <p className="mb-3  leading-none font-poppinsSemibold fs-40w text-[#111F58] wrap-balance">
                Animated Problem-Solving:
              </p>
              <p className="mb-8 font-poppinsRegular fs-25w text-[#000000] wrap-balance max-w-[95%]">
                Hundreds of animated, step-by-step solved problems that make
                learning interactive and effective.
              </p>
              <div className="border-[#D1DEDE] border-b-1"></div>
            </div>
            <div className="offer-items lg:mt-[-4rem]">
              <div className="offerimg mb-5">
                <img
                  src={offericon4}
                  alt="offers"
                  className="xl:w-[120px] lg::w-[110px] xs:w-[90px]"
                />
              </div>
              <p className="mb-3 leading-none font-poppinsSemibold fs-40w text-[#111F58] wrap-balance">
                Accessible Anywhere, Anytime:
              </p>
              <p className="mb-8 font-poppinsRegular fs-25w text-[#000000] wrap-balance max-w-[95%]">
                With our mobile app, students can learn on the go, fitting study
                time into their busy schedules seamlessly.
              </p>
              <div className="border-[#D1DEDE] border-b-1"></div>
            </div>
            <div className="offer-items ">
              <div className="offerimg mb-5">
                <img
                  src={offericon3}
                  alt="offers"
                  className="xl:w-[120px] lg::w-[110px] xs:w-[90px]"
                />
              </div>
              <p className="mb-3 leading-none font-poppinsSemibold fs-40w text-[#111F58] wrap-balance">
                Proven Success:
              </p>
              <p className="mb-8 font-poppinsRegular fs-25w text-[#000000] wrap-balance max-w-[95%]">
                Our platform is trusted by over 50,000 learners, with a track
                record of helping students achieve outstanding results.
              </p>
              <div className="border-[#D1DEDE] border-b-1"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="why-choose">
        <div className="container-wrap-w h-[100%]">
          <div className="grid lg:grid-cols-2 lg:gap-[5rem] items-center h-[100%]">
            <div className="girlimage mx-auto ">
              <img
                src={girlimage}
                alt="girl"
                className="h-[100%] w-full object-cover"
              />
            </div>
            <div className="rightbox md:bg-transparent xs:bg-[#EAEEF9] md:p-[0px] xs:py-8 xs:px-[4%] md:[mx-0px] xs:mx-[-4%]">
              <div className="font-poppinsBold text-[#111F58] fs-40w leading-none lg:mb-7 mb-3">
                Why Choose Us?
              </div>
              <p className="font-poppinsRegular fs-25w text-[#000000]">
                With Maths.Engineering, say goodbye to rote memorization and
                embrace a learning experience that's engaging, intuitive, and
                tailored to your academic needs. Our platform is designed with
                the student in mind, making us the perfect partner in your
                journey to academic success in engineering mathematics.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="join-community bg-[#0E78BE] lg:pt-[7rem] lg:pb-[7rem] pt-8 pb-[5rem]">
        <div className="container-wrap-w">
          <div className="mx-auto text-center max-w-4xl wrap-balance">
            <div className="text-[#fff] fs-40w font-poppinsSemibold mb-3">
              Join Our Community
            </div>
            <p className="text-[#fff] fs-25w lg:mb-8 mb-6 font-poppinsRegular">
              Become part of a growing community of learners who have turned
              their mathematical challenges into triumphs. Start your journey to
              mastering engineering math with ease and confidence today!
            </p>
            <div onClick={navigateToPlayStore} className="btn-wrap">
              <a
                href="javascript:;"
                className="fw-26w font-poppinsSemibold rounded-[10px] bg-[#F6AE35] xl:h-[60px] md:h-[48px] xs:h-[44px] p-3 pl-6 pr-6 inline-flex items-center justify-center"
              >
                JOIN NOW AND START LEARNING
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
      <div className="w-[80%] m-auto md:mt-[10%] mt-[23%] font-poppinsRegular text-[14px] bg-white leading-[2rem]">
        <div className="mb-[2rem]">
          <p className="text-center font-poppinsSemibold text-[2.7rem]">
            About us
          </p>
        </div>
        <div className="mb-[1.3rem]">
          <p>
            Maths.Engineering platform is a digital guide that covers all topics
            that are relevant to engineering mathematics. The platform function
            both as an instructional tool for faculties and as a self-learning
            tool for students.
          </p>
        </div>
        <div className="mb-[1.3rem]">
          <p>
            Our digital platform is the first of its kind, to use immersive
            video’s of solved problems to help learners to easily understand the
            mathematics concepts. Every video will have step by step animations
            along with quick tips which will help learners to excel will in
            their examinations.
          </p>
        </div>
        <div className="mb-[1.3rem]">
          <p>
            Our Platform covers many of the leading Technical (State, Deemed,
            Private) universities syllabus Patterns. We’re constantly adding
            more mathematics topics to ensure we cover all Engineering
            Mathematics topics at global level.
          </p>
        </div>
        <div>
          <p className="font-poppinsSemibold text-[2rem] mt-[2.7rem] mb-[1.3rem]">
            Freemium Model
          </p>
          <p>
            This is a Freemium Model platform where learners are given options
            to view a set of solved problems videos at no cost. If learners wish
            to access the complete course they can upgrade to premium model by
            paying a nominal subscription fees.
          </p>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default Aboutus;
