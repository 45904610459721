import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import Course from '../pages/course/Course'
import CourseDetails from '../pages/CourseDetails'
import AboutUs from '../pages/AboutUs'
import TermsCondition from '../pages/TermsCondition'
import Topics from '../pages/Topics'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import SearchResult from '../pages/SearchResult'
import ContactUs from '../pages/ContactUs'
import UniversityDetails from '../pages/UniversityDetails'
import ThankYouPage from '../pages/ThankYouPage'
import KnowMorePage from '../pages/KnowMorePage'



export const Router = () => {
  return (
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/course/*' element={<Course/>}></Route>
        <Route path='/course/:courseCode/:courseName' element={<CourseDetails/>} />
        <Route path="/about-us"   element={<AboutUs/>}/>
        <Route path="/privacy-policy"   element={<PrivacyPolicy/>}/>
        <Route path="/terms-conditions" element={<TermsCondition/>}/>
        <Route path='/topic' element={<Topics/>}/>
        <Route path='/search-result' element={<SearchResult/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/pages/:universityName/:locationCode' element={<UniversityDetails/>}/>
        <Route path='/thankyou' element={<ThankYouPage/>}/>
        <Route path='/knowmore' element={<KnowMorePage/>}/>
        {/* <Route path='/test/' element={<><VideoCarousel/><VCmain/></>} /> */}
        {/* <Route path='/test/' element={<><ImageCollage/></>} /> */}
    </Routes>
  )
}
