import React from 'react'

const ContactDetails = () => {
  return (
    <div className='md:block hidden py-6'>
        <p className='text-[#111F58] font-poppinsBold text-[40px] pb-2'>Sales & Support</p>
        <div>
            <p className='font-poppinsRegula'>Sales: <a href="mailto:support@maths.engineering" className='font-poppinsMedium text-[#115DE2] underline'>sales@maths.engineering</a></p>
            <p className='font-poppinsRegular py-1'>Support: <a href="mailto:support@maths.engineering" className='font-poppinsMedium text-[#115DE2] underline'>support@maths.engineering</a></p>
            <p className='font-poppinsRegular pb-1'>Contact: +919360922729</p>
        </div>
    </div>
  )
}

export default ContactDetails