export default class APIProxy {
  authHeaders = {
    token: '',
    email: '',
    userFuzzyId: '',
  };

  getUserFuzzyId = () => {
    return this.authHeaders.userFuzzyId;
  };

  updateCredentialHeaders(credentials:any) {
    this.authHeaders = {
      token: credentials.token,
      email: credentials.email,
      userFuzzyId: credentials.id,
    };
  }

  get(url:string, action:any) {
    fetch(url, {
      headers: {
        ...this.authHeaders,
      },
    })
      .then(response => response.json())
      .then(json => {
        action(json);
      });
  }

  post(url:string, bodyParams:object, action:any) {
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...this.authHeaders,
      },
      body: JSON.stringify(bodyParams),
    })
      .then(response => response.json())
      .then(json => {
        action(json);
      });
  }

  query(url:string, queryString:string, variables:object) {
    return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'access-control-allow-origin': '*',
        ...this.authHeaders,
      },
      body: JSON.stringify({ query: queryString, variables }),
    });
  }

  mutate(url:string, mutationQueryString:string, variables:object) {
    return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'access-control-allow-origin': '*',
        ...this.authHeaders,
      },
      body: JSON.stringify({ query: mutationQueryString, variables }),
    });
  }

  // async post(url, bodyParams) {
  //   const response = await fetch(url, {
  //      method: "POST",
  //      headers: {
  //        Accept: "*/*",
  //        ...this.authHeaders,
  //      },
  //      body: bodyParams,
  //    });
  //    return await response.json();
  //  }

  asyncDelete(url:string, bodyParams:any) {
    return fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'access-control-allow-origin': '*',
        ...this.authHeaders,
      },
      body: JSON.stringify(bodyParams),
    });
  }

  getAsync(url:string) {
    return fetch(url, {
      headers: {
        ...this.authHeaders,
      },
    });
  }
}
