import React, { useContext } from "react";
import CourseTopicCard from "./CourseTopicCard";
import { StoreContext } from "../../../storeContext";
import { observer } from "mobx-react";

const CourseCurriculum = () => {
  
  const { courseDetailStore } = useContext(StoreContext);
  
  return (
    <div className="md:pl-[8%] md:mt-[-2%]">
       <div className="md:ml-[0%] w-[90%] lg:w-[57.5%]  bg-white overflow-hidden border border-solid border-gray-300 rounded-[10px] shadow-md  mt-[5%] xs:mx-auto z-100 relative ">
      <p className="font-poppinsSemibold bg-[#F0F3FB] text-[#3D7BE7] fs-30w  px-[1rem] py-[.8rem]">
        {courseDetailStore?.courseMeta[0]?.universityCode === "GLOBAL" ? "Topic Curriculum" : "Course Curriculum"}
      </p>
      <div className="bg-white ">
        <div>
          {courseDetailStore?.partChapters?.map((ele, index) => (<CourseTopicCard key={index} index={index} partItem={ele.part} chapters={ele.chapters} />))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default observer(CourseCurriculum);
