import React, { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { StoreContext } from '../../../storeContext';
import CourseItemCard from './CourseItemCard';
import ViewMoreCard from './ViewMoreCard';
import { observer } from 'mobx-react';
import { Course } from '../../../services/Utils';

const CourseScroll = () => {

  const sliderRef = useRef<Slider>(null);

 const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: false,
    adaptiveHeight:false,
    slidesPerRow: 1, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
    
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
};

  const { courseStore } = useContext(StoreContext);

  const [courseList, setCourseList] = useState<Course[]>([]);

  const arrangeCourses = () => {
    const allCourses: Course[] = [];

    if(courseStore.universities.length > 0){
    courseStore.universities
      .filter(ele => courseStore.isUniversitySelected ? ele.university.code === courseStore.universityFilterCode : ele)
      .forEach((item) => {
        item.courses.slice(0, 4).forEach((ele: Course) => {
          allCourses.push(ele);
        });
      });
    }
    else{
      courseStore.topics
      .slice(0, 4).forEach((ele: any) => {
          allCourses.push(ele);
        });
    }

    setCourseList(allCourses);
  };
  const viewMore = {
    "code": "",
    "name": "",
    "regulations": "",
    "semester": "",
    "university": "",
    "aboutSubjects": "",
    "aboutChapters": "",
    "subjectCode":"",
  }
  
  const extendedCourseList = [...courseList, viewMore];
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    arrangeCourses()
  }, [courseStore.universities, courseStore.universityFilterCode])

  return (
    <>
    {extendedCourseList.length > (screenWidth < 768 ? 2 : 3)
    ? 
    <div>
      <div style={{width:"100%",marginTop:"0.1rem",overflow:"hidden"}} className='md:px-[8%] xs:px-[5%] md:w-[85%]  sliderMobHome'>
      <Slider {...settings} ref={sliderRef}>
        {extendedCourseList.map((item, index) => (
          index !== courseList.length ? <CourseItemCard key={index} course={item} count={extendedCourseList.length} screenWidth={screenWidth} /> : <ViewMoreCard key={index} count={extendedCourseList.length} screenWidth={screenWidth} />
        ))}
      </Slider>
    </div>
    </div>
    :
    <div className='w-[100%] md:px-[8%] xs:px-[3%] mb-12 '>
      <div className='grid lg:grid-cols-4 md:gap-[16px] xs:gap-[16px] md:grid-cols-3 sm:grid-cols-2 sxsm:grid-cols-2'>
        {extendedCourseList.map((item, index) => (
          index !== courseList.length ? <CourseItemCard key={index} course={item} count={extendedCourseList.length} screenWidth={screenWidth} /> : <ViewMoreCard key={index} count={extendedCourseList.length} screenWidth={screenWidth}/>
        ))}
      </div>
    </div>
    }
    </>
  );
};

export default observer(CourseScroll);