import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Select from "react-select";
import { Text } from "@fluentui/react/lib/Text";
import { StoreContext } from "../../../storeContext";
import { IoFilter } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";

const FilterStack = () => {
  const { courseStore } = useContext(StoreContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [university, setUniversity] = useState(null);
  const [semester, setSemester] = useState(null);
  const [regulation, setRegulation] = useState(null);
  const [selectedUniversity, setSelectedUniversity]=useState("")

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchLocations = async () => {
    await courseStore.fetchAllLocations();
  };

  const fetchUniversities = async (location: any) => {
    await courseStore.fetchUniversities(location);
}

  const onLocationChange = (e: any) => {
    setUniversity(null);
    setSemester(null);
    setRegulation(null);
    courseStore.selectedLocation.value = e.value;
    courseStore.selectedLocation.label = e.label;
    courseStore.universityFilterCode = "";
    courseStore.semesterFilterCode = "";
    courseStore.regulationFilterCode = "";
  };

  const onUniversityChange = (value: any) => {
    setUniversity(value);
    setSemester(null);
    setRegulation(null);
    if(value.value === "All"){
      return courseStore.universityFilterCode = "";
    }
    courseStore.universityFilterCode = value.value;
    courseStore.semesterFilterCode = "";
    courseStore.regulationFilterCode = "";
  };

  const onSemesterChange = (value: any) => {
    setSemester(value);
    setRegulation(null);
    if(value.value === "All"){
      return courseStore.semesterFilterCode = "";
    }
    courseStore.semesterFilterCode = value.value;
    courseStore.regulationFilterCode = "";
  }

  const onRegulationChange = (value: any) =>{
    setRegulation(value);
    if(value.value === "All"){
      return courseStore.regulationFilterCode = "";
    }
    courseStore.regulationFilterCode = value.value;
  }

  const states = courseStore.locations.map((proxyObj: any) => {
    return {
      value: proxyObj.code,
      label: proxyObj.name,
    };
  });

  const universities = courseStore.universities.map((proxyObj: any) => {
    return {
      value: proxyObj.university.code,
      label: proxyObj.university.name,
    };
  });

  const universityOptions = [ ...universities]

  const setSemesters = () => {
    let semesters: { value?: string; label?: string; }[] = [];

    courseStore.universities
    .filter(ele => courseStore.isUniversitySelected ? courseStore.universityFilterCode === ele.university.code : ele)
    .map((item, index)=>{
      item.courses.map((ele, ind)=>{
        const isExist = semesters.some(x => x.value === ele.semester);
        if(!isExist){
          return semesters.push({ value: ele.semester, label: ele.semester });
        }
      })
    })
  
    const semesterOptions = [ ...semesters]
    return semesterOptions;

  }

  const setRegulations = () => {
    let regulations: { value?: string; label?: string; }[] = [];
  
    courseStore.universities
    .filter(ele => courseStore.isUniversitySelected ? courseStore.universityFilterCode === ele.university.code : ele)
    .map((item, index)=>{
      item.courses.map((ele, ind)=>{
        const isExist = regulations.some(x => x.value === ele.regulations);
        if(!isExist){
          return regulations.push({ value: ele.regulations, label: ele.regulations });
        }
      })
    })
  
    const regulationOptions = [ ...regulations];
    return regulationOptions;
  }

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(()=>{
    fetchUniversities(courseStore.selectedLocation.value);
}, [courseStore.selectedLocation.value])

useEffect(()=>{
  setSelectedUniversity((universities.filter(ele=>ele?.value===courseStore?.universityFilterCode)[0]?.label))
},[courseStore.universityFilterCode])
useEffect(()=>{
  setSemesters();
  setRegulations();
}, [courseStore.universityFilterCode]) 

  return (
    <div className="flex flex-col items-center w-full bg-white shadow-md p-2  md:px-[8%] xs:px-[4%]">
      <div className="flex flex-row items-center w-full cursor-pointer">
        {screenSize < 900 && <IoFilter className="text-3xl text-[#115DE2] mr-3" />}

        <Text className="font-bold fs-30w font-poppinsSemibold leading-none ">Filter</Text>
        {screenSize > 900 && (
          <div className="flex flex-row justify-end w-4/5 ml-8 space-x-4">
            <Select
              placeholder="State"
              value={courseStore.selectedLocation}
              options={states}
              onChange={onLocationChange}
              className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#EBF4F6",
                  borderColor: "transparent",
                  color: "#000",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              dropdownIndicator: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              singleValue: (provided) => ({
                  ...provided,
                  color: 'black', // Style for the selected option
              })
              }}
            />
            <Select
              placeholder="University"
              value={courseStore.isUniversitySelected ?{value:courseStore.universityFilterCode,label:selectedUniversity}:university}
              options={universityOptions}
              onChange={onUniversityChange}
              className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#EBF4F6",
                  borderColor: "transparent",
                  color: "#000",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              dropdownIndicator: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              singleValue: (provided) => ({
                  ...provided,
                  color: 'black', // Style for the selected option
              })
              }}
            />
            <Select
              placeholder="Semester"
              value={semester}
              options={setSemesters()}
              onChange={onSemesterChange}
              className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#EBF4F6",
                  borderColor: "transparent",
                  color: "#000",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              dropdownIndicator: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              singleValue: (provided) => ({
                  ...provided,
                  color: 'black', // Style for the selected option
              })
              }}
            />
            <Select
              placeholder="Regulation"
              value={regulation}
              options={setRegulations()}
              onChange={onRegulationChange}
              className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#EBF4F6",
                  borderColor: "transparent",
                  color: "#000",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              dropdownIndicator: (provided) => ({
                  ...provided,
                  color: '#000',
              }),
              singleValue: (provided) => ({
                  ...provided,
                  color: 'black', // Style for the selected option
              })
              }}
            />
          </div>
        )}
        {screenSize < 900 && (
          <div className="flex items-center w-full justify-end h-[40px]">
            <IoMdArrowDropdown
              className="text-3xl cursor-pointer "
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            />
          </div>
        )}
      </div>

      {/* Dropdown Content */}
      <div
        className={`flex flex-col w-full mt-2 ${
          isDropdownOpen ? "" : "hidden"
        }`}
      >
        <Select
          placeholder="State"
          value={courseStore.selectedLocation}
          options={states}
          onChange={onLocationChange}
          className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "#EBF4F6",
              borderColor: "transparent",
              color: "#000",
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#000',
          }),
          dropdownIndicator: (provided) => ({
              ...provided,
              color: '#000',
          }),
          singleValue: (provided) => ({
              ...provided,
              color: 'black', // Style for the selected option
          })
          }}
        />
        <Select
          placeholder="University"
          value={university}
          options={universityOptions}
          onChange={onUniversityChange}
          className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "#EBF4F6",
              borderColor: "transparent",
              color: "#000",
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#000',
          }),
          dropdownIndicator: (provided) => ({
              ...provided,
              color: '#000',
          }),
          singleValue: (provided) => ({
              ...provided,
              color: 'black', // Style for the selected option
          })
          }}
        />
        <Select
          placeholder="Semester"
          value={semester}
          options={setSemesters()}
          onChange={onSemesterChange}
          className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "#EBF4F6",
              borderColor: "transparent",
              color: "#000",
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#000',
          }),
          dropdownIndicator: (provided) => ({
              ...provided,
              color: '#000',
          }),
          singleValue: (provided) => ({
              ...provided,
              color: 'black', // Style for the selected option
          })
          }}
        />
        <Select
          placeholder="Regulation"
          value={regulation}
          options={setRegulations()}
          onChange={onRegulationChange}
          className="w-full p-2 rounded-[5px] font-poppinsRegular fs-20w"
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "#EBF4F6",
              borderColor: "transparent",
              color: "#000",
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#000',
          }),
          dropdownIndicator: (provided) => ({
              ...provided,
              color: '#000',
          }),
          singleValue: (provided) => ({
              ...provided,
              color: 'black', // Style for the selected option
          })
          }}
        />
      </div>
    </div>
  );
};

export const Filter = observer(FilterStack);
