import React, { useEffect, useState } from 'react'
import { Menu } from '../components/Header'
import { Uniqueness } from '../components/Home/Uniqueness/Uniqueness'
import { VideoCarousel } from '../components/Home/Uniqueness/VideoCarousel'
import { VCmain } from '../components/Home/Uniqueness/VCmain'

import Collaboration from '../components/Home/Collaboration/Collaboration'
import { BrowseCoursesComponent } from '../components/Home/BrowseCourses'
import FAQ from '../components/Home/FAQ/FAQ'
import Testimonials from '../components/Home/testimonials/Testimonials'
import Main from '../components/Home/Main/Main'
import FloatButton from '../components/StaticComponent/FloatButton'
import DownloadPopup from '../components/StaticComponent/DownloadPopup'
import { setTitle } from '../services/Utils'



const Home = () => {


  useEffect(()=>{
    const meta ="Maths.Engineering is the best Digital Guide (book or study material) for all engineering mathematics students, as the syllabus is covered as per your university and previous year SOLVED QUESTION papers available with STEP-BY-STEP animated videos and PDF."
    setTitle("BEST Digital Guide for Engineering Mathematics",meta);
 
  },[])
  return (
    <div className=' overflow-hidden'>
      
      <Main/>
      <BrowseCoursesComponent/>
      <Uniqueness/>
      <VCmain/>
      <Testimonials/>
      <VideoCarousel/>
      <Collaboration/>
      <FAQ/>
      <DownloadPopup/>
    </div>
  )
}

export default Home