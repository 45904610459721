// BlurOverlay.jsx

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

interface BlurOverlayProps {
  isVisible: boolean;
  children: React.ReactNode;
}

const BlurOverlay: React.FC<BlurOverlayProps> = ({ isVisible, children }) => {
  useEffect(() => {
    const body = document.body;

    if (isVisible) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }

    return () => {
      body.style.overflow = 'auto'; // Reset overflow when the component is unmounted
    };
  }, [isVisible]);

  return isVisible ? (
    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[900]'>
      {children}
    </div>
  ) : null;
};

BlurOverlay.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default BlurOverlay;
