import React, { useContext, useState } from "react";
import G from "../../assets/png-transparent-google-play-logo-google-play-computer-icons-app-store-google-text-logo-sign-removebg.png";
import Icon from "../../assets/Logo/Maths.Engineering Logo (1).png";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import Gmail from "../../assets/vuesax-linear-sms.svg";
import Call from "../../assets/vuesax-linear-call-calling.svg";
import {
  YOUTUBE_URL,
  PLAY_STORE_URL,
  LINKEDIN_URL,
  FACEBOOK_URL,
  TWITTER_URL,
  INSTAGRAM_URL,
} from "../../constants";
import { observer } from "mobx-react";
import { StoreContext } from "../../storeContext";
import { Link, useNavigate } from "react-router-dom";
import RightArrow from "../../assets/vuesax-broken-arrow-right.svg";
import card_2 from "../../assets/Footer/Card 2.png";
import card_1 from "../../assets/Footer/Card 1.png"

const FooterComponent = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const { homeStore } = useContext(StoreContext);
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [validation, setValidation] = useState("off");
  const [alertMsg, setAlertMsg] = useState("");
  const { courseDetailStore } = useContext(StoreContext);

  const navigate = useNavigate();
  const navigateToPlayStore = () => {
    window.open(PLAY_STORE_URL);
  };
  const handleNavigate = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  const handleNavigateTOPages = (value: any) => {
    window.open(`/${value}`, "_blank");
    window.scrollTo(0, 0);
  };
  const closeAlert = () => {
    setShowAlert(false);
    setAlertMsg("");
  };

  const handleValidation = (e: any) => {
    setValidation((prev) => (prev === "off" ? "on" : "off"));
  };

  const insertNewSubscriber = async () => {
    let newSubscriber = {
      emailId: email,
    };
    await homeStore.insertSubscriber(newSubscriber);
    let res = homeStore.insertSubscriberResult;
    setEmail("");
    if (res.error === null) {
      setValidation("off");
      setAlertMsg("Thank you for showing interest");
      return setShowAlert(true);
    }
    alert("Please try again.");
  };

  const handleSubmit = () => {
    if (email === "" || !emailRegex.test(email)) {
      setAlertMsg("Please enter a valid email");
      return setShowAlert(true);
    }
    if (validation === "off") {
      setAlertMsg("Please Accept Privacy Policy");
      return setShowAlert(true);
    }
    insertNewSubscriber();
  };
  const handleCloseButtonClick = () => {
    closeAlert();
    setEmail(""); // Clear the email input field
  };

  return (
    <div
      id="footer"
      ref={courseDetailStore.customScroll.footerRef}
      className="md:py-[2%] xs:pt-[2%] xs:pb-[6%] bg-[#EAEEF9] relative z-[120]"
    >
      <div className="msm:px-16 bg-[#2FADE2] md:mx-[8%] xs:mx-[4%] mb-[-70px] rounded-lg shadow-md relative z-[103] xl:h-[250px] lxl:h-[240px] xlg:h-[230px] smlg:h-[215px] msm:h-[200px] md:h-[185px] lsm:h-[210px]  xs:h-[170px] flex sm:flex-row flex-col justify-between sm:items-center footermargin-about">
        <div className="flex flex-col w-full sm:w-[80%] sm:w-6/9 font-poppinsRegular xs:px-2 md:px-2 py-8 text-center sm:text-left">
          <p className="text-white xl:text-[60px] lxl:text-[55px] xlg:text-[50px] smlg:text-[45px] msm:text-[40px] md:text-[35px] lsm:text-[30px] xs:text-[25px] xs:mt-[-1rem] md:mt-auto font-poppinsMedium">
          Get Our Learning App
          </p>
          <p className="text-white xl:text-[30px] lxl:text-[28px] xlg:text-[26px] smlg:text-[24px] msm:text-[22px] md:text-[22px] lsm:text-[20px] xs:text-[15px]">
            Learn Anytime & Anywhere
          </p>
        </div>
        <div className="flex justify-center items-center xl:h-[85.36px] xlg:h-[75px] smlg:h-[65px]  md:h-[54px] lsm:h-[48.73] xs:h-[44.73px] xl:w-[290px] xlg:w-[270px] smlg:w-[240px]  md:w-[200px] lsm:w-[165.57px] xs:w-[145.57px] m-auto   p-1 2xl:p-0 2xl:mr-[50px] xs:mt-[-4px] hsm:mt-[-2px] sm:mt-auto">
          <img
            src={G}
            onClick={navigateToPlayStore}
            className="cursor-pointer"
            alt="Google Play Logo"
          />
        </div>
      </div>
      <div className="bg-[#F6F6F9]  pt-12">
      <div className="bg-[#F6F6F9]  md:mt-[6%] mt-[16%]  md:mb-0 xs:mb-[-1%] md:px-[16%] px-[4%] flex md:flex-row flex-col justify-between gap-6">
      <img src={card_1} className="md:w-[45%] w-[100%] md:mx-0 xs:mx-auto" alt="card-2" />
        <img src={card_2}  className="md:w-[45%] w-[100%] md:mx-0 xs:mx-auto" alt="card-2" />
      </div>
      </div>
      <div className="bg-[#F6F6F9] md:px-[8%] xs:px-[5.7%] pb-[50px] w-[100%] z-[102] flex flex-row justify-between pt-[75px] relative flex-wrap sm:flex-no-wrap">
        <div className="w-full sm:w-[40%] ">
          <div className="mb-5 flex justify-center sm:justify-start">
            <img
              src={Icon}
              onClick={() => {
                handleNavigate();
              }}
              className={`xl:h-[37.52px] xlg:h-[36px] smlg:h-[35px]  md:h-[34px] xs:h-[33.83px] xl:w-[184.13px] xlg:w-[180px] smlg:w-[176px]  md:w-[171px] xs:w-[166px]`}
              alt="Icon"
            />
          </div>

          <div className="xl:text-[30px] lxl:text-[29px] xlg:text-[29px] smlg:text-[28px] msm:text-[27px] md:text-[26px] xs:text-[25px] mb-5 font-poppinsSemibold sm:text-left text-center text-[#111F58] leading-8">
            Stay up to date with the latest insights
          </div>
          <div className="flex justify-start mb-3">
            <div className="relative w-full font-poppinsLight">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                className="border-b border-gray-400 p-2 w-full pr-10 bg-transparent focus:outline-none xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px]"
              />
              <div
                onClick={handleSubmit}
                className="absolute inset-y-0 right-0 flex text-orange-500 items-center pr-3"
              >
                <img src={RightArrow} />
              </div>
            </div>
          </div>

          <div className="flex justify-between  mt-4 mb-3">
            <div className="flex mt-[3px] xs:mr-4 md:mr-8  smlg:mr-10 xlg:mr-12">
              <input
                type="checkbox"
                checked={validation === "on"}
                onChange={handleValidation}
                className="h-[20px] w-[20px]"
              />
            </div>
            <p className="text-left font-poppinsRegular xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[15px] md:text-[14px] xs:text-[12px]">
              By submitting your email you acknowledge that you have read and
              understood our{" "}
              <a
                onClick={() => {
                  handleNavigateTOPages("privacy-policy");
                }}
                className="cursor-pointer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>

        <div className="w-full sm:w-[50%] flex flex-col ">
          <div className=" flex flex-col sm:flex-row sm:text-left text-center justify-between border-b border-gray-500 pb-5">
            <div className="flex flex-col xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px] font-poppinsRegular cursor-pointer">
              <Link
                to="/about-us"
                target="_blank"
                onClick={() => handleNavigateTOPages("about-us")}
              >
                About Us
              </Link>
              <Link
                to="/course"
                target="_blank"
                onClick={() => handleNavigateTOPages("course")}
              >
                Courses
              </Link>
              <Link
                to="/contact-us"
                target="_blank"
                onClick={() => handleNavigateTOPages("contact-us")}
              >
                Contact Us
              </Link>
              <Link
                to="/privacy-policy"
                target="_blank"
                onClick={() => handleNavigateTOPages("privacy-policy")}
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-conditions"
                target="_blank"
                onClick={() => handleNavigateTOPages("terms-conditions")}
              >
                Terms and Conditions
              </Link>
            </div>
            <div className="flex flex-col mt-[20px] sm:mt-[0px] m-auto xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px]  font-poppinsMedium">
              <p className="font-poppinsSemibold">Contact Us</p>
              <div className="flex flex-row gap-5 py-2">
                <a
                  href="mailto:support@maths.engineering"
                  className="flex flex-row gap-1 md:gap-5 items-center"
                >
                  <img src={Gmail} className="h-4 w-4" alt="Gmail" />
                  <p>support@maths.engineering</p>
                </a>
              </div>
              <div className="flex justify-center sm:justify-start flex-row gap-5">
                <a
                  href="tel:+919360922729"
                  className="flex justify-center sm:justify-start flex-row items-center gap-1 md:gap-5"
                >
                  <img src={Call} className="h-4 w-4" alt="Call" />
                  <p>+91 9360922729</p>
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row  m-auto sm:m-0 text-center justify-between items-center pt-5">
            <p className="font-poppinsBold text-[#111F58] xl:text-[30px] lxl:text-[28px] xlg:text-[26px] smlg:text-[24px] msm:text-[22px] md:text-[22px] xs:text-[20px] text-center">
              Connect with us
            </p>
            <div className="xl:w-[45%] lxl:w-[48%] xlg:w-[53%] smlg:w-[55%] msm:w-[50%] md:[60%] flex flex-row gap-2 sm:mt-0 mt-[10px] mx-auto">
              <a
                href={FACEBOOK_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex rounded-full p-2 border-2 border-black"
              >
                <FaFacebookF className="text-black w-[30px] h-[30px]" />
              </a>
              <a
                href={TWITTER_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center rounded-full p-2 border-2 border-black"
              >
                <BsTwitterX className="text-black w-[30px] h-[30px]" />
              </a>
              {/* <a
                href={LINKEDIN_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center rounded-full p-2 border-2 border-black"
              >
                <FaLinkedinIn className="text-black w-4 h-4 sm:h-2.5 sm:w-2.5 msm:h-4 msm:w-4" />
              </a> */}
              <a
                href={YOUTUBE_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center rounded-full p-2 border-2 border-black"
              >
                <FaYoutube className="text-black w-[30px] h-[30px] " />
              </a>
              <a
                href={INSTAGRAM_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center rounded-full p-2 border-2 border-black"
              >
                <GrInstagram className="text-black w-[30px] h-[30px]" />
              </a>
            </div>
          </div>
          <br />
          <div className="flex flex-row pt-5 border-t border-gray-500 font-poppinsMedium xl:text-[20px] lxl:text-[18px] xlg:text-[17px] smlg:text-[15px] msm:text-[13px] md:text-[11px] xs:text-[10px] text-center sm:text-left">
            <p className="w-full">
              © Copyrights {currentYear} maths.engineering. All Rights Reserved
            </p>
          </div>
        </div>
      </div>

      {/* Conditional rendering for the alert */}
      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1500]">
          <div className="bg-white p-6 rounded shadow">
            <p className="text-xl font-semibold mb-4">{alertMsg}</p>
            <button
              onClick={handleCloseButtonClick}
              className="bg-[#115DE2] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const Footer = observer(FooterComponent);
