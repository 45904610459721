export const getChapters = `query getChapters($input: String!) {
	getChapters(courseCode: $input){
    parts{
      part{
        code
        name
        meta
        courseCode
        priority
      }
      chapters{
        code
        name
        meta
        partCode
        priority
      }
    }
  }
}`

export const getReviewQuery = `query getReview($input:String!){
  getReviews(courseCode:$input){
    reviews{
      courseCode
      description
      name
      role
      org
      category
    }
  }
}`;


export const getCourseMeta = `query getCourseMeta($input: String!) {
  getCourseMeta(courseCode: $input) {
    courseMeta {
      id
      courseCode
      courseName
      universityCode
      universityName
      semester
      regulations
      price
      duration
      keywords
      description
      faqs
      urls
      advantages
      subjectCode
      videoImageUrl
    }
    error {
      message
    }
  }
}`;

export const insertEnquiry = `mutation ($input:NewLead!) {
  saveLead(leadData: $input) {
    status
    error{
      message
    }
  }
}`;