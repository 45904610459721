import { makeObservable, observable, action, computed } from "mobx";
import { apiHost } from "../../services/APIEndpoints";
import { fetchLocations, fetchUniversities, fetchTopicsQuery } from "./Query";
import {Course} from "../../services/Utils";

const INIT: string = "init";
const PENDING: string = "pending";
const DONE: string = "done";
const ERROR: string = "error";

interface Location {
  code: string,
  name: string,
}
interface University {
  code: string,
  name: string
}

interface Universities {
  university: University,
  courses: Course[]
}
interface Topic {
  code: string,
  name: string,
  regulations: String,
  semester: String,
  university: string,
  aboutSubjects: string,
  aboutChapters: String,
  subjectCode:string
}

interface SelectedLocation {
  value: String,
  label: String
}
class CourseStore {

  state: string = INIT;
  apiProxy: any;
  locations: Location[] = [];
  universities: Universities[] = [];
  universityFilterCode: string = "";
  semesterFilterCode: string = "";
  regulationFilterCode: string = "";
  topics: Topic[] = [];
  selectedLocation: SelectedLocation = {value: "TAMIL NADU", label: "TAMIL NADU"};
  selectedUnivesity:string="";
  constructor(apiProxy: any) {
    this.apiProxy = apiProxy;

    makeObservable(this, {
      state: observable,
      locations: observable,
      universities: observable,
      universityFilterCode: observable,
      semesterFilterCode: observable,
      regulationFilterCode: observable,
      topics: observable,
      selectedLocation: observable,

      isLoading: computed,
      isError: computed,
      isDone: computed,
      isUniversitySelected: computed,
      isSemesterSelected: computed,
      isRegulationSelected: computed,

      fetchAllLocations: action,
      fetchUniversities: action,
      fetchTopics: action
    });
  }

  get isLoading() {
    return this.state === PENDING;
  }

  get isError() {
    return this.state === ERROR;
  }

  get isDone() {
    return this.state === DONE;
  }

  get isUniversitySelected() {
    return this.universityFilterCode?.trim().length > 0
  }

  get isSemesterSelected() {
    return this.semesterFilterCode.trim().length > 0
  }

  get isRegulationSelected() {
    return this.regulationFilterCode.trim().length > 0
  }

  fetchAllLocations = async () => {

    try {
      let response = await this.apiProxy.query(apiHost, fetchLocations);
      let result = await response.json();
      this.locations = result.data.getLocations.locations;
      this.state = DONE
    } catch (error) {
      console.log(error);
      this.state = ERROR;
    }
  }

  fetchUniversities = async (stateCode: string) => {

    let variable = {
      input: stateCode
    }

    try {
      let response = await this.apiProxy.query(apiHost, fetchUniversities, variable);
      let result = await response.json();
      const data = result.data.getUniversitiesWithCourses.universities;
      this.universities = data.sort((a: any, b: any) => {
        return a.university.code.localeCompare(b.university.code);
    });
      this.state = DONE;
    } catch (error) {
      console.log(error);
      this.state = ERROR;
    }
  }

  fetchTopics = async () => {
    try{
      let response  = await this.apiProxy.query(apiHost, fetchTopicsQuery);
      let result = await response.json();
      this.topics = result.data.getTopics.topics;
      this.state = DONE;
    }catch(err){
      console.log(err);
      this.state = ERROR;
    }
  }

}

export default CourseStore;