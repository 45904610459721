import React from 'react';
import styles from "./LearnerSpeak.module.css";

interface Review {
  id:Number,
  courseCode:String,
  description:String,
  name: String,
  role:String,
  org:String
  category:String
}

interface LearnerSpeakCardProps {
  data: Review;
}

const LearnerSpeakCard:React.FC<LearnerSpeakCardProps> = ({data}) => {

  return (
    <div className={`${styles.cardContainer} flex-1auto`}>
        <div className='flex-1auto overflow-hidden'>
        <p className={`${styles.cardText} font-poppinsRegular fs-20w wrap-balance`}>{data?.description}</p>
        </div>
        <div className={`${styles.cardBanner} wrap-balance`}>
            <p className='font-poppinsMedium fs-20w'>{`${data?.name} - ${data?.role}`}</p>
            <p className='font-poppinsMedium fs-15w'>{data?.org}</p>
        </div>

        <div className='h-[48.5px]  pl-4 pt-3 text-[#115DE2] fs-20w font-poppinsSemibold rounded-lg bg-[#FFFF]'>{data?.category}</div>
    </div>
  )
}

export default LearnerSpeakCard

