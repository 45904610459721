import React, { useEffect, useState } from 'react';

interface University {
    code: String,
    name: String,
}

interface LearnerSpeakCardProps {
    data: University;
    index: number;
    flag: number;
    handleDivClick: () => void;
}

const UniversityNameCard: React.FC<LearnerSpeakCardProps> = ({ data, index, flag, handleDivClick }) => {

    const convertToTitleCase = (inputString: String): string => {

        const words = inputString.toLowerCase().split(' ');
    
        const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      
        return titleCaseWords.join(' ');
      };

    return (
        <div onClick={handleDivClick} className={` h-[50px] xxl:h-[90px] hover:cursor-pointer rounded-md md:px-[7px] flex items-center justify-center text-center leading-[15px]  ${index === flag ? 'bg-[#115DE2] text-[#FFFFFF]' : 'bg-[#22AAE11A] text-[#115DE2]'}`}>
            <p className='hidden'>{data.code}</p>
            <p className='fs-20w font-poppinsSemibold break-words' >{convertToTitleCase(data.name)}</p>
        </div>
    )
}

export default UniversityNameCard;

