import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../storeContext";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

const headerContent = "Dive deep into the Short Animated Videos,Important Semester Questions & Complete content as per your University Syllabus.";

interface BannerDetailProps { }

const Banner: React.FC<BannerDetailProps> = (BannerDetailProps) => {
  const [title, setTitle] = useState("");
  const { courseDetailStore } = useContext(StoreContext);

  const { courseCode } = useParams();
  const fetchCourseMeta = () => {
    if (courseCode) {
      courseDetailStore.fetchCourseMeta(courseCode);
      courseDetailStore.fetchChapters(courseCode);
      courseDetailStore.fetchReviews(courseCode);
      setTitle("test1jhif");
    } else {
      console.error("Course code is undefined.");
    }
  };

  useEffect(() => {
    fetchCourseMeta();
  }, [courseCode]);

  return (
    <div className="w-[100%] bg-[#F0F3FB] md:pt-[7%] xs:pt-[28%] xs:pb-[5%] md:pb-[3%]">
      <div className="md:ml-[8%] xs:m-auto md:w-[60%]">
        <div className="md:text-[3rem] md:text-start xs:text-center mt-[5px] px-[0px]">
          <div className="md:text-[3rem] md:text-start xs:text-center mt-[5px] px-[0px] mb-2" >
            <p className="text-[#111F58] md:font-bebasNeueRegular xs:font-poppinsBold md:w-[80%] w-[100%]  md:leading-[90%] fs-70w wrap-balance leading-tight">{courseDetailStore.courseMeta[0]?.courseName.toUpperCase()}</p>
          </div>
          <div className="flex items-center mb-2  text-[#111F58] font-poppinsSemibold  fs-30w md:justify-start xs:justify-center">
            {/* {courseDetailStore.courseMeta[0]?.regulations==" "?null:<span className={`border-r-2 border-[#111F58] pr-2 pl-2  h-[1.1rem] flex items-center ${courseDetailStore.courseMeta[0]?.universityCode === "GLOBAL"? 'hidden' : ''}`}>R-{courseDetailStore.courseMeta[0]?.regulations}</span>} */}
            <span className={`${ courseDetailStore.courseMeta[0]?.regulations.trim()==="" && courseDetailStore.courseMeta[0]?.semester.trim() ==""  ? null : 'border-r-2'} border-[#111F58] pr-2 h-[1.1rem] flex items-center`}>{courseDetailStore.courseMeta[0]?.subjectCode}</span>
            {courseDetailStore.courseMeta[0]?.regulations == " " || courseDetailStore.courseMeta[0]?.regulations == null ? null : <span className={`border-r-2 border-[#111F58] pr-2 pl-2  h-[1.1rem] flex items-center ${courseDetailStore.courseMeta[0]?.universityCode === "GLOBAL" ? 'hidden' : ''}`}>R-{courseDetailStore.courseMeta[0]?.regulations}</span>}
            {courseDetailStore.courseMeta[0]?.semester == " " || courseDetailStore.courseMeta[0]?.semester == null ? null : <span className={`pr-2 pl-2  h-[1.1rem] flex items-center ${courseDetailStore.courseMeta[0]?.universityCode === "GLOBAL" ? 'hidden' : ''}`}>Sem-{courseDetailStore.courseMeta[0]?.semester}</span>}
          </div>
          <div className="text-[#111F58]  fs-30w font-poppinsSemibold md:text-start xs:text-center" >
            <p className={`text-[#111F58] fs-30w ${courseDetailStore.courseMeta[0]?.universityCode === "GLOBAL" ? 'hidden' : ''}`}>{courseDetailStore.courseMeta[0]?.universityName}</p>
          </div>
          <div className="md:w-[80%] xs:w-[90%] text-[#000000] fs-25w  font-poppinsRegular md:text-start xs:text-center xs:mx-auto md:mx-[2px] mt-[2%]">
            {/* <p>{courseDetailStore.courseMeta[0]?.description}</p> */}
            <p className="font-poppinsRegular fs-25w">{headerContent}</p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default observer(Banner);
