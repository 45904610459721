import React, { useState } from 'react'
import player from "../../../assets/video-play.svg";
import folder from "../../../assets/note-remove.svg";
import playerColored from "../../../assets/video-play-colored.svg";
import folderColored from "../../../assets/vuesax-linear-note-remove-colored.svg";
import expandIcon from "../../../assets/expand.svg";
import contractIcon from "../../../assets/contract.svg";

interface Chapter {
  code: string,
  name: string,
  meta: string,
  partCode: string,
  priority: number
}
interface Part {
  code: string,
  name: string,
  meta: string,
  courseCode: string,
  priority: number
}

interface SampleProps {
  partItem: Part;
  chapters: Chapter[];
  index: number;
}

const CourseTopicCard: React.FC<SampleProps> = ({ partItem, chapters, index }) => {

  const [expandedChapters, setExpandedChapters] = useState<number[]>([]);

  const toggleChapter = (chapterId: number) => {
    if (expandedChapters.includes(chapterId)) {
      setExpandedChapters(expandedChapters.filter((id) => id !== chapterId));
    } else {
      setExpandedChapters([...expandedChapters, chapterId]);
    }
  };

  const removeNumber = (sentence: String) => {
    let spaceIndex = sentence.indexOf(" ");

    if (spaceIndex !== -1) {
      // Check if a space exists in the sentence
      // Remove the first word (substring from the index of the first space + 1)
      let newSentence = sentence.substring(spaceIndex + 1);

      return newSentence; // Output: "quick brown fox jumps over the lazy dog."
    } else {
      return sentence;
    }
  };
 
  let splitMeta = partItem.meta.split('|').map(item => item.trim());
  function removeSubstringBeforeDot(str:string) {
    const dotIndex = str.indexOf('.');
    if (dotIndex !== -1) { 
      return str.slice(dotIndex + 1); 
    }
    return str; 
  }
  
  return (
    <div className="bg-white px-[0.75rem] border-b-2 py-[0.75rem] " key={partItem.code}>
      <div className="flex items-center w-[100%]">
        <div className="md:flex items-center  w-[100%] gap-2">
          <div className="font-poppinsSemibold md:w-[50%] xs:w-[100%] fs-20w md:mb-0 xs:mb-1  cursor-pointer flex wrap-balance" onClick={() => toggleChapter(Number(partItem.code))} >
            <span className="mr-[2%]">{Math.floor((Number(index) + 1)/10) < 1 ? ((Number(index) + 1)+")") : (Number(index) + 1 +")") + " "}</span><span>{removeSubstringBeforeDot(partItem.name.toUpperCase())}</span>
          </div>

          <div className="font-poppinsRegular gap-2 md:w-[50%] xs:w-[100%] flex xs:items-center flex-start	bg-white fs-20w-mob ">
            <div className={`flex items-center md:w-[60%] xs:w-[28%] hidden md:flex md:block mr-2 xs:ps justify-start  md:gap-[3%] xs:gap-[6%] ${splitMeta[1]!=="" ? " leading-none":""} border-black `}>
              <img src={player} alt="Icon" className="w-[16px] h-[16px]" />
              <span>{splitMeta[0]}</span>
            </div>
            <div className={`flex items-center md:w-[55%] xs:w-[38%] md:hidden justify-start mr-2   md:gap-[3%] xs:gap-[6%] ${splitMeta[1]!=="" ? " border-black leading-none":""}`}>
              <img src={playerColored} alt="Icon" className="w-[16px] h-[16px]" />
              <span>{splitMeta[0]}</span>
            </div>
            {splitMeta[1][0]!=="0"?<div className="flex items-center md:w-[100%] xs:w-[50%]  hidden md:flex md:block justify-start bg-white   md:gap-[3%] xs:gap-[6%]" >
              <img src={folder} alt="Media Player Icon" className="w-[16px] h-[16px]" />
              <span >{splitMeta[1]}</span>
            </div>:"" }
            {splitMeta[1][0]!=="0"?<div className="flex items-center md:w-[100%] xs:w-[60%] md:hidden justify-start bg-white   md:gap-[3%] xs:gap-[6%]" >
              <img src={folderColored} alt="Media Player Icon" className="w-[16px] h-[16px]" />
              <span >{splitMeta[1]}</span>
            </div>: ""}
          </div>
        </div>
        <div>
          <button onClick={() => toggleChapter(Number(partItem.code))}>
            <img src={expandedChapters.includes(Number(partItem.code)) ? contractIcon : expandIcon} alt={expandedChapters.includes(Number(partItem.code)) ? '-' : '+'} width="13" height="13" />
          </button>
        </div>
      </div>
      {expandedChapters.includes(Number(partItem.code)) && (
        <ol className='flex flex-wrap flex-col  gap-2 mt-4 mb-4'>
        {chapters.map((ele, ind) => {
          return (
            <div key={ind}> {/* Ensure each element has a unique key */}
              <li className='font-poppinsRegular fs-20w-mob flex'>
                {/* Number(index + 1) + ". " part with grey color */}
                <span className='text-[#808080] mr-2'>
                  {Number(index + 1) + "." + (Number(ind) + 1)}
                </span>
                {/* ele.name part with black color */}
                <span className='text-black'>{removeNumber(ele.name)}</span>
              </li>
            </div>
          );
        })}
      </ol>
      )}
    </div>
  )
}

export default CourseTopicCard