import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Uniqueness = () => {
  const navigate= useNavigate();
  const handlNavigate =(value:any)=>{
    navigate(`/${value}`)
    window.scrollTo(0, 0);
  }
  return (
    <div className='bg-[#FFFBED] xl:h-[600px] lxl:h-[700px]  xlg:h-[650px]  smlg:h-[640px] slg:h-[630px]  msm:h-[610px]  md:h-[600px] xs:h-[400px]'>
      <div className='xs:px-8 md:px-8 py-6 '>
        <p className=' xl:text-[60px] lxl:text-[55px] xlg:text-[50px] smlg:text-[45px] msm:text-[40px] md:text-[40px] xs:text-[35px] font-poppinsBold text-[#111F58] text-center xs:leading-10 md:leading-[3rem] smlg:leading-[4rem]  lxl:leading-[5rem] md:mb-[10px]'>Experience Our Magic</p>
        <p className='xl:text-[30px] lxl:text-[28px] xlg:text-[26px] smlg:text-[24px] msm:text-[22px] md:text-[22px] xs:text-[20px] font-poppinsMedium  mt-1 text-center md:mb-[30px]'>Step-by-Step Success in Minutes. Ready to dive deeper?</p>
        <div className="xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px] p-3 bg-[#88C743] mt-4 w-[200px] msm:w-[250px] flex justify-center m-auto  text-center font-poppinsSemibold rounded-lg cursor-pointer md:block xs:hidden" onClick={() => { handlNavigate("course") }}>
          EXPLORE COURSES
        </div>
      </div>
    </div>
  )
}
