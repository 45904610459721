import React, { useContext, useState } from 'react'
import { StoreContext } from '../../../storeContext';
import { observer } from 'mobx-react';
import Select from "react-select";
import {cities} from "../../../sampleData";

const Enquiry = () => {

    const { courseDetailStore } = useContext(StoreContext);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [collegeUniversity, setCollegeUniversity] = useState("");
    const [designation, setDesignation] = useState("");
    const [city, setCity] = useState('');
    const [query, setQuery] = useState('');

    const closeAlert = () => {
        setShowAlert(false);
        setAlertMsg("");
    };

    const handleCityChange = (city: any) => {
        setCity(city.value);
      };

    const insertEnquiry = async (newEnquiry: any) => {
        await courseDetailStore.insertEnquiry(newEnquiry);
        let response = courseDetailStore.insertEnquiryStatus;
        if (response === "saved") {
            setAlertMsg("Thanks for Enquiry. We will get back to you!")
            setShowAlert(true);
        }
        else {
            alert("Please try again.")
        }
        setFullname('');
        setEmail('');
        setPhoneNumber('');
        setCity('');
        setQuery('');
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const isValid = validateForm();

        if(isValid === false){
            return;
        }

        let newEnquiry = {
            fullname,
            email,
            phoneNumber,
            collegeUniversity,
            city,
            query,
            designation
        }
        insertEnquiry(newEnquiry);
    };

    const validateForm = () => {
        const fullname_regex = /^[a-zA-Z\s]+$/;
        const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const email_local_part_regex = /[!#$%^&*()+={}\[\]:;<>,/?\\|~]/;
        const mobile_regex = /^(?!([0-9])\1{9}$)\d{10}$/;

        if (!fullname_regex.test(fullname)) {
            setAlertMsg("Please enter a valid name without special characters or numbers.")
            setShowAlert(true);
            return false;
        }

        if (!email_regex.test(email)) {
            setAlertMsg("Please enter a valid email address.")
            setShowAlert(true);
            return false;
        }

        if (/-{2,}/.test(email) || /_{2,}/.test(email)) {
            setAlertMsg("Please enter a valid email address.")
            setShowAlert(true);
            return false;
        }

        const localPart = email.split('@')[0];
        if (email_local_part_regex.test(localPart)) {
            setAlertMsg("Please enter a valid email address.")
            setShowAlert(true);
            return false;
        }

        if (!mobile_regex.test(phoneNumber)) {
            setAlertMsg("Please enter a valid 10-digit phone number.")
            setShowAlert(true);
            return false;
        }

        return true;
    }

    const cityList = cities.map((city: any) => {
        return {
            value: city,
            label: city,
        };
    });

    return (
        <div className='w-full pt-5 bg-[#EAEEF9] md:mt-[-9.5%] xs:mt-[-3%] md:pt-[12%] xs:pt-[8%] z-[-1] md:pb-[4%] xs:pb-[8%]'>
            <div className="md:w-[53%] xs:mr-[4%] xs:ml-[4%] md:mr-[8%] md:ml-[8%] xs:mb-[.8rem]">
                <h2 className="font-poppinsSemibold fs-40w mb-1  xs:text-center md:text-left ">Enquiry</h2>
                <p className="mb-5 text-[#115DE2] font-poppinsSemibold xs:text-center md:text-left fs-25w">Any Queries? Get in touch with us</p>
                <form onSubmit={handleSubmit} className="flex flex-col space-y-3 enquirty-form">
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
                      <div>
                      <input
                            type="text"
                            value={fullname}
                            onChange={(e) => setFullname(e.target.value)}
                            placeholder="Full Name"
                            className="w-full flex-1 xs:h-10 md:h-12 p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300 fs-20w font-poppinsRegular"
                            required
                        />
                      </div>
                      <div>
                      <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="w-full flex-1 xs:h-10 md:h-12 p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300 fs-20w font-poppinsRegular"
                            required
                        />
                      </div>
                      </div>
                      <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
                      <div >
                      <input
                            type="number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Phone Number"
                            className="w-full flex-1 xs:h-10 md:h-12 p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300 fs-20w font-poppinsRegular"                           
                        />
                      </div>

                      <div >

                      <Select
                            placeholder="City"
                            options={cityList}
                            onChange={handleCityChange}
                            className="flex-1  border rounded-[5px] fs-20w font-poppinsRegular text-[#000]"
                            styles={{
                                control: (provided: any) => ({
                                    ...provided,
                                    backgroundColor: "white",
                                    borderColor: "transparent",
                                    color: "#000",
                                    height: "46px",
                                    marginLeft: "0",
                                    '@media (max-width: 767px)': {
                                        marginLeft: "0",
                                        height: "40px",
                                        width: "100%"
                                      },
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#000',
                                }),
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    color: '#000',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: 'black', // Style for the selected option
                                }),
                            }}
                        />
                      </div>
               
                    </div>
                    <div className='grid grid-cols-1  gap-3'>
                    <textarea
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Write your queries here"
                        className="flex-1 min-h-[100px] p-3 border  mb-[1rem] rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none fs-20w font-poppinsRegular"
                        required
                    ></textarea>
                  </div>
                   
                    <button
                        type="submit"
                        className="w-[150px] mt-[1rem] bg-[#88C743] xs:m-auto md:m-0 py-2 px-6 rounded-md fs-20w font-poppinsSemibold"
                    >
                        SUBMIT
                    </button>
                </form>
            </div>

            {/* Conditional rendering for the alert */}
            {showAlert && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1500]">
                    <div className="bg-white p-6 rounded shadow">
                        <p className="text-xl font-semibold mb-4">{alertMsg}</p>
                        <button onClick={closeAlert} className="bg-[#115DE2] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px]">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}


export const EnquiryForm = observer(Enquiry);