import React, { useContext, useEffect, useState } from "react";
import { FAQItem } from "./FAQItem";

import { StoreContext } from "../../../storeContext";
import { observer } from "mobx-react";

const FAQ = () => {
  const { courseDetailStore } = useContext(StoreContext);

  const parsedFaqs =[
    {
        "question": "Where can I access Maths.Engineering courses?",
        "answer": "Courses can be accessed only through the Maths.Engineering mobile app, or tablet."
    },
    {
        "question": "How do I find and enroll in a course?",
        "answer": "You can browse courses on the Maths.Engineering website, select a course tailored to your university syllabus, and download the mobile app to enroll in a course. Payment options are available on the Android app."
    },
    {
        "question": "Can I preview a course before purchasing?",
        "answer": "Yes, Maths.Engineering offers a freemium model where the first unit of every course is available for free. This allows you to browse through the initial content of a course to make an informed decision before purchasing the full course."
    },
    {
        "question": "What are the general requirements for accessing courses?",
        "answer": "To access courses on Maths.Engineering, you will need a stable and reliable internet connection, as the platform does not currently offer options for downloading content. This ensures you can stream the video lectures and interactive content seamlessly. You can access the courses from any device like a smartphone, and tablet."
    },
    {
        "question": "How do I pay for a course? Are there different plans for individuals and institutions?",
        "answer": "For Individual Learners: Courses can be purchased using various payment methods, including UPI, debit/credit cards, and net banking, providing convenient options to suit your preferences. For Educational Institutions: Institutions interested in our courses and services are encouraged to connect with us directly for a tailored discussion on pricing and plans. We offer customized solutions to meet the unique needs of each educational institution. To explore institutional options, please contact us at harsha@maths.engineering or call +91 9360922729 for more detailed information and to discuss how we can best serve your educational goals."
    },
    {
        "question": "What is the policy for refunds or course exchanges?",
        "answer": "At Maths.Engineering, due to the nature of our products being non-tangible and irrevocable digital goods, we do not offer refunds once an order is confirmed and the product is delivered. We encourage customers to contact us if they experience any issues with receiving or downloading our products. For any inquiries or assistance regarding our Returns and Refunds Policy, please feel free to reach out to us. We are committed to ensuring the satisfaction and positive experience of all our users."
    },
    {
        "question": "How can I track my progress in a course?",
        "answer": "Once you log in to the platform, you can find your course in the dashboard section. From there, you can go to the Course content section. You can access your course completion progress based on the units in the course overview section."
    },
    {
        "question": "Who can I contact for technical or course-related support?",
        "answer": "You can mail us via support@maths.engineering or call / WhatsApp us @ +91 9360922729"
    }
]



  // const parsedFaqs = courseDetailStore.courseMeta[0]?.faqs
  //   ? JSON.parse(courseDetailStore.courseMeta[0].faqs)
  //   : [];
  const [expandedFAQ, setexpandedFAQ] = useState<number[]>([]);

  const toggleFaq = (chapterId: number) => {
    setexpandedFAQ((prevState) => {
      if (prevState.includes(chapterId)) {
        return [];
      } else {
        return [chapterId];
      }
    });
  };

  return (
    <div className="lg:pt-[60px] lg:pb-[60px] pt-8 pb-8">
      <div className="container-wrap-w">
      <p className="fs-30w font-poppinsSemibold text-[#115DE2] p-5 pl-0">FAQ's</p>
      <div className="bg-white border-2  msm:w-[57.5%]  shadow rounded-lg overflow-hidden">
        <div>
          {parsedFaqs.map((ele: any, index: any) => (
            <FAQItem
              key={index}
              faq={ele}
              expandedFAQ={expandedFAQ}
              toggleFaq={toggleFaq}
              index={index}
            />
          ))}
        </div>
      </div>
      </div>
  
    </div>
  );
};

export default observer(FAQ);
