import React, { useContext, useEffect, useRef, useState } from 'react';

import { StoreContext } from '../../../storeContext';
import UniversityNameCard from './UniversityCardItem';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const UniversityScroll = () => {

  const { courseStore } = useContext(StoreContext);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleDivClick = (index: number, uniCode: string) => {
    setSelectedIndex(index);
    courseStore.universityFilterCode = uniCode;
  };

  const navigateToCourse = () => {
    navigate("/course");
    courseStore.universityFilterCode = "";
    window.scrollTo(0, 0);
  }

  const viewMoreUniversity = (
    <div onClick={navigateToCourse} className=' h-[50px] xxl:h-[90px] hover:cursor-pointer rounded-md flex items-center justify-center text-center bg-[#22AAE11A] text-[#115DE2]'>
    <p className='fs-20w font-poppinsSemibold break-words' >Other Universities / Colleges</p>
</div>
  );


  return (

    <div className='w-[100%] hidden md:block'>
      <div className='mt-[0%] grid grid-cols-4 gap-[12px]'>
        {courseStore.universities.slice(0, 3).map((item, index) => (
          <UniversityNameCard key={index} data={item.university} index={index} flag={selectedIndex} handleDivClick={() => handleDivClick(index, item.university.code)} />
        ))}
        {viewMoreUniversity}
      </div>
    </div>
  )
}

export default observer(UniversityScroll);
