import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { StoreContext } from '../../storeContext';
import ply_str_logo from "../../assets/CourseDetailCard/Group 1814.png"
interface FloatButtonProps {
    prop:String;
    path:String | null;
  }
const FloatButton: React.FC<FloatButtonProps> =({prop,path}) => {
  const { courseStore } = useContext(StoreContext);
    const navigate = useNavigate();
    const redirectToExternalLink = (url: string) => {
    window.location.href = url;
};

  const navigateToCourse = () => {
    if (path) {
      navigate(`${path}`);
      courseStore.universityFilterCode = "";
  } else {
      redirectToExternalLink('https://play.google.com/store/apps/details?id=com.mathsengineering.courses');
  }
}
  return (
    <div className="md:hidden z-[2000] fixed  xs:bottom-1 left-0 right-0 mx-auto w-[100%] h-[60px] rounded-[10px]  shadow-lg  hover:shadow-xl">
      <div className=' w-[90.2%] bg-[white] flex flex-row gap-2 justify-center mx-auto rounded-[10px] pb-3'>
      <div onClick={ navigateToCourse } className="border border-[1px] border-[#464646] bg-[#F6AE35] md:hidden w-[100%] h-[60px] rounded-[10px] bg-[#88C743] flex justify-between items-center mx-auto">
          <p className="w-[90%] text-[18px] text-[#111F58] font-poppinsSemibold mx-2 leading-[18px]">{prop}</p>
          <img src={ply_str_logo} className="h-[29px]  px-[2%] my-auto"/>
        </div>
        </div>
    </div>
  );
}

export default observer(FloatButton);