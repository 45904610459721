import React from "react";
import expandIcon from "../../../assets/expand_bold.svg";
import contractIcon from "../../../assets/contract.svg";

interface FAQ {
  code: number;
  question: string;
  answer: string[];
}

interface FAQItemProps {
  index: number;
  faq: FAQ;
  expandedFAQ: number[];
  toggleFaq: (chapterId: number) => void;
}

export const FAQItem: React.FC<FAQItemProps> = ({faq,index,expandedFAQ,toggleFaq,}) => {

  const highlightContactInfo = (text: any) => {
  const phoneRegex = /\b\d{10}\b/g;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  const highlightedText = text.replace(phoneRegex, (match: any) => `<span style="color: #1085C5; font-weight: bold;">${match}</span>`);
  const finalHighlightedText = highlightedText.replace(emailRegex, (match: any) => `<span style="color: #1085C5; font-weight: bold;">${match}</span>`);

  return { __html: finalHighlightedText };
  };

  return (
    <div
      className="bg-white px-[3%] py-[1.5%] mb-[2%] bg-[white] rounded-[10px] "
      key={faq.code}
    >
      <div className="flex items-center w-[100%]">
        <div
          className="md:flex items-center  w-[90%] cursor-pointer "
          onClick={() => toggleFaq(index)}
        >
          <div className="md:w-[80%] xs:w-[90%] font-poppinsRegular tracking-tight leading-tight xl:text-[30px] lxl:text-[27px] xlg:text-[25px] smlg:text-[23px] msm:text-[20px] md:text-[17px] xs:text-[15px]  p-[1%]">
            <span>{faq.question}</span>
          </div>
        </div>
        <div className="font-poppinsRegular">
          <button onClick={() => toggleFaq(index)}>
            <p className="xl:text-[40px] lxl:text-[36px] xlg:text-[33px] smlg:text-[30px] msm:text-[26px] md:text-[23px] xs:text-[20px]">{expandedFAQ.includes(index) ? '-' : "+"}</p>
            {/* <img
              src={expandedFAQ.includes(index) ? contractIcon : expandIcon}
              alt={expandedFAQ.includes(faq.code) ? "-" : "+"}
              width="14"
              height="14"
            /> */}
          </button>
        </div>
      </div>
      {expandedFAQ.includes(index) && (
        <div className="flex my-[1%] xl:text-[27px] lxl:text-[24px] xlg:text-[21px] smlg:text-[18px] msm:text-[16px] md:text-[14px] xs:text-[12px] p-[1%] pl-[3%] font-poppinsRegular">
          <ul className="list-disc space-y-3">
            {faq.answer.map((item, index)=>{
              return <li key={index} dangerouslySetInnerHTML={highlightContactInfo(item)} />
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
