import React, { useContext } from 'react'
import excel from "../../../assets/excel.png";
import Content from "../../../assets/vuesax-broken-message-text.svg";
import LowCost from "../../../assets/lowCost.png";
import understand from "../../../assets/understand.png";
import mobile from "../../../assets/mobile.png";
import { StoreContext } from '../../../storeContext';
import { observer } from 'mobx-react';
// import LowCost from "../../../assets/vuesax-broken-money-change.svg";
// import Employee from "../../../assets/vuesax-broken-people.svg";
// import Video from "../../../assets/vuesax-linear-video-play.svg";
// import AllConcept from "../../../assets/vuesax-broken-box.svg";

const Advantages = () => {

  const { courseDetailStore } = useContext(StoreContext);

  const parsedAdvantages: string[] = [
    "Low Cost",
    "Understand Maths easily with Short, animated problem videos.",
    "Access Your Semester-Focused content, Important Questions, Tips and much more",
    "Study Anytime with our Mobile-Friendly App.",
    "Excel with Tailored content for your university syllabus"
];

  const iconArray = [LowCost, understand, Content, mobile, excel];

  return (
    <div className='bg-[#EAEEF9] md:pl-[8%] pt-[6%] pb-5 mt-[-5%] xs:pr-[5%] xs:pl-[5%]'>
      <p className='fs-30w text-[#3D7BE7] p-5 pl-0  font-poppinsSemibold'>Learning advantages</p>
      <div className='bg-white border-5 md:w-[95%] xs:w-[100%] msm:w-[60.5%]  font-poppinsRegular shadow rounded-lg'>

        {
          parsedAdvantages.map((item, index) => (
            <div key={index} className="flex items-center border-b border-gray-300 p-3">
              <img
                src={iconArray[index]}
                alt="Your Icon"
                className="text-[#115DE2] xl:w-[24px] lxl:w-[22px] xs:text-[20px] xl:h-[24px] lxl:h-[22px] xs:h-[20px]  mr-5"
              />
              <p className="font-poppinsMedium fs-20w">{item}</p>
            </div>
          ))
        }

      </div>
    </div>
  )
}

export default observer(Advantages);