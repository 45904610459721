import React, { useContext, useEffect, useState } from 'react'
import Select from "react-select";
import UniversityScroll from './UniversityScroll';
import { StoreContext } from '../../../storeContext';
import { observer } from 'mobx-react';
import CourseScroll from './CourseScroll';
import { useNavigate } from 'react-router-dom';

const BrowseCourses = () => {

    const { courseStore } = useContext(StoreContext);
    const navigate = useNavigate();

    const fetchUniversities = async (location: any) => {
        await courseStore.fetchUniversities(location);
    }

    const fetchLocations = async () => {
        await courseStore.fetchAllLocations();
    }

    const onLocationChange = (e: any) => {
        courseStore.selectedLocation.value = e.value;
        courseStore.selectedLocation.label = e.label;
        courseStore.universityFilterCode = "";
    }

    const onUniversityChange = (e: any) => {
        courseStore.universityFilterCode = e.value;
    };

    const states = courseStore.locations.map((proxyObj: any) => {
        return {
            value: proxyObj.code,
            label: proxyObj.name,
        };
    })?.slice().sort((a, b) => {
      const sequence = ["Tamil nadu", "Karnataka", "Telangana", "Andhra Pradesh"];
      const indexA = sequence.indexOf(a.label);
      const indexB = sequence.indexOf(b.label);
      return indexA - indexB;
    });

    const universities = courseStore.universities.map((proxyObj: any) => {
        return {
            value: proxyObj.university.code,
            label: proxyObj.university.name,
        };
    });

    const navigateToCourse = () => {
        navigate("/course");
        courseStore.universityFilterCode = "";
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        fetchLocations();  
        courseStore.fetchTopics();
    }, [])

    useEffect(()=>{ 
      courseStore.universityFilterCode = universities[0]?.value;
    },[universities[0]?.value])

    useEffect(() => {
        fetchUniversities(courseStore.selectedLocation.value);
    }, [courseStore.selectedLocation.value])
    useEffect(()=>{courseStore.selectedUnivesity=courseStore.universityFilterCode;},[courseStore.universityFilterCode])

    return (
      <div className="bg-[#FFFFFF] w-[100%]">
        <div className="md:px-[8%] xs:px-[5%] pt-[2%]">
          <div className="w-full md:h-[100px] xs:h-auto flex md:flex-row md:justify-between xs:flex-col  xs:gap-[20px]">
            <div className="md:w-[67%] xs:w-[70%] mx-auto flex md:items-center md:justify-start xs:justify-center xs:text-center">
              <h1 className="xl:text-[60px] lxl:text-[55px] xlg:text-[50px] smlg:text-[45px] msm:text-[40px] md:text-[40px] xs:text-[35px]  xs:pb-[20px] xs:pt-[35px] font-poppinsBold text-[#111F58] leading-10">
                Browse Our Courses
              </h1>
            </div>

            <div className="xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px]  md:w-[33%] flex flex-col gap-[10px] font-poppinsRegular">
              <p className="p-[0px]  fs-20w font-poppinsRegular">
                SELECT YOUR STATE
              </p>
              <Select
                placeholder="State"
                value={courseStore.selectedLocation}
                options={states}
                onChange={onLocationChange}
                className="w-full rounded-md hidden md:block xs:block xxl:text-[20px] xxxl:text-[25px]"
                styles={{
                  control: (provided: any) => ({
                    ...provided,
                    backgroundColor: "#87ADF0",
                    borderColor: "transparent",
                    color: "black",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "white", // Style for the selected option
                  }),
                }}
              />
              <Select
                placeholder="University"
                options={universities}
                onChange={onUniversityChange}
                className="w-full rounded-md block md:hidden"
                styles={{
                  control: (provided: any) => ({
                    ...provided,
                    backgroundColor: "#22AAE11A",
                    borderColor: "#115DE2",
                    color: "black",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#115DE2",
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: "#115DE2",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#115DE2", // Style for the selected option
                  }),
                }}
              />
            </div>
          </div>
        </div>

        <div className="md:px-[8%] xs:px-[5%] md:py-[2%] xs:py-[6%]">
          <UniversityScroll />
        </div>

        <div>
          <CourseScroll />
        </div>

        <div className="flex justify-center md:pb-[3%] xs:pb-[6%]">
          <button
            onClick={navigateToCourse}
            className="xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px] bg-[#88C743] w-[250px] msm:w-[330px] msm:mt-[40px] px-[0.2rem] py-[0.7rem] font-poppinsSemibold rounded-[7px]"
          >
            VIEW MORE COURSES/TOPICS
          </button>
        </div>
      </div>
    );
}
export const BrowseCoursesComponent = observer(BrowseCourses);