export const FAQS=[
  {   code:1,
    question: "What is Maths.Engineering?",
      answer: ["Maths.Engineering is an edutech platform specializing in engineering mathematics. It offers a range of courses tailored to different university syllabi, designed for both individual learners and educational institutions."]
  },
  {
    code:2,
    question: "What do Maths.Engineering courses include?",
    answer: ["Courses include comprehensive topics in engineering mathematics, featuring chapters with solved problems, animated video lectures, interactive quizzes, and summaries. The content is aligned with university curricula to support exam preparation."]
  },
  {
    code:3,
    question: "How can institutions integrate Maths.Engineering courses into their curriculum?",
      answer: ["Maths.Engineering offers customizable teaching content tailored to the specific needs of each college. Institutions can use our Learning Management System (LMS) to track student progress effectively. Additionally, students are provided access to our app, allowing them to study anytime and anywhere. Teachers can utilize our product for classroom teaching. Colleges interested in integrating our courses into their curriculum can start by contacting us via email at harsha@maths.engineering or calling us at +91 9360922729 to discuss their specific requirements and walk through the integration process."]
  },
  {
    code:4,
    question: "What institutional benefits does Maths.Engineering offer?",
      answer:[ "Customized Curriculum: Maths.Engineering offers tailored content to match specific college requirements, ensuring alignment with their curriculum." ,"Classroom Integration: Videos are used as primary teaching tools, enhancing problem-solving efficiency and visual understanding of complex topics." , "Homework and Visual Learning: Homework assignments through the app provide visual explanations, catering to various learning styles and improving student comprehension.", "Targeted Academic Support: The LMS allows teachers to offer focused support, especially benefiting students who prefer visual learning, leading to better academic performance.", "Enhanced Problem-Solving and Revision: The visual tools enable more efficient problem-solving, allowing more time for revisions and interactive sessions." ,"Assessment and Progress Tracking: Regular class tests aligned with the curriculum are tracked using the LMS, providing insights into student progress.", "Proven Impact: At SRM TRP Engineering College, Maths.Engineering significantly improved exam performance, with students and faculty noting its effectiveness in simplifying complex topics and increasing engagement.", "Contact for Implementation: Colleges can start integrating Maths.Engineering by contacting harsha@maths.engineering or calling +91 9360922729."]
  }
]

export const testimonials = [
  {
    id: 1,
    studentName: "JASWANTH",
    studentInfo: "First Year, Mechanical Engineering",
    imageUrl: "JASWANTH",
    description: "These animated videos on Maths. Engineering are like magic! I used to struggle with complex stuff, but now, I get it in a flash. Learning feels like fun now.",
    rating: 5
  },
  {
    id: 2,
    studentName: "AVANTHIKA",
    studentInfo: "First Year, Computer Science and Engineering",
    imageUrl: "AVANTHIKA",
    description: "I can't believe how Maths.Engineering makes the hard stuff seem so easy. Those 45-minute problems? Nailed in just 10 minutes! Seriously, it's like a superpower!",
    rating: 5
  },
  {
    id: 3,
    studentName: "R.M.ADHITYA",
    studentInfo: "First Year, Computer Science Engineering",
    imageUrl: "ADHITYA",
    description: "Who would've thought I could ace math while waiting for the bus? Thanks to Maths.Engineering, I'm learning whenever and wherever I want. Learning's on my terms now.",
    rating: 5
  }
]

export const cities = [
'Mumbai (Maharashtra)',
'Delhi (Delhi)',
'Bangalore (Karnataka)',
'Hyderabad (Telangana)',
'Ahmedabad (Gujarat)',
'Chennai (Tamil Nadu)',
'Kolkata (West Bengal)',
'Surat (Gujarat)',
'Pune (Maharashtra)',
'Jaipur (Rajasthan)',
'Lucknow (Uttar Pradesh)',
'Kanpur (Uttar Pradesh)',
'Nagpur (Maharashtra)',
'Indore (Madhya Pradesh)',
'Thane (Maharashtra)',
'Bhopal (Madhya Pradesh)',
'Visakhapatnam (Andhra Pradesh)',
'Pimpri-Chinchwad (Maharashtra)',
'Patna (Bihar)',
'Vadodara (Gujarat)',
'Ghaziabad (Uttar Pradesh)',
'Ludhiana (Punjab)',
'Agra (Uttar Pradesh)',
'Nashik (Maharashtra)',
'Faridabad (Haryana)',
'Meerut (Uttar Pradesh)',
'Rajkot (Gujarat)',
'Kalyan-Dombivali (Maharashtra)',
'Vasai-Virar (Maharashtra)',
'Varanasi (Uttar Pradesh)',
'Srinagar (Jammu and Kashmir)',
'Aurangabad (Maharashtra)',
'Dhanbad (Jharkhand)',
'Amritsar (Punjab)',
'Navi Mumbai (Maharashtra)',
'Allahabad (Uttar Pradesh)',
'Howrah (West Bengal)',
'Ranchi (Jharkhand)',
'Jabalpur (Madhya Pradesh)',
'Gwalior (Madhya Pradesh)',
'Coimbatore (Tamil Nadu)',
'Vijayawada (Andhra Pradesh)',
'Jodhpur (Rajasthan)',
'Madurai (Tamil Nadu)',
'Raipur (Chhattisgarh)',
'Kota (Rajasthan)',
'Guwahati (Assam)',
'Chandigarh (Chandigarh)',
'Thiruvananthapuram (Kerala)',
'Solapur (Maharashtra)',
'Hubballi-Dharwad (Karnataka)',
'Tiruchirappalli (Tamil Nadu)',
'Tiruppur (Tamil Nadu)',
'Moradabad (Uttar Pradesh)',
'Mysore (Karnataka)',
'Bareily (Uttar Pradesh)',
'Gurgaon (Haryana)',
'Aligarh (Uttar Pradesh)',
'Jalandhar (Punjab)',
'Bhubaneswar (Odisha)',
'Salem (Tamil Nadu)',
'Mira-Bhayandar (Maharashtra)',
'Warangal (Telangana)',
'Guntur (Andhra Pradesh)',
'Bhiwandi (Maharashtra)',
'Saharanpur (Uttar Pradesh)',
'Gorakhpur (Uttar Pradesh)',
'Bikaner (Rajasthan)',
'Amravati (Maharashtra)',
'Noida (Uttar Pradesh)',
'Jamshedpur (Jharkhand)',
'Bhilai (Chhattisgarh)',
'Cuttack (Odisha)',
'Firozabad (Uttar Pradesh)',
'Kochi (Kerala)',
'Nellore (Andhra Pradesh)',
'Bhavnagar (Gujarat)',
'Dehradun (Uttarakhand)',
'Durgapur (West Bengal)',
'Asansol (West Bengal)',
'Rourkela (Odisha)',
'Nanded (Maharashtra)',
'Kolhapur (Maharashtra)',
'Ajmer (Rajasthan)',
'Akola (Maharashtra)',
'Gulbarga (Karnataka)',
'Jamnagar (Gujarat)',
'Ujjain (Madhya Pradesh)',
'Loni (Uttar Pradesh)',
'Siliguri (West Bengal)',
'Jhansi (Uttar Pradesh)',
'Ulhasnagar (Maharashtra)',
'Jammu (Jammu and Kashmir)',
'Sangli-Miraj & Kupwad (Maharashtra)',
'Mangalore (Karnataka)',
'Erode (Tamil Nadu)',
'Belgaum (Karnataka)',
'Ambattur (Tamil Nadu)',
'Tirunelveli (Tamil Nadu)',
'Malegaon (Maharashtra)',
'Gaya (Bihar)',
'Jalgaon (Maharashtra)',
'Udaipur (Rajasthan)',
'Maheshtala (West Bengal)',
'Davanagere (Karnataka)',
'Kozhikode (Kerala)',
'Kurnool (Andhra Pradesh)',
'Rajpur Sonarpur (West Bengal)',
'Rajahmundry (Andhra Pradesh)',
'Bokaro (Jharkhand)',
'South Dumdum (West Bengal)',
'Bellary (Karnataka)',
'Patiala (Punjab)',
'Gopalpur (West Bengal)',
'Agartala (Tripura)',
'Bhagalpur (Bihar)',
'Muzaffarnagar (Uttar Pradesh)',
'Bhatpara (West Bengal)',
'Panihati (West Bengal)',
'Latur (Maharashtra)',
'Dhule (Maharashtra)',
'Tirupati (Andhra Pradesh)',
'Rohtak (Haryana)',
'Korba (Chhattisgarh)',
'Bhilwara (Rajasthan)',
'Berhampur (Odisha)',
'Muzaffarpur (Bihar)',
'Ahmednagar (Maharashtra)',
'Mathura (Uttar Pradesh)',
'Kollam (Kerala)',
'Avadi (Tamil Nadu)',
'Kadapa (Andhra Pradesh)',
'Kamarhati (West Bengal)',
'Sambalpur (Odisha)',
'Bilaspur (Chhattisgarh)',
'Shahjahanpur (Uttar Pradesh)',
'Satara (Maharashtra)',
'Bijapur (Karnataka)',
'Rampur (Uttar Pradesh)',
'Shivamogga (Karnataka)',
'Chandrapur (Maharashtra)',
'Junagadh (Gujarat)',
'Thrissur (Kerala)',
'Alwar (Rajasthan)',
'Bardhaman (West Bengal)',
'Kulti (West Bengal)',
'Kakinada (Andhra Pradesh)',
'Nizamabad (Telangana)',
'Parbhani (Maharashtra)',
'Tumkur (Karnataka)',
'Khammam (Telangana)',
'Ozhukarai (Puducherry)',
'Bihar Sharif (Bihar)',
'Panipat (Haryana)',
'Darbhanga (Bihar)',
'Bally (West Bengal)',
'Aizawl (Mizoram)',
'Dewas (Madhya Pradesh)',
'Ichalkaranji (Maharashtra)',
'Karnal (Haryana)',
'Bathinda (Punjab)',
'Jalna (Maharashtra)',
'Eluru (Andhra Pradesh)',
'Kirari Suleman Nagar (Delhi)',
'Barasat (West Bengal)',
'Purnia (Bihar)',
'Satna (Madhya Pradesh)',
'Mau (Uttar Pradesh)',
'Sonipat (Haryana)',
'Farrukhabad (Uttar Pradesh)',
'Sagar (Madhya Pradesh)',
'Rourkela (Odisha)',
'Durg (Chhattisgarh)',
'Imphal (Manipur)',
'Ratlam (Madhya Pradesh)',
'Hapur (Uttar Pradesh)',
'Arrah (Bihar)',
'Karimnagar (Telangana)',
'Anantapur (Andhra Pradesh)',
'Etawah (Uttar Pradesh)',
'Ambernath (Maharashtra)',
'North Dumdum (West Bengal)',
'Bharatpur (Rajasthan)',
'Begusarai (Bihar)',
'New Delhi (Delhi)',
'Gandhidham (Gujarat)',
'Baranagar (West Bengal)',
'Tiruvottiyur (Tamil Nadu)',
'Puducherry (Puducherry)',
'Sikar (Rajasthan)',
'Thoothukudi (Tamil Nadu)',
'Rewa (Madhya Pradesh)',
'Mirzapur (Uttar Pradesh)',
'Raichur (Karnataka)',
'Pali (Rajasthan)',
'Ramagundam (Telangana)',
'Haridwar (Uttarakhand)',
'Vijayanagaram (Andhra Pradesh)',
'Katihar (Bihar)',
'Nagarcoil (Tamil Nadu)',
'Sri Ganganagar (Rajasthan)',
'Karawal Nagar (Delhi)',
'Mango (Jharkhand)',
'Thanjavur (Tamil Nadu)',
'Bulandshahr (Uttar Pradesh)',
'Uluberia (West Bengal)',
'Murwara (Uttar Pradesh)',
'Sambhal (Uttar Pradesh)',
'Singrauli (Madhya Pradesh)',
'Nadiad (Gujarat)',
'Secunderabad (Telangana)',
'Naihati (West Bengal)',
'Yamunanagar (Haryana)',
'Bidhan Nagar (West Bengal)',
'Pallavaram (Tamil Nadu)',
'Bidar (Karnataka)',
'Munger (Bihar)',
'Panchkula (Haryana)',
'Burhanpur (Madhya Pradesh)',
'Raurkela Industrial Township (Odisha)',
'Kharagpur (West Bengal)',
'Dindigul (Tamil Nadu)',
'Gandhinagar (Gujarat)',
'Hospet (Karnataka)',
'Nangloi Jat (Delhi)',
'Malda (West Bengal)',
'Ongole (Andhra Pradesh)',
'Deoghar (Jharkhand)',
'Chapra (Bihar)',
'Haldia (West Bengal)',
'Khandwa (Madhya Pradesh)',
'Nandyal (Andhra Pradesh)',
'Chittoor (Andhra Pradesh)',
'Morena (Madhya Pradesh)',
'Amroha (Uttar Pradesh)',
'Anand (Gujarat)',
'Bhind (Madhya Pradesh)',
'Bhalswa Jahangir Pur (Delhi)',
'Madhyamgram (West Bengal)',
'Bhiwani (Haryana)',
'Navi Mumbai Panvel Raigad (Maharashtra)',
'Baharampur (West Bengal)',
'Ambala (Haryana)',
'Morvi (Gujarat)',
'Fatehpur (Uttar Pradesh)',
'Rae Bareli (Uttar Pradesh)',
'Khora (Uttar Pradesh)',
'Bhusawal (Maharashtra)',
'Orai (Uttar Pradesh)',
'Bahraich (Uttar Pradesh)',
'Vellore (Tamil Nadu)',
'Mahesana (Gujarat)',
'Sambalpur (Orissa)',
'Raiganj (West Bengal)',
'Sirsa (Haryana)',
'Danapur (Bihar)',
'Serampore (West Bengal)',
'Sultan Pur Majra (Delhi)',
'Guna (Madhya Pradesh)',
'Jaunpur (Uttar Pradesh)',
'Panvel (Maharashtra)',
'Shivpuri (Madhya Pradesh)',
'Surendranagar Dudhrej (Gujarat)',
'Unnao (Uttar Pradesh)',
'Hugli and Chinsurah (West Bengal)',
'Alappuzha (Kerala)',
'Kottayam (Kerala)',
'Machilipatnam (Andhra Pradesh)',
'Shimla (Himachal Pradesh)',
'Adoni (Andhra Pradesh)',
'Tenali (Andhra Pradesh)',
'Proddatur (Andhra Pradesh)',
'Saharsa (Bihar)',
'Hindupur (Andhra Pradesh)',
'Sasaram (Bihar)',
'Hajipur (Bihar)',
'Bhimavaram (Andhra Pradesh)',
'Dehri (Bihar)',
'Madanapalle (Andhra Pradesh)',
'Siwan (Bihar)',
'Bettiah (Bihar)',
'Guntakal (Andhra Pradesh)',
'Srikakulam (Andhra Pradesh)',
'Motihari (Bihar)',
'Dharmavaram (Andhra Pradesh)',
'Gudivada (Andhra Pradesh)',
'Narasaraopet (Andhra Pradesh)',
'Bagaha (Bihar)',
'Miryalaguda (Telangana)',
'Tadipatri (Andhra Pradesh)',
'Kishanganj (Bihar)',
'Karaikudi (Tamil Nadu)',
'Suryapet (Telangana)',
'Jamalpur (Bihar)',
'Kavali (Andhra Pradesh)',
'Tadepalligudem (Andhra Pradesh)',
'Amaravati (Andhra Pradesh)',
'Buxar (Bihar)',
'Jehanabad (Bihar)',
'Aurangabad (Bihar)',
]