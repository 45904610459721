export const fetchLocations = `query getAllLocations {
    getLocations{
      locations{
        code
        name
        combined
      }
    }
  }`

export const fetchUniversities = `query getUniversities($input: String!){
  getUniversitiesWithCourses(locationCode: $input){
    universities{
      university{
        code
        name
      }
      courses{
        code
        name
        regulations
        semester
        aboutSubjects
        aboutChapters
        subjectCode
        autonomousCollegeName
      }
    }
  }
}`

export const fetchTopicsQuery = `query getTopics{
  getTopics{
   	topics{
      code
      name
      regulations
      university
      semester
      aboutSubjects
      aboutChapters
      subjectCode
    }
    error{
      message
    }
  }
}`;