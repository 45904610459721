import React, { useContext, useEffect, useState } from 'react';
import CourseCard from "./CourseCard"
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../../storeContext';
import { observer } from 'mobx-react';

interface Course {
  code: string;
  name: string;
  regulations?: string; 
  semester?: string;    
  university?: string;
  aboutSubjects: string; 
  aboutChapters: string | null; 
  subjectCode?: string;
}

interface University{
  code: string,
  name: string
}
interface CourseCardListProps{
  university: University,
  courses: Course[],
  index:any,
  universityName:string
}

const CourseCardList: React.FC<CourseCardListProps> = ({university, courses, index,universityName}) => {

  const navigate = useNavigate();
  const { courseStore, courseDetailStore } = useContext(StoreContext);

  const [ifUniversity, setIfUniversity] = useState(false);

  const handleViewCoursesClick = (value: any) => {
    courseDetailStore.fetchChapters(value.code);
    courseDetailStore.selectedCourse.code = value.code;
    courseDetailStore.selectedCourse.name = value.name;
    courseDetailStore.selectedCourse.universityName = university.name;
    navigate(`/course/${value.code}/${value.name.trim().split(" ").join("")}`);
  };

  const convertToTitleCase = (inputString: string): string => {

    const words = inputString?.toLowerCase().split(' ');

    const titleCaseWords = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  
    return titleCaseWords?.join(' ');
  };

  const renderUniversityName = () => {
    let arr = courses
        .filter(ele => courseStore.isSemesterSelected ? courseStore.semesterFilterCode === ele.semester : ele)
        .filter(ele => courseStore.isRegulationSelected ? courseStore.regulationFilterCode === ele.regulations : ele);

      if(arr.length > 0){
        return setIfUniversity(true)
      }
      return setIfUniversity(false)
  }

  useEffect(()=>{
    renderUniversityName()
  }, [courseStore.semesterFilterCode, courseStore.regulationFilterCode]) 

  return (
    <div   className={`${ifUniversity ? "" : "hidden"} w-[100%] ${index % 2 === 0 ? 'bg-[#EAEEF9]' : 'bg-[white]'} lg:py-11 ${universityName?"pt-8":""} pb-8 `}>
      <div className="w-[100%]  md:px-[8%] xs:px-[4%] mb-[30px]  flex md:items-start xs:mx-auto text-[#111F58]  fs-40w font-poppinsBold text-center xs:justify-center md:justify-start xs:leading-8"><p>{convertToTitleCase(universityName)}</p></div>
      <div className="md:px-[8%] xs:px-[4%]   grid lg:grid-cols-4 md:gap-[16px] xs:gap-[16px] md:grid-cols-3 sm:grid-cols-2 sxsm:grid-cols-2 ">
        {courses
        .filter(ele => courseStore.isSemesterSelected ? courseStore.semesterFilterCode === ele.semester : ele)
        .filter(ele => courseStore.isRegulationSelected ? courseStore.regulationFilterCode === ele.regulations : ele)
        .map((ele, index) => (
          <CourseCard key={index} course={ele} onViewCoursesClick={()=>handleViewCoursesClick(ele)} />
        ))}
      </div>
    </div>
  );
};

export default observer(CourseCardList);
