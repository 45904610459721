import React, { useContext, useEffect, useState } from 'react'
import CourseHeader from '../components/Course/header/CourseHeader'
import { observer } from 'mobx-react'
import { StoreContext } from '../storeContext';
import CourseCardList from '../components/Course/CourseCard/CourseCardList';
import { useParams } from 'react-router-dom';
import { Suggestion } from '../components/Course/Suggestion';
import { Course } from '../services/Utils';

const UniversityDetails = () => {
    const { courseStore } = useContext(StoreContext);
    const {universityName,locationCode}=useParams()
    const [location , setLocation]= useState(locationCode?.trim().split("-").join(" "));
    const [university, setUniversity] =useState(universityName?.trim().split("-").join(" "))
    const fetchUniversities = async (location: any) => {
        await courseStore.fetchUniversities(location);
    }
    const convertToTitleCase = (inputString: string): string => {

        const words = inputString?.toLowerCase().split(' ');
    
        const titleCaseWords = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      
        return titleCaseWords?.join('');
      };
    // console.log(courseStore.universities[0]?.university.name)
    const filteredCourses: Course[] = [];
    courseStore.universities.forEach(universityData => {
    universityData.courses.forEach(course => {
        if (course.autonomousCollegeName === "Panimalar Engineering College") {
            filteredCourses.push(course);
        }
    });
});
    useEffect(()=>{
        fetchUniversities(location);
    }, [courseStore.selectedLocation.value])
    
    useEffect(()=>{
      if(universityName==="panimalar-engineering-college"){
        setUniversity("autonomous-anna-university"?.trim().split("-").join(" "));
      }
    },[])
    
  return (
    <div>
        <CourseHeader title={universityName==="panimalar-engineering-college"?"panimalar engineering college":university}/>
        {
                        courseStore.universities
                            .filter(ele => convertToTitleCase(ele.university?.name?.trim().split(/[^\w\s]/).join(""))=== convertToTitleCase(university||""))
                            .map((filteredUniversity,index) => (
                                <CourseCardList key={filteredUniversity.university.code} universityName='' index={index} university={filteredUniversity.university} courses={universityName==="panimalar-engineering-college"?filteredCourses:filteredUniversity.courses}
                                />
                            ))
                    }  
                    <Suggestion/>    
    </div>
  )
}

export default observer(UniversityDetails)