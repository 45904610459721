import React from 'react'

interface BannerProps {
    flag: boolean;
  }
  
  const Banner: React.FC<BannerProps> = ({ flag }) => {
  return (
      <div className="w-[100%] bg-[#F0F3FB] md:pt-[6%] xs:pt-[20%] min-h-[20px]">
          <div className={`w-[100%] px-[8%]  m-auto md:flex-row flex-col  items-center flex py-6`}>
              <div className='md:w-[50%] xs:w-[90%]'>
                  <p className='md:text-left text-center text-[#111F58] xl:text-[70px] lxl:text-[65px] xlg:text-[65px] smlg:text-[60px] msm:text-[50px] md:text-[40px] xs:text-[30px] font-bebasNeueRegular'>GET IN TOUCH WITH US:</p>
              </div>
              { flag ? <div>
                  <p className='font-poppinsBold text-[#111F58] fs-30w text-center leading-6'>Thank you for your interest in Maths.Engineering.</p>
              </div>:"" }
          </div>
      </div>
  )
}

export default Banner