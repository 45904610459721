import React, { useState } from 'react'
import Banner from './Banner'
import Form from './Form'
import ContactDetails from './ContactDetails'

const Contactus = () => {
  const [flag, setFlag]= useState(false)
  return (
   <>
   <Banner flag={flag}/>
   <div className='flex justify-between md:px-[8%] '>
    <ContactDetails/>
   <Form setFlag={setFlag} />
   </div>
   </>
  )
}

export default Contactus