import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeContext";

interface FormProps {
    setFlag: (flag: boolean) => void;
  }
  
  const Form: React.FC<FormProps> = ({ setFlag }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [fullname, setFullname] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [collegeUniversity, setCollegeUniversity] = useState("")
  const [city, setCity] = useState('');
  const [query, setQuery] = useState('');
  const [validation, setValidation] = useState("off");

  const { courseDetailStore } = useContext(StoreContext);

  const insertEnquiry = async (newEnquiry: any) => {
    await courseDetailStore.insertEnquiry(newEnquiry);
    let response = courseDetailStore.insertEnquiryStatus;
    if (response === "saved") {
        alert("Thanks for Enquiry. We will get back to you!")
        setAlertMsg("Thanks for Enquiry. We will get back to you!")
        setShowAlert(true);
        setFlag(true)
    }
    else {
        alert("Please try again.")
    }
    setFullname('');
    setEmail('');
    setPhoneNumber('');
    setCollegeUniversity('')
    setQuery('');
    setValidation((prev) => (prev === "off" ? "on" : "off"));
}

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const isValid = validateForm();
    if (validation === "off") {
        alert("Please authorize us to contact you for plans and promotions.")
        setAlertMsg("Please authorize us to contact you for plans and promotions.");
        return setShowAlert(true);
      }

    if(isValid === false){
        return;
    }

    let newEnquiry = {
        fullname,
        email,
        phoneNumber,
        collegeUniversity,
        city,
        query,
        designation
    }
    insertEnquiry(newEnquiry);

};

const validateForm = () => {
    const fullname_regex = /^[a-zA-Z\s]+$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email_local_part_regex = /[!#$%^&*()+={}\[\]:;<>,/?\\|~]/;
    const mobile_regex = /^(?!([0-9])\1{9}$)\d{10}$/;

    if (!fullname_regex.test(fullname)) {
        alert("Please enter a valid name without special characters or numbers.")
        setAlertMsg("Please enter a valid name without special characters or numbers.")
        setShowAlert(true);
        return false;
    }

    if (!email_regex.test(email)) {
        alert("Please enter a valid email address.")
        setAlertMsg("Please enter a valid email address.")
        setShowAlert(true);
        return false;
    }

    if (/-{2,}/.test(email) || /_{2,}/.test(email)) {
        alert("Please enter a valid email address.")
        setAlertMsg("Please enter a valid email address.")
        setShowAlert(true);
        return false;
    }

    const localPart = email.split('@')[0];
    if (email_local_part_regex.test(localPart)) {
        alert("Please enter a valid email address.")
        setAlertMsg("Please enter a valid email address.")
        setShowAlert(true);
        return false;
    }

    if (!mobile_regex.test(phoneNumber)) {
        alert("Please enter a valid 10-digit phone number.")
        setAlertMsg("Please enter a valid 10-digit phone number.")
        setShowAlert(true);
        return false;
    }

    return true;
}

const handleValidation = (e: any) => {
    setValidation((prev) => (prev === "off" ? "on" : "off"));
  };

  return (
    <div className="md:w-[60%] w-full md:mx-0 xs:mx-auto font-poppinsRegular mb-16 ">
      <p className="md:text-left text-center py-6 md:leading-7 smlg:leading-8 lxl:leading-9 xl:leading-10 xs:leading-5 xl:text-[30px] lxl:text-[28px] xlg:text-[26px] smlg:text-[24px] msm:text-[22px] md:text-[22px] lsm:text-[20px] xs:text-[15px]">
        Please provide the following information and get connected with us. You
        should receive a response within 24 hours.
      </p>
      <div className="w-full bg-[#EAEEF9] pb-8 rounded-[10px]">
        <form onSubmit={handleSubmit} className="w-[90%] mx-auto  grid grid-cols-1 gap-3">
          <label className="fs-20w pt-8 ">Your Name*</label>
          <input
            type="text"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            id=""
            placeholder="YOUR NAME"
            className="w-full flex-1 xs:h-10 md:h-12 p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300 xl:text-[15px] lxl:text-[14px] smlg:text-[13px] xs:text-[12px]"
           required
          />
          <label className="fs-20w ">Your Email*</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="YOUR EMAIL"
            className="w-full flex-1 xs:h-10 md:h-12 p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300 xl:text-[15px] lxl:text-[14px] smlg:text-[13px] xs:text-[12px]"
            required
          />
          <label className="fs-20w ">Contact Number*</label>
          <input
             type="number"
             value={phoneNumber}
             onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="CONTACT NUMBER"
            className="w-full flex-1 xs:h-10 md:h-12 p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300 xl:text-[15px] lxl:text-[14px] smlg:text-[13px] xs:text-[12px]"
            required
          />
          <label className="fs-20w ">College/University*</label>
          <input
            type="text"
            value={collegeUniversity}
            onChange={(e) => setCollegeUniversity(e.target.value)}
            placeholder="YOUR COLLEGE/UNIVERSITY"
            className="w-full flex-1 xs:h-10 md:h-12 p-3 border rounded-md focus:outline-none focus:ring focus:border-blue-300 xl:text-[15px] lxl:text-[14px] smlg:text-[13px] xs:text-[12px]"
            required
          />
          <label className="fs-20w">Message*</label>
          <textarea
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="YOUR MESSAGE"
            className=" w-full flex-1 min-h-[100px] p-3 border  mb-[1rem] rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none fs-20w font-poppinsRegular"
            required
          ></textarea>
          <div className="flex justify-between  mt-4 mb-3">
            <div className="flex mt-[3px] xs:mr-4 md:mr-8  smlg:mr-10 xlg:mr-12">
              <input
                type="checkbox"
                checked={validation === "on"}
                onChange={handleValidation}
                className="h-[20px] w-[20px]"
              />
            </div>
            <p className="text-left font-poppinsRegular xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px]">
              I authorize Maths Engineering to call/email/SMS/WhatsApp me for
              plans and ongoing promotions and offers.
            </p>
          </div>
          <button
            type="submit"
            className="w-[150px] mt-[1rem] bg-[#88C743] xs:m-auto md:m-0 py-2 px-6 rounded-md fs-20w font-poppinsSemibold"
          >
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
