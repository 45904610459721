import React from "react";
import AppStore from "./services/AppStore";
import CourseStore from "./components/Course/CourseStore";
import CourseDetailStore from "./components/CourseDetails/CourseDetailStore";
import HomeStore from "./components/Home/HomeStore";
import SearchStore from "./components/Header/SearchStore";

const appStore = new AppStore();
const apiProxy = appStore.apiProxy;

const courseStore = new CourseStore(apiProxy);
const courseDetailStore = new CourseDetailStore(apiProxy);
const homeStore = new HomeStore(apiProxy);
const searchStore = new SearchStore(apiProxy);

export const StoreContext = React.createContext({
  courseStore,
  courseDetailStore,
  homeStore,
  searchStore
});
