import expandIcon from "../../../assets/faq/Path 804.png";
import contractIcon from "../../../assets/faq/Path 805.png";

interface FAQ {
  code: number;
  question: string;
  answer: string;
}

interface FAQItemProps {
  index: number;
  faq: FAQ;
  expandedFAQ: number[];
  toggleFaq: (chapterId: number) => void;
}

export const FAQItem: React.FC<FAQItemProps> = ({
  faq,
  index,
  toggleFaq,
  expandedFAQ,
}) => {
  return (
    <div className="bg-white px-3 border-b-2 border-[#C8D7D7] py-3" key={faq.code}>
      <div className="flex items-center w-[100%] justify-between">
        <div
          className="md:flex items-center   cursor-pointer flex-1"
          onClick={() => toggleFaq(index)}
        >
          <div className="font-poppinsMedium fs-20w ">
            <span>{faq.question}</span>
          </div>
        </div>
        <div className="leading-none ">
          <button onClick={() => toggleFaq(index)}>
            <img
              src={expandedFAQ.includes(index) ? contractIcon : expandIcon}
              alt={expandedFAQ.includes(index) ? "-" : "+"}
              width="12"
              height="12"
            />
          </button>
        </div>
      </div>
      {expandedFAQ.includes(index) && (
        <div className="flex mt-4 mb-2 fs-20w font-poppinsRegular">
          <div>
            <p>{faq.answer}</p>
          </div>
        </div>
      )}
    </div>
  );
};
