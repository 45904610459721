import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { StoreContext } from '../../storeContext';
import {Filter} from "../../components/Course/Filter";
import CourseCardList from "../../components/Course/CourseCard/CourseCardList";
import styles from "./Course.module.css";
import {Menu} from '../../components/Header';
import { Suggestion } from '../../components/Course/Suggestion';
import CourseHeader from '../../components/Course/header/CourseHeader';
import { setTitle } from '../../services/Utils';
import { Course as co} from '../../services/Utils';


interface University {
    code: string,
    name: string
  }
interface Universities {
    university: University,
    courses: co[]
  }
const Course = () => {

const { courseStore } = useContext(StoreContext);

const [courseCount, setCourseCount] = useState(0) 

const calculateCourseCount = () => {
    let count = 0;
    courseStore.universities.map((item, index)=>{
        item.courses.map((ele, ind)=>{
            count++
        })
    })
    setCourseCount(count);
}

useEffect(() =>{
    calculateCourseCount()
}, [courseStore.selectedLocation]) 

useEffect(()=>{
    const meta ="Maths.Engineering is the best Digital Guide (book or study material) for all engineering mathematics students, as the syllabus is covered as per your university and previous year SOLVED QUESTION papers available with STEP-BY-STEP animated videos and PDF."
    setTitle("BEST Digital Guide for Engineering Mathematics",meta);
 
  },[])
//To Priortise university on the basis of selection made in Browse Our Courses component on home page
const selectedUni :Universities[] = []; 
const otherUni:Universities[] = []; 
courseStore.universities?.forEach((el)=>{
    if(el?.university?.code===courseStore.selectedUnivesity){
        selectedUni.push(el);
    }
    else otherUni.push(el);
})
const periorityUni = [...selectedUni,...otherUni]
    return (
        <div className={styles.container}>
            <div className={styles.courseRoot}>
                <div className={styles.courseContainer}>
                      <CourseHeader title={"COURSES"}/>
                      <Filter/>
                      {/* <div className='bg-[#EAEEF9] w-[100%] pt-[3%] pl-[8%] text-[#0E78BE] font-poppinsSemibold'><span>Search result ({courseCount})</span></div>  */}
                      { courseStore.selectedUnivesity?
                        periorityUni
                            .filter(ele => courseStore.isUniversitySelected  ? ele.university.code === courseStore.universityFilterCode : ele)
                            .map((filteredUniversity,index) => (
                                <CourseCardList key={filteredUniversity.university.code} index={index} university={filteredUniversity.university} universityName={filteredUniversity.university.name} courses={filteredUniversity.courses}
                                />
                            )):courseStore.universities.filter(ele => courseStore.isUniversitySelected  ? ele.university.code === courseStore.universityFilterCode : ele)
                            .map((filteredUniversity,index) => (
                                <CourseCardList key={filteredUniversity.university.code} index={index} university={filteredUniversity.university} universityName={filteredUniversity.university.name} courses={filteredUniversity.courses}
                                />
                            ))
                    }      
                    <Suggestion/>
                </div>
            </div>
        </div>
    );
};

export default observer(Course);