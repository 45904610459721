import React from 'react'
import PrivacyAndPolicy from '../components/StaticComponent/PrivacyAndPolicy'

const PrivacyPolicy = () => {
  return (
    <div className='w-[100%] bg-white border-4 border-white' >
        <PrivacyAndPolicy/>
     </div>
  )
}

export default PrivacyPolicy