import React from "react";
import KnowMore from "../components/StaticComponent/KnowMore/KnowMore";

const KnowMorePage = () => {
  return (
    <div className="w-[100%] bg-white border-4 border-white ">
        <KnowMore/>
    </div>
  );
};

export default KnowMorePage;
