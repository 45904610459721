import React from "react";
import styles from "./SuggestionCard.module.css";
import folder from "../../../assets/folder.svg";
import player from "../../../assets/video-play.svg";
import rightArrow from "../../../assets/Path_right.svg";
import { useNavigate } from "react-router-dom";
import { removeSubstring } from "../../../services/Utils";

interface Topic {
  code: string,
  name: string,
  regulations: String,
  semester: String,
  aboutSubjects: string,
  aboutChapters: String
}

interface TopicCardProps{
  topic: Topic
}

const SuggestionCard: React.FC<TopicCardProps> = ({topic}) => {

  const navigate = useNavigate();

  const handleViewTopicClick = (topicCode:String,topicName:String) => {
    navigate(`/course/${topicCode}/${topic.name.trim().split(" ").join("")}`);
    window.scrollTo(0, 0);
  }
 
  return (
    <>
    {
      topic.name !="Review" ?<div className="h-[100%] md:w-[100%] xs:w-[100%]" >
      <div
      onClick={() => handleViewTopicClick(topic.code, topic.name)}
        className={`${styles.customShadow} cursor-pointer w-[100%] h-[100%] bg-white xs:m-auto md:m-[0px] text-center rounded-[10px] overflow-hidden flex flex-col`}
      >
        <div className="xs:justify-center md:justify-left xs:w-[100%] fs-20w text-white font-poppinsSemibold md:p-3 xs:p-[10px]  bg-[#0E78BE] flex items-center flex-1auto h-100 leading-[1.3]">
          <p className="w-[100%] md:text-left xs:text-center">
          {topic.name}
          </p>
        </div>
        {/* <div className="w-[100%] bg-[#E1E7F7] ">
          <div className="w-[100%] flex items-center md:justify-start xs:justify-center md:p-3 xs:p-[10px] fs-15w font-poppinsMedium text-center xs:mx-auto ">
            <span
              className="leading-none md:pr-2 xs:pr-1 fs-15w"
            >
              {topic.code}
            </span>
            
          </div>
        </div> */}

        <div className="md:p-3 xs:p-[10px] pt-0">
          <div className="  w-[100%] flex md:flex-nowrap xs:flex-wrap  xs:items-center bg-white  fs-13w  md:mx-auto ">
            <div className="border-b-2 border-[#E1E7F7] w-full  xs:pt-[15px] xs:pb-[15px] lg:pt-[1.5rem] lg:pb-[1.5rem] flex xl:gap-4 smlg:gap-[10px] whitespace-nowrap	md:flex-nowrap xs:flex-wrap xs:gap-[5px]">
              <div className="flex items-center xl:gap-2 smlg:gap-[5px] xs:gap-[8px] w-full sm:w-auto">
                <img
                  src={folder}
                  alt=""
                  className="xl:h-[15px] lxl:h-[14px] smlg:h-[13px] xs:h-[12px]"
                />
                <span className="font-poppinsRegular fs-13w">
                  Includes{" "}
                  <span className="font-poppinsSemibold flex-auto">
                  {removeSubstring(topic.aboutSubjects)}
                  </span>
                </span>
              </div>
              <div className="flex items-center xl:gap-2 smlg:gap-[5px] xs:gap-[8px]">
                <img
                  src={player}
                  alt=""
                  className="xl:h-[15px] lxl:h-[14px] smlg:h-[13px] xs:h-[12px]"
                />
                <span className="font-poppinsRegular fs-13w">
                  Covers{" "}
                  <span className="font-poppinsSemibold flex-auto">
                  {topic.aboutChapters}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex items-center justify-center md:p-3 pt-0 xs:p-[10px] ">
          <button
            onClick={() => handleViewTopicClick(topic.code, topic.name)}
            className="flex items-center justify-center gap-[0.5rem] font-poppinsSemibold bg-blue-600 text-white  cursor-pointer border-none p-3 xl:h-[40px] lxl:h-[36px] md:h-[34px] xs:h-[30px] fs-15w rounded-[5px] w-full sm:w-auto justify-between"
          >
            VIEW TOPIC
            <img
              src={rightArrow}
              alt="Icon"
              className="w-[6px] h-[12px] mt-[-0.1rem]"
            />
          </button>
        </div>
      </div>
    </div> : ""
    }
    </>
        
  );
};

export default SuggestionCard;
