import React from "react";
import styles from "./Testimonials.module.css";
import yellowStar from "../../../assets/star.svg";
import filledStar from "../../../assets/testimonials/vuesax-linear-star.png";
import "./Testimonials.css";
import AVANTHIKA from "../../../assets/testimonials/Testimonial_AVANTHIKA.png";
import JASWANTH from "../../../assets/testimonials/Testimonial_JASWANTH.png";
import ADHITYA from "../../../assets/testimonials/Testimonial_ADHITYA.png";

interface Testimonial {
  id: Number;
  studentName: String;
  studentInfo: String;
  imageUrl: string;
  description: String;
  rating: number;
}

interface TestimonialCardProps {
  data: Testimonial;
  index: number;
}

const TestmonialsCard: React.FC<TestimonialCardProps> = ({ data, index }) => {
  const ratingStarFun = () => {
    const rating = data?.rating;
    const filledStarsCount =
      rating >= 0 && rating <= 5 ? Math.floor(rating) : 5; // Ensure rating is between 0 and 5
    const emptyStarsCount = 5 - filledStarsCount;

    const filledStars = new Array(filledStarsCount)
      .fill(0)
      .map((_, ind) => <img key={ind} src={filledStar} alt="filled star" />);

    const emptyStars = new Array(emptyStarsCount)
      .fill(0)
      .map((_, ind) => (
        <img key={ind + filledStarsCount} src={yellowStar} alt="empty star" />
      ));

    return (
      <div className="flex pt-[0.2rem]">
        {filledStars}
        {emptyStars}
      </div>
    );
  };

  const images = [JASWANTH, AVANTHIKA, ADHITYA];

  return (
    <div className="xs:h-[400px] relative md:h-[330px] md:w-[100%] md:flex md:flex-row bg-[#FFFF] shadow-md xs:rounded-[13px] md:rounded-[25px] xs:flex-col">
      <div className="md:pl-[5.2%] z-[109] md:pr-[1%] xs:h-[50%] xs:w-[100%]  md:h-[100%] md:w-[50%] md:flex md:items-center md:justify-end xs:justify-center ">
        <div className="md:h-[80%] md:w-[90%] xs:w-[100%] xs:h-[100%] xs:p-[1rem] md:p-[0%] md:flex md:flex-col md:items-start xs:flex xs:flex-col xs:items-center">
          <p className="tracking-tight xlg:text-[1.2rem] msm:text-[1rem] md:text-[16px] xs:text-[15px] text-[black] font-poppinsRegular md:text-start xs:text-center">
            {data.description}
          </p>
          <div className="mt-[30px]">
            <div className="w-32 h-32 md:hidden  overflow-hidden flex items-center justify-center rounded-full bg-[#22AAE1]">
              <div className="rounded-full">
                <img
                  className="object-cover w-full h-full rounded-full mt-[70px]"
                  src={images[index]}
                  alt="Testimonial"
                />
              </div>
            </div>
          </div>

          <p className="xl:text-[25px] lxl:text-[24px] xlg:text-[22px] smlg:text-[21px] msm:text-[21px] md:text-[21px] xs:text-[20px] font-poppinsSemibold font-bold pt-[1.2rem]">
            {data.studentName}
          </p>
          <p className="xl:text-[15px] lxl:text-[14px] xlg:text-[13px] smlg:text-[12px] msm:text-[11px] md:text-[11px] xs:text-[10px] font-poppinsRegular">
            {data.studentInfo}
          </p>
          {ratingStarFun()}
        </div>
      </div>

      <div className={`${styles.imageContainer} md:absolute hidden  top-0 right-0  md:h-[100%] md:w-[100%] xs:h-[50%] xs:w-[100%] md:flex md:items-center md:justify-center w-[50%]`}>
        <img className="mt-[-4rem] ml-[27rem] dlg:ml-[55%] md:w-[230px] md:h-[394px]" src={images[index]} />
      </div>

    </div>
  );
};

export default TestmonialsCard;

// mt-[-2.5rem] ml-[2rem]
