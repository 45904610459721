import { makeObservable, observable, action, computed } from "mobx";
import { apiHost } from "../../services/APIEndpoints"; 
import { buildSearchIndex, searchCourses } from "./SearchQuery";
import { Course } from "../../services/Utils";

const INIT: string = "init";
const PENDING: string = "pending";
const DONE: string = "done";
const ERROR: string = "error";

interface SearchCourses {
    course:Course,
    suggestion:String
}

interface BuildSearchIndexStatus {
    status: String,
    error:any
}

class SearchStore {
    apiProxy: any;
    state: String = INIT;
    buildSearchIndexStatus: BuildSearchIndexStatus = {
        status: "",
        error: null
    };
    searchedCoursesData:SearchCourses[] = [];
    searchedQuery:String = ""

    constructor( apiProxy:any ) {
        this.apiProxy = apiProxy;

        makeObservable(this, {
            state: observable,
            buildSearchIndexStatus:observable,
            searchedCoursesData:observable,

            isLoading: computed,
            isError: computed,
            isDone: computed,

            buildSearchIndex:action

        })
    }


    get isLoading() {
        return this.state === PENDING;
    }

    get isError() {
        return this.state === ERROR;
    }

    get isDone() {
        return this.state === DONE;
    }

    buildSearchIndex = async () => {
        this.state = PENDING;

        try {
            let response = await this.apiProxy.mutate(apiHost,buildSearchIndex);
            let result = await response.json();
            this.buildSearchIndexStatus = result.data.buildSearchIndex;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error);
        }
    }

    searchCourses = async (keyword:String) => {
        this.state = DONE;

        const variable = {
            input:keyword
        };

        try {
            let response = await this.apiProxy.mutate(apiHost,searchCourses,variable);
            let result = await response.json();
            this.searchedCoursesData = result.data.searchCourse.courses;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error);
        }

    }

}

export default SearchStore;