export const WHATSAPP_URL = "https://api.whatsapp.com/send?phone=%2B919360922729&text=Hi%2C%20I%20have%20a%20query%20regarding%20your%20learning%20community.";

export const YOUTUBE_URL = "https://www.youtube.com/channel/UCypeClt5J93vXBx5cOLwlVQ";

export const INSTAGRAM_URL = "https://www.instagram.com/maths.engineering/";

export const LINKEDIN_URL = "https://www.linkedin.com/company/promath-technology-private-limited/";

export const FACEBOOK_URL = "https://www.facebook.com/Maths.Engineering";

export const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.mathsengineering.courses";

export const TWITTER_URL = "https://twitter.com/mathsengg";