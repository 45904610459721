import React, { useContext } from 'react'
import { StoreContext } from '../storeContext'
import CourseCard from '../components/Course/CourseCard/CourseCard';
import { useNavigate } from 'react-router-dom';

const SearchResult = () => {
    const navigate = useNavigate();

    const {searchStore} = useContext(StoreContext);

    // Function to remove duplicate item from suggestion data
    const removeDuplicates = (array:any) => {
        const uniqueKeys = new Set();
        return array.filter((obj:any) => {
        const keyValue = obj.course.code;
        if (!uniqueKeys.has(keyValue)) {
            uniqueKeys.add(keyValue);
            return true;
        }
        return false;
        });
    };
    const uniqueArray = removeDuplicates(searchStore.searchedCoursesData);

    const handleViewCoursesClick = (value: any) => {
        navigate(`/course/${value.code}`);
      };

  return (
    <div className='w-full mt-[50px] p-[5%]'>
        <div className='w-[100%] m-auto md:px-[1%]'>
            <p className='p-[1rem] border-b border-[#888487] font-poppinsRegular text-xl'>Showing <span className='font-bold'>{uniqueArray.length}</span> result(s) for <span className='text-[#1178BE]'>{searchStore.searchedQuery}</span></p>
            <div className='md:w-[100%]  mt-[1rem] flex flex-wrap md:justify-start  md:gap-[1%] xs:gap-[5%] xs:justify-center'>
            {uniqueArray.map((ele:any, index:number) => (
                <CourseCard key={index} course={ele.course} onViewCoursesClick={()=>handleViewCoursesClick(ele.course)} />
            ))}
            </div>
        </div>
    </div>
  )
}

export default SearchResult