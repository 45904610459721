// MainCard.tsx
import React from 'react';

interface MainCardProps {
  number: string;
  text: string;
  icon: string;
  textColor: string;
}

const MainCard: React.FC<MainCardProps> = ({ number, text, icon, textColor }) => {
    
  return (
    <div className="bg-white xl:h-[88px] lxl:h-[80px] xlg:h-[80px] smlg:h-[80px] msm:h-[80px] md:h-[70px]  xl:p-4 lg:p-3 p-3 font-poppinsRegular rounded-lg shadow-md flex xl:gap-4 lg:gap-3 gap-2 items-center">
    <div style={{ color: `${textColor}` }} className="fs-50w font-bold font-bebasNeueRegular">{number}</div>
    <div className="font-poppinsMedium fs-20w w-[100px] lg:w-[80px] slg:w-[100px]"><p className='md:leading-5 leading-[15px]'>{text}</p></div>
      <img src={icon} alt={`Icon ${number}`} className="w-6 h-6  slg:w-10 slg:h-10 xs:hidden md:block ml-auto" />
    </div>
  );
};

export default MainCard;
