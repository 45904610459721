import image from "../../assets/ThankYou/image2 1 (1).jpg";

const ThankYou = () => {
  return (
    <div className="w-[100%] mb-[8%]">
      {/* <div className="md:mt-[3.5%] xs:mt-[15%] lxxsm:mt-[12%] hsm:mt-[10%] w-[100%] md:hidden">
                <img src={image} alt="Marketing-Image" className="w-[100%] h-[100%]" />
            </div> */}

      <div className="md:pl-[5%] xs:pt-[12%] md:pt-[3%] pb-[2%] md:mt-[6.5%] xs:mt-[13%] md:mb-[3%] xs:mb-[12%] md:h-[18vh] w-[100%] bg-[#F0F3FB] leading-[1.4] text-center">
        <p className="fs-50w text-[#111F58] font-poppinsBold">
          Thank You for Registering!
        </p>
      </div>
      <div className="text-center fs-40w leading-[1.4] text-[#000000] font-poppinsMedium md:px-[8%] xs:px-[4%]">
        <p>
          Welcome to the next step in your educational journey with
          <span className="text-[#2FADE2] underline"> Maths.Engineering</span>.
          We're thrilled to have you with us.
        </p>
      </div>
      <div className="text-center fs-40w leading-[1.4] text-[#000000] font-poppinsMedium md:px-[8%] md:my-[3%] xs:my-[10%] xs:px-[4%]">
        <p>
          Your Exclusive {" "}
          <span className="underline text-[#46B14E]">
            50% discount code
          </span>{" "}
          will be shared with you shortly.
        </p>
      </div>
      <div className="">
        <img src={image} alt="image" className="mx-auto" />
      </div>
      <div className="md:w-[60%] xs:w-[90%] fs-30w font-poppinsRegular text-center mx-auto leading-[1.4] ">
        <p className=" pb-[4%]">
          Keep an eye on your Whatsapp Chat for this special offer and get ready
          to unlock all the premium features our courses have to offer.
        </p>
        <p>
          While you wait, feel free to explore our free resources and
          familiarize yourself with our platform. We're committed to providing
          you with the best learning experience possible.
        </p>
      </div>
      <div className="md:pt-[4%] xs:pt-[12%] text-center xl:text-[55px] lxl:text-[52px] xlg:text-[47px]  md:text-[42px] xs:text-[34px] leading-[1.4] text-[#111F58] font-poppinsBold">
        <p>Stay tuned, and Happy learning!</p>
      </div>
      {/* <div className="md:pl-[15%] md:w-[100%] xs:w-[90%] mx-auto xlg:leading-[40px] lg:leading-[30px] pt-[4%] md:pr-[8%] ">
                    <p className="fs-30w font-poppinsMedium  mb-6 mt-4">
                        Welcome to the next step in your educational journey with
                        Maths.engineering. We're thrilled to have you with us.
                    </p>
                    <p className="fs-25w font-popinsRegular mt-[3%] mb-8">
                        Your{" "}
                        <span className="font-poppinsSemibold">
                            Exclusive 50% discount code
                        </span>{" "}
                        will be shared with you shortly. Keep an eye on your inbox for this
                        special offer and get ready to unlock all the premium features our
                        courses have to offer.
                    </p>
                    <p className="text-[#111F58] fs-40w font-poppinsSemibold mb-4">
                        What's Next?
                    </p>
                    <p className="font-poppinsRegular fs-25w mb-10">
                        While you wait, feel free to explore our free resources and
                        familiarize yourself with our platform. We're committed to providing
                        you with the best learning experience possible.
                    </p>
                    <p className=" fs-25w  font-poppinsBold text-center">Stay tuned, and Happy learning!</p>
                </div> */}

      {/* <div className="md:mt-[3.5%] xs:mt-[15%] w-[100%]  xs:hidden md:block">
                <img src={image} alt="Marketing-Image" className="max-h-[786px] " />
            </div> */}
    </div>
  );
};

export default ThankYou;
