import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import CourseHeader from '../components/Course/header/CourseHeader';
import { StoreContext } from '../storeContext';
import SuggestionCard from '../components/Course/Suggestion/SuggestionCard';
import { setTitle } from '../services/Utils';
import search_icon from "../assets/search-normal.png"

const Topics = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [scrollAmount, setScrollAmount] = useState(100);
  const { courseStore } = useContext(StoreContext);


  const filteredTopics = courseStore.topics.filter(topic =>
    topic.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchClick = () => {
    window.scrollBy({
      top: scrollAmount,
      behavior: 'smooth'
    });
  };



  useEffect(() => {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth <= 768;
    if (isMobile) {
      setScrollAmount(200);
    } else {
      setScrollAmount(280);
    }
  }, []);

  useEffect(() => {
    if (courseStore.topics.length === 0) {
      courseStore.fetchTopics()
    }
  }, [])

  useEffect(() => {
    const meta = "Topics for Engineering Mathematics."
    setTitle("Topics for Engineering Mathematics", meta);

  }, [])

  return (
    <div className="border">
      <CourseHeader title={"TOPICS"} />
      <div className="w-[100%] bg-white">
        <div className="h-[100%] md:px-[8%] xs:px-[4%] m-auto pt-0 pb-8 ">
          <div className='flex items-center fs-20w font-poppinsMedium pt-[1rem] mb-[1rem]'>
            <p className='p-[0.5rem] mb-[1rem] mt-[1rem]'>Search</p>
            <input className='bg-[#D2E7EB] xs:w-[80%] md:w-auto p-[0.5rem] mb-[1rem] mt-[1rem] rounded-[5px] border-none mr-4 border border-gray-300 focus:outline-[#F6AE35]'
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="Search Topics"
            />
            <div className='bg-[#E6F1F9] fs-20w p-[0.6rem] mb-[1rem] mt-[1rem] rounded-[10px] flex items-center justify-center'>
              <img onClick={handleSearchClick} src={search_icon} alt="" className='' />
            </div>
          </div>
          <div className="m-auto grid lg:grid-cols-4 gap-[16px] md:grid-cols-3 sm:grid-cols-2 sxsm:grid-cols-2">
            {filteredTopics
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((ele, index) => (
                <SuggestionCard key={index} topic={ele} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Topics);