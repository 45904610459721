import { action, makeObservable, observable, computed, runInAction } from 'mobx';

import APIProxy from './APIProxy';

const blankCredentials = {
  email: '',
  token: '',
  role: '',
  username: '',
  id: '',
};

const INIT = 'init';
const PENDING = 'pending';
const INVALID = 'invalid';
const DONE = 'done';
const ERROR = 'error';

class AppStore {
  state = INIT;

  credentials = blankCredentials;

  currentComponent = null;

  apiProxy = new APIProxy();

  menus = [];

  socketToken = null;

  constructor() {
    // this.setSessionFromStorage();

    makeObservable(this, {
      state: observable,
      menus: observable,

      currentComponent: observable,

      credentials: observable,
      logout: action,

      // authenticate: action,
      // navigateTo: action,

      isLoading: computed,
      isError: computed,
      isInvalid: computed,
      isHead: computed,
      isMember: computed,

      socketToken: observable,
    });
  }

  get isLoading() {
    return this.state === PENDING;
  }

  get isError() {
    return this.state === ERROR;
  }

  get isInvalid() {
    return this.state === INVALID;
  }

  // setSessionFromStorage = () => {
  //   const storedCredentials = localStorage.getItem('credentials');

  //   if (storedCredentials !== null) {
  //     this.credentials = JSON.parse(storedCredentials);
  //     this.updateContext();
  //   } else {
  //     this.credentials = blankCredentials;
  //     this.updateContext();
  //   }
  // };

  // authenticate = async values => {
  //   this.state = PENDING;
  //   this.credentials = blankCredentials;

  //   const result = await this.loginStore.authenticate(values);

  //   runInAction(() => {
  //     this.state = result.state;

  //     if (result.state !== DONE) {
  //       return;
  //     }

  //     const data = result.data;

  //     this.credentials.email = data.email;
  //     this.credentials.token = data.id;
  //     this.credentials.role = data.userType;
  //     this.credentials.username = data.name;
  //     this.credentials.id = data.id;

  //     this.apiProxy.updateCredentialHeaders(this.credentials);

  //     this.updateContext();
  //     this.persistCredentials();
  //   });
  // };

  persistCredentials = () => {
    localStorage.setItem('credentials', JSON.stringify(this.credentials));
  };

  // resolveLandingPage() {
  //   for (let menu of this.menus) {
  //     if (menu.isLandingPage) {
  //       this.navigateTo(menu.key);
  //       return;
  //     }
  //   }
  // }

  // updateContext = () => {
  //   this.apiProxy.updateCredentialHeaders(this.credentials);
  //   this.resolveMenu();
  //   this.resolveLandingPage();
  // };

  // navigateTo(key:any) {
  //   this.currentComponent = { key: key };
  // }

  resolveMenu() {
    // if (!this.isLoggedIn()) {
    //     this.menus = require('./menus/GuestMenu.json');
    //     return;
    // }
    // this.menus = require('./menus/AdminRoleMenu.json');
  }

 



  // isLoggedIn = () => {
  //   return !(
  //     isBlank(this.credentials.email) || isBlank(this.credentials.token)
  //   );
  // };

  logout = () => {
    localStorage.setItem('credentials', JSON.stringify(blankCredentials));
    window.location.reload();
  };

  setProxy = (apiProxy:any) => {
    this.apiProxy = apiProxy;
  };

  get isHead() {
    return this.credentials.role === 'head';
  }

  get isMember() {
    return this.credentials.role === 'member';
  }
}

export default AppStore;
