import React from 'react'
import Terms from '../components/StaticComponent/TermsAndConditions'

const TermsCondition = () => {
  return (
    <div className='w-[100%] bg-white border-4 border-white' >
      <Terms/>
    </div>
  )
}

export default TermsCondition