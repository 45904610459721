import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Suggestion.module.css';
import SuggestionCard from './SuggestionCard';
import { StoreContext } from "../../../storeContext";
import { observer } from 'mobx-react';

const SuggestionList: React.FunctionComponent = (props) => {

  const { courseStore } = useContext(StoreContext);

  const navigate = useNavigate();

  const handleTopicsView = () => {
    navigate("/topic");
    window.scrollTo(0, 0);
  }


  useEffect(() => {
    if (courseStore.topics.length === 0) {
      courseStore.fetchTopics()
    }
  }, [])
  
  return (
    <div className={`${styles["not-able-container"]} lg:py-11 mb-[5rem] pt-8 pb-8`} >
      <div className="w-[100%] mb-[30px]  flex md:items-start xs:mx-auto text-[#111F58]  fs-40w font-poppinsBold md:text-left xs:justify-center md:justify-start leading-[1.3]">
      <p>Can't find your course? Explore our extensive Engineering Mathematics library!</p>
      </div>
      <div className="grid lg:grid-cols-4 md:gap-[16px] xs:gap-[20px] md:grid-cols-3 sm:grid-cols-2 sxsm:grid-cols-2 md:mb-12 mb-10">
        {courseStore.topics.slice(0,4).map((ele, index) => (
          <SuggestionCard key={index} topic={ele} />
        ))}
      </div>
     
      <div className="text-[#000000] fs-20w font-poppinsSemibold rounded-[10px] bg-[#88C743] inline-flex leading-none xs:px-[20px] xs:py-[15px] lg:px-[25px] lg:py-[15px] cursor-pointer " onClick={handleTopicsView}>VIEW ALL TOPICS</div>
      <div className='md:mb-12 mb-10'></div>
      <div className="border-[1px] border-[#707070] rounded-[10px] py-5 flex justify-center mx-auto items-center xl:w-[785px] lxl:w-[720px] xlg:w-[660px] smlg:w-[600px] msm:w-[530px] md:w-[450px] xs:w-[100%] ">
        <div className='font-poppinsMedium  xl:w-[562px] lxl:w-[520px] xlg:w-[480px] smlg:w-[440px] msm:w-[400px] md:w-[360px] xs:w-[313px] fs-30w'>If you are not able to find the course, <a className="underline text-[#88c743] font-poppinsMedium" href="mailto:support@maths.engineering">Connect with us</a></div>
        
      </div>
    </div>
  );
};

export const Suggestion = observer(SuggestionList);
