import React, { useContext, useEffect, useRef, useState } from 'react'
import TestmonialsCard from './TestmonialsCard';
import Slider, { Settings }  from 'react-slick';
import nextBtn from "../../../assets/nextbtn.svg";
import prevBtn from "../../../assets/prevbtn.svg";
import "./Testimonials.css"
import { StoreContext } from '../../../storeContext';
import { observer } from 'mobx-react';
import { testimonials } from '../../../sampleData';

const Testimonials = () => {

    const sliderRef:any = useRef(null);

    const next = () => {
      sliderRef.current.slickNext();
    };
  
    const previous = () => {
      sliderRef.current.slickPrev();
    };

    const settings: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        adaptiveHeight:true,
    
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 892,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        
      };

  return (
    <div className="absolute left-0 mt-[80px] md:mt-[26%] slg:mt-[20%] xl:mt-[28%] xxl:mt-[22%] xxxl:mt-[18%]  w-full md:px-[8%] xs:px-[5%] py-[2%] pt-[5%] ">
      {/* <!-- Slider Wrapper --> */}
      <p className='xl:text-[60px] lxl:text-[55px] xlg:text-[50px] smlg:text-[45px] msm:text-[40px] md:text-[40px] xs:text-[35px] md:pl-[9.3%] flex md:items-center md:justify-start xs:justify-center font-poppinsBold m-0 text-[#111F58]'>Testimonials</p>
            
      <div className="xs:h-[700px] md:h-[480px] md:pt-[2rem] xs:pt-[0.5rem]  w-[100%] m-auto overflow-x-hidden relative">
          <Slider ref={sliderRef} className="my-custom-slider" {...settings}>
              {testimonials.map((el, index) => (
                <>
                  <TestmonialsCard data={el} index={index} key={index}/>
                
              </>
              ))}
          </Slider>
          <div className="hidden md:flex absolute mt-[-60px] ml-[18%]">
                  <button onClick={previous} className="mx-2"><img className="h-9 w-9" src={prevBtn} alt="Previous"/></button>
                  <button onClick={next} className="mx-2"><img className="h-9 w-9" src={nextBtn} alt="Next"/></button>
              </div>
          <style>
        {`
          @media (min-width: 768px) {
            .my-custom-slider .slick-dots {
              /* Customize the position of the dots */
              position: absolute;
              margin-top:"50px";
              bottom: 50px; /* Adjust as needed */
              left: 13.5%; /* Adjust as needed */
              transform: translateX(-50%);
            }
          }
        `}
      </style>
      </div>

      {/* <!-- Button Wrapper --> */}
      <div className="md:hidden flex absolute xl:top-[410px] lxl:top-[410px] xlg:top-[380px] smlg:top-[380px] msm:top-[380px] md:top-[380px] xs:w-[90%] md:w-[20%] md:ml-[7.7%]  md:gap-[1%] xs:gap-[32%] mt-[-275px] md:justify-start xs:justify-center">
          <button onClick={previous} className="mx-2"><img className="h-9 w-9" src={prevBtn} alt="Previous"/></button>
          <button onClick={next} className="mx-2"><img className="h-9 w-9" src={nextBtn} alt="Next"/></button>
      </div>
</div>

  )
}

export default observer(Testimonials);