import React from 'react';
import styles from "./CourseHeader.module.css"

interface courseHeaderProps {
    title: string | null | undefined;
}

const subHeader = "Discover University-Aligned Courses";
const topicSubHeader = "Discover University-Aligned Topics";
const contentText = "Explore Courses Tailored to Your University's Syllabus.";
const topicContentText="Can't find your course? Choose from many Topics to match your syllabus."
const filterText = "Use our filters to find the right course for you.";

const CourseHeader: React.FC<courseHeaderProps> = ({title}) => {
  return (
    <>
        <div className={`${styles.headerbg} hidden md:block min-h-[250px] w-[100%]`}>
            <div className={`w-[100%] px-[8%]  m-auto ${title === "TOPICS" ? 'mt-[70px]' : 'mt-[50px]'} flex-col justify-center flex py-10`}>
                <p className='fs-120w leading-none font-extrabold text-[#111F58] font-bebasNeueRegular py-10'>{title}</p>
                {/* <p className='fs-30w text-[#111F58] font-poppinsSemibold mb-1'>{title === "TOPICS" ? topicSubHeader :subHeader}</p> */}
                {/* <p className='fs-25w max-w-[800px] text-[#111F58] font-poppinsMedium '>{title=== "TOPICS" ? topicContentText: contentText}</p> */}
            </div>
            <div className={`${styles.footerBox} xl:h-[100px] lxl:h-[90px] lg:h-[80px] h-[70px] w-[100%]`}>
                <div className='h-[100%] px-[8%] m-auto flex items-center'>
                    <p className='text-[#000000]  fs-30w font-poppinsMedium'>{title=== "TOPICS" ? topicContentText: contentText}</p>
                </div>
            </div>
        </div>

        <div className='hidden md:hidden xs:block  w-[100%] mt-[64px] py-[1%] pl-[5%] py-[7%] bg-[#F0F3FB]'>
            <div className='h-[100%] w-[90%]'>
                <p className='text-[50px] font-extrabold leading-none text-[#111F58] font-bebasNeueRegular mb-2'>{title}</p>
                {/* <p className='mb-1 fs-30w text-[#111F58] font-poppinsBold mb-1'>{title === "TOPICS" ? topicSubHeader :subHeader}</p> */}
                <p className='fs-25w mb-2 text-md max-w-[800px] text-[#111F58] font-poppinsMedium '>{title==="TOPICS" ? topicContentText: contentText}</p>
                {/* <p className='text-[#000000] text-md font-poppinsMedium'>{filterText}</p> */}
            </div>
        </div>
    </>
  )
}

export default CourseHeader