import React, { useState } from "react";
import { FAQItem } from "./FAQItem";
import { FAQS } from "../../../sampleData";

const FAQ = () => {
  const [expandedFAQ, setexpandedFAQ] = useState<number[]>([]);

  const toggleFaq = (chapterId: number) => {
    setexpandedFAQ((prevState) => {
      if (prevState.includes(chapterId)) {
        return [];
      } else {
        return [chapterId];
      }
    });
  };

  return (
    <div className="font-poppins pt-[2%] pb-5 bg-[#EAEEF9] ">
      <p className="xl:text-[60px] lxl:text-[55px] xlg:text-[50px] smlg:text-[45px] msm:text-[40px] md:text-[40px] xs:text-[35px] font-poppinsBold text-[#111F58] p-5 pl-0 text-center">
        FAQs
      </p>
      <div className="border-5 md:w-[70%] xs:w-[90%] rounded-lg mx-auto">
        <div>
          {FAQS.map((ele, index) => (
            <FAQItem
              key={index}
              faq={ele}
              toggleFaq={toggleFaq}
              expandedFAQ={expandedFAQ}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
