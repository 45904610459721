import React from "react";
import player from "../../../assets/video-play.svg";
import folder from "../../../assets/folder.svg";
import rightArrow from "../../../assets/Path_right.svg";
import styles from "./CourseCard.module.css";
import { removeSubstring } from "../../../services/Utils";
import { Course } from "../../../services/Utils";

interface CourseCardProps {
  course: Course;
  onViewCoursesClick: () => void;
}

const CourseCard: React.FC<CourseCardProps> = ({
  course,
  onViewCoursesClick,
}) => {
  const convertToTitleCase = (inputString: string): string => {
    const words: string[] = inputString.toLowerCase().split(" ");
    const skipWords: string[] = ["is", "the", "for", "and"];

    const titleCaseWords: string[] = words.map((word) => {
      if (skipWords.includes(word)) {
        return word;
      } else {
        if (word.charAt(0) === "(") {
          return word.charAt(0) + word.charAt(1).toUpperCase() + word.slice(2);
        } else if (word.charAt(0) === "i") {
          return word.toUpperCase();
        } else if (word.includes("-")) {
          const parts = word.split("-");
          return (
            parts[0].charAt(0).toUpperCase() +
            parts[0].slice(1) +
            "-" +
            parts[1].toUpperCase()
          );
        } else return word.charAt(0).toUpperCase() + word.slice(1);
      }
    });

    return titleCaseWords.join(" ");
  };

  return (
    <div className="h-[100%] md:w-[100%] xs:w-[100%]">
      <div
        className={`${styles.customShadow} cursor-pointer w-[100%] h-[100%] bg-white xs:m-auto md:m-[0px] text-center rounded-[10px] overflow-hidden flex flex-col`}
        onClick={onViewCoursesClick}
      >
         {course.autonomousCollegeName?.trim() !=="" ?<div><p className="flex items-center justify-center fs-15w font-poppinsSemibold md:p-3 xs:p-[10px] h-[50px]">{course.autonomousCollegeName}</p></div>:""}
        <div className="xs:justify-center md:justify-left xs:w-[100%] fs-20w text-white font-poppinsSemibold md:p-3 xs:p-[10px]  bg-[#0E78BE] flex items-center flex-1auto h-100 leading-[1.3]">
          <p className="w-[100%] md:text-left xs:text-center">
            {convertToTitleCase(course.name)}
          </p>
        </div>
        <div className="w-[100%] bg-[#E1E7F7] ">
          <div className="w-[100%] flex items-center md:justify-start xs:justify-center md:p-3 xs:p-[10px] fs-15w font-poppinsMedium text-center xs:mx-auto ">
            <span
              className={`${course.university !== "GLOBAL" && course.regulations != " " && course.semester != " " ? "border-r-[1.5px] border-black leading-none" : null} md:pr-2 xs:pr-1 fs-15w`}
            >
              {course.subjectCode}
            </span>
            {course.university !== "GLOBAL" && course.regulations != " " ? (
              <span className="border-r-[1.5px] border-black md:pl-2 md:pr-2 xs:pr-[3px] xs:pl-[3px]  fs-15w leading-none">
                R-{course.regulations}
              </span>
            ) : null}
            {course.university !== "GLOBAL" && course.semester != " " ? (
              <span className="md:pl-2 xs:pl-[6px] fs-15w leading-none">Sem-{course.semester}</span>
            ) : null}
          </div>
        </div>
        <div className="md:p-3 xs:p-[10px] pt-0">
          <div className="w-[100%]  xs:items-center bg-white  fs-13w  md:mx-auto border-b-2 border-[#E1E7F7] xs:pt-[15px] xs:pb-[15px] lg:pt-[1.5rem] lg:pb-[1.5rem]">
            <div className=" w-full flex xl:gap-4 smlg:gap-[10px] whitespace-nowrap	md:flex-nowrap xs:flex-wrap xs:gap-[5px]">
              <div className="flex items-center xl:gap-2 smlg:gap-[5px] xs:gap-[8px] w-full sm:w-auto">
                <img
                  src={folder}
                  alt=""
                  className="xl:h-[15px] lxl:h-[14px] smlg:h-[13px] xs:h-[12px]"
                />
                <span className="font-poppinsRegular fs-13w">
                  Includes{" "}
                  <span className="font-poppinsSemibold flex-auto">
                    {removeSubstring(course.aboutSubjects)}
                  </span>
                </span>
              </div>
              <div className="flex items-center xl:gap-2 smlg:gap-[5px] xs:gap-[8px]">
                <img
                  src={player}
                  alt=""
                  className="xl:h-[15px] lxl:h-[14px] smlg:h-[13px] xs:h-[12px]"
                />
                <span className="font-poppinsRegular fs-13w">
                  Covers{" "}
                  <span className="font-poppinsSemibold flex-auto">
                    {course.aboutChapters}
                  </span>
                </span>
              </div>
            </div>
            {/* <div className="flex items-center xl:gap-2 smlg:gap-[5px] xs:gap-[8px] smlg:mt-[8px] xs:mt-[5px]">
                <img
                  src={NoteRemover}
                  alt=""
                  className="xl:h-[15px] lxl:h-[14px] smlg:h-[13px] xs:h-[12px]"
                />
                <span className="font-poppinsRegular fs-13w">   
                Over 33 Problems solved
                </span>
              </div> */}

          </div>
        </div>
        <div className=" flex items-center justify-center md:p-3 pt-0 xs:p-[10px] ">
          <button
            onClick={onViewCoursesClick}
            className="flex items-center justify-center gap-[0.5rem] font-poppinsSemibold bg-blue-600 text-white  cursor-pointer border-none p-3 xl:h-[40px] lxl:h-[36px] md:h-[34px] xs:h-[30px] fs-15w rounded-[5px] w-full sm:w-auto justify-between"
          >
            {course.university !== "GLOBAL" ? "VIEW COURSE" : "VIEW TOPIC"}
            <img
              src={rightArrow}
              alt="Icon"
              className="w-[6px] h-[12px] mt-[-0.1rem]"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
