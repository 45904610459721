import { makeObservable, observable, action, computed } from "mobx";
import { apiHost } from "../../services/APIEndpoints";
import { getTestimonialQuery, insertSubscriber as insertSubscriberQuery, getKickers } from "./Query";


const INIT: string = "init";
const PENDING: string = "pending";
const DONE: string = "done";
const ERROR: string = "error";

interface Testimonial {
    id:Number,
    studentName:String,
    studentInfo:String,
    description:String,
    rating:Number,
    createdAt:Date
}

interface NewSubscriber {
    emailId: String
}

interface InsertSubscriberResult {
    status: String,
    error: any
}

interface Kicker{
    heading: String,
    subheading: String
}

class HomeStore {
    apiProxy: any;
    state: String = INIT;
    testimonialData: Testimonial[] = [];
    kickerList: Kicker[] = [];
    insertSubscriberResult: InsertSubscriberResult = {
        status: "",
        error: ""
    };


    constructor( apiProxy:any ) {
        this.apiProxy = apiProxy;

        makeObservable(this, {
            state: observable,
            testimonialData:observable,
            insertSubscriberResult: observable,
            kickerList: observable,

            isLoading: computed,
            isError: computed,
            isDone: computed,

            fetchTestimonials:action,
            insertSubscriber: action,
            fetchKickers: action
        })
    }


    get isLoading() {
        return this.state === PENDING;
    }

    get isError() {
        return this.state === ERROR;
    }

    get isDone() {
        return this.state === DONE;
    }

    fetchTestimonials =async () => {
        this.state = PENDING

        try {
            let response = await this.apiProxy.query(apiHost,getTestimonialQuery);
            let result = await response.json();
            this.testimonialData = result.data.getTestimonials.testimonials;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error);
        }
    }


    insertSubscriber = async (newSubscriber: NewSubscriber) => {
        this.state = PENDING;

        let variable = {
            input: newSubscriber
        }

        try {
            let response = await this.apiProxy.mutate(apiHost, insertSubscriberQuery, variable);
            let result = await response.json();
            this.insertSubscriberResult = result.data.insertSubscriber;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error)
        }
    }

    fetchKickers =async () => {
        this.state = PENDING

        try {
            let response = await this.apiProxy.query(apiHost,getKickers);
            let result = await response.json();
            this.kickerList = result.data.getKickers.kickers;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error);
        }
    }
}

export default HomeStore;