import React, { useContext } from 'react';
import arrow_right from "../../../assets/arrow-right.svg";
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../../storeContext';

interface ViewMoreProps {
    count: number;
    screenWidth: any;
}

const ViewMoreCard: React.FC<ViewMoreProps>  = ({count, screenWidth}) => {

    const navigate = useNavigate();
    const { courseStore } = useContext(StoreContext);

    const navigateToCourse = () => {
        navigate("/course");
        courseStore.universityFilterCode = "";
        window.scrollTo(0, 0);
    }
    return (
        <div className={`${count < 4 ? `md:w-1/4 h-[100%] xs:px-[0%] ${screenWidth < 768 ? "xs:w-[100%]" : "xs:w-[50%] "} md:w-[100%] md:px-[0.5%] xs:px-[0%]` : "h-[100%] md:w-[100%] xs:w-[100%]  md:px-[2%] xs:px-[0%]"}`}>
            <div key={"view-more"} className={`bg-[#F6AE35] flex flex-col items-center justify-center h-[100%] p-[3%] xs:m-auto text-center rounded-[7px] shadow-md overflow-hidden`}>
                <p className="fs-30w font-poppinsBold">VIEW MORE</p>
                <img onClick={navigateToCourse} className='hover:cursor-pointer' src={arrow_right} />
            </div>
        </div>
    )
}

export default ViewMoreCard;

