import React, { useContext, useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings }  from 'react-slick';
import styles from "./LearnerSpeak.module.css"
import LearnerSpeakCard from './LearnerSpeakCard';
import nextBtn from "../../../assets/nextbtn.svg";
import prevBtn from "../../../assets/prevbtn.svg";
import "./LearnerSpeak.css";

import { StoreContext } from '../../../storeContext';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

interface CustomDotProps {
  onClick: () => void;
  active: boolean;
}

interface Review {
  id:Number,
  courseCode:String,
  description:String,
  name: String,
  role:String,
  org:String
  category:String
}

const LeanerSpeak = () => {

  const { courseDetailStore } = useContext( StoreContext );
  // const { courseCode } : any = useParams();
  const courseCode = "1FY2-01"
  const sliderRef:any = useRef(null);

  const fetchReviews = async (courseCode:String) => {
    await courseDetailStore.fetchReviews(courseCode);
  }

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };




  const settings: Settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight:true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 892,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    
  };

  useEffect(() => {
    fetchReviews(courseCode)
  },[courseCode])

  return (
        <div className={`${styles.container} courseSlider relative ` }>
          <div className='flex items-center justify-between'>
            <p className="fs-30w font-poppinsSemibold font-bold text-[#115DE2] p-5 pl-0">Learners Speak</p>
            <div className='md:flex hidden gap-[10px] item-center mt-1'>
                <button onClick={previous}><img height={"30px"} width={"30px"} src={prevBtn}/></button>
                <button onClick={next}><img height={"30px"} width={"30px"} src={nextBtn}/></button>
            </div>
          </div>

          
            <Slider ref={sliderRef} {...settings} className='learner-custom-slider'>
              {courseDetailStore.reviewsData?.map((item, index) => (
                <LearnerSpeakCard data={item} key={item.id+""}/>
              ))}
            </Slider>
       
          <div className='md:hidden flex mt-[18px] xs:gap-[10px] justify-center'>
                <button onClick={previous}><img height={"38px"} width={"38px"} src={prevBtn}/></button>
                <button onClick={next}><img height={"38px"} width={"38px"} src={nextBtn}/></button>
            </div>      
    </div>
  )
}

export default observer(LeanerSpeak);