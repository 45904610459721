import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import VideoImg from "../../../assets/video.png";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import Files from "../../../assets/vuesax-linear-document-copy.svg";
import RxVideo from "../../../assets/vuesax-linear-video-play (1).svg";
import NoteRemover from "../../../assets/vuesax-linear-note-remove.svg"
import { StoreContext } from "../../../storeContext";
import { observer } from "mobx-react";
import ply_str_logo from "../../../assets/CourseDetailCard/Group 1814.png"
import solvedQuestionIcon from "../../../assets/CourseDetailCard/SQP ICON.svg"
import { YOUTUBE_URL, INSTAGRAM_URL, LINKEDIN_URL, FACEBOOK_URL, TWITTER_URL, PLAY_STORE_URL, } from "../../../constants";

const SAMPLE_VIDEO_URL = "https://www.youtube.com/watch?v=XklqIBMnLaAL";

const CourseDetailsCard = () => {
  const { courseDetailStore } = useContext(StoreContext);
  const [subject, setSubject] = useState(0);
  const [chapters, setChapters] = useState(0);
  const [problems, setProblems] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
 
  const navigateToPlayStore = () => {
    window.open(PLAY_STORE_URL, "_blank");
  };

  const calculateCourseMeta = () => {
    let chapt = 0;
    let pb = 0;

    setSubject(courseDetailStore?.partChapters?.length);
    courseDetailStore.partChapters?.forEach((item) => {
      const parts = item.part.meta.split("|");

      if (parts.length === 2) {
        const chaptersMatch = parts[0].match(/\d+/);
        const problemsMatch = parts[1].trim().match(/\d+/); // Trim whitespace before matching

        const chaptersNumber = chaptersMatch ? parseInt(chaptersMatch[0], 10) : NaN;
        const problemsNumber = problemsMatch ? parseInt(problemsMatch[0], 10) : 0;

        chapt += chaptersNumber;
        pb += problemsNumber;
      } else {
        console.log("Invalid input format");
      }
    });

    setChapters(chapt);
    setProblems(pb);
  };

  const handlePlay = () => {
    setIsPlaying(true);
    const urls = courseDetailStore.courseMeta[0]?.urls;
    const videoUrl = urls || "https://www.youtube.com/watch?v=XklqIBMnLaAL";
    setVideoUrl(videoUrl);
  };

  useEffect(() => {
    calculateCourseMeta();
  }, [courseDetailStore.partChapters]);
  return (
    <div
      ref={courseDetailStore.customScroll.stickyCardRef}
      className={`rounded overflow-hidden shadow-md z-[110] msm:ml-auto msm:mr-[2%] mt-[4%] min-w-[300px] mb-[40px] msm:mb-auto  m-auto bg-[#F0F3FB] msm:h-[85vh] h-[435px] lsm:h-[430px] lxsm:h-[470px] lxxsm:h-[490px] lxxxsm:h-[500px] hsm:h-[420px] w-[90%] hsm:w-[27vw]  rounded-lg ${!courseDetailStore.customScroll.isSticky
        ? "msm:absolute msm:bottom-1 md:right-5 slg:right-20"
        : "msm:fixed top-[3rem] md:right-5 slg:right-20 "
        }`}
    >
      <div
        className="msm:h-[30vh] msm:max-h-[250px] lg:max-h[250px] xl:max-h-[325px] xxl:max-h-[400px] xxxl:max-h-[450px]  "
        onClick={() => handlePlay()}
      >
        {!isPlaying ? (
          <img
            src={VideoImg}
            alt="Video Thumbnail"
            className="w-[100%] h-[100%] object-cover  cursor-pointer rounded-lg"
          />
        ) : (
          <ReactPlayer
            url={videoUrl}
            playing={isPlaying}
            controls={true}
            width="100%"
            height="100%"
            className="w-[100%] h-[100%] object-cover rounded"
          />
        )}
      </div>

      <div className="hidden msm:flex flex-col px-[6%] justify-start p-[2%] bg-[#F0F3FB]">
        <p className="msm:text-[1.5vw] text-[30px]  font-bold font-poppinsSemibold msm:text-left ">
          {courseDetailStore.courseMeta[0]?.courseName}
        </p>
        {/* <p className="msm:text-[1.5vw] text-[30px] font-bold font-poppinsSemibold msm:text-left">
          {"(" + courseDetailStore.courseMeta[0]?.courseCode + ")"}
        </p> */}

      </div>

      <div className="bg-white px-[8%] pt-[7%] m-auto msm:h-[30vh] h-[195px]  flex-col justify-center items-center text-center msm:items-start msm:text-left">
        <p className="fs-30w font-poppinsMedium text-[#0E78BE] pb-2">
          This{" "}
          {courseDetailStore.courseMeta[0]?.universityCode === "GLOBAL"
            ? "Topic"
            : "Course"}{" "}
          Includes
        </p>
        <div className={` ${subject < 1 ? "hidden" : ""} flex items-center mb-1 font-poppinsRegular  m-auto text-center justify-center msm:justify-start`}>
          <img
            src={Files}
            alt="Your Icon"
            className=" text-center msm:text-left w-4 h-4 mr-4"
          />
          <span className="text-center msm:text-left fs-20w">
            Includes {subject} unit(s)
          </span>
        </div>
        <div className={`${chapters < 1 ? "hidden" : ""} flex items-center mb-1 font-poppinsRegular justify-center msm:justify-start`}>
          <img
            src={RxVideo}
            alt="Your Icon"
            className=" text-center msm:text-left w-4 h-4 mr-4"
          />
          <span className="text-center msm:text-left fs-20w">
            Covers {chapters} Chapters
          </span>
        </div>
        <div className={`${problems < 1 ? "hidden" : ""} flex items-center mb-1 font-poppinsRegular justify-center msm:justify-start`}>
          <img
            src={NoteRemover}
            alt="note remover"
            className=" text-center msm:text-left w-4 h-4 mr-4"
            style={{ fill: "#4299E1" }}
          />
          <span className="text-center msm:text-left fs-20w">
            Over {problems} Problems solved
          </span>
        </div>
        { courseDetailStore.courseMeta[0]?.subjectCode ==="MA3251"?<div className={`${problems < 1 ? "hidden" : ""} flex md:items-center mb-1 font-poppinsRegular justify-center msm:justify-start`}>
          <img
            src={solvedQuestionIcon}
            alt="solvedQuestionIcon"
            className="w-6 h-6 md:ml-[-1.5%] md:mr-2"
            style={{ fill: "#4299E1" }}
          />
          <span className="text-center msm:text-left fs-20w md:w-full w-[80%]">
          Solved University Question Papers
          </span>
        </div>:""}
      </div>

      <div className="flex flex-col  mt-auto msm:mt-[-20px]">

        <div onClick={navigateToPlayStore} className="font-poppinsSemibold p-3 bg-[#F6AE35] smlg:text-[1vw] text-[1vw] w-[90%] msm:flex justify-between m-auto mt-[1vw] dlg:mt-[20px] lxl:mt-[2vw] 3xl:mt-[1.5vw] 3xxl:mt-[1.4vw]  text-color font-bold rounded-[10px]  hidden">
          <div><p className="w-[70%]" >DOWNLOAD THE APP </p>
            <p>AND START LEARNING FOR FREE</p></div>
          <img src={ply_str_logo} className="h-[29px] w-[26px] my-auto" />
        </div>

        <div className="flex justify-center  md:justify-start px-[8%] mt-0 hsm:mt-auto  items-center gap-2 smlg:gap-3  p-4">
          {/* <p className="flex fs-20w items-center font-poppinsMedium my-auto">
            Share :
          </p> */}
          <a
            href={FACEBOOK_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="flex rounded-full p-2 border-2 border-black"
          >
            <FaFacebookF className="text-black w-3 h-3 3xxl:w-5 3xxl:h-5 " />
          </a>
          <a
            href={TWITTER_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center rounded-full p-2 border-2 border-black"
          >
            <BsTwitterX className="text-black w-3 h-3 3xxl:w-5 3xxl:h-5" />
          </a>
          {/* <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer" className="flex items-center rounded-full p-2 border-2 border-black">
      <FaLinkedinIn className="text-black w-3 h-3 3xxl:w-5 3xxl:h-5" />
    </a> */}
          <a
            href={YOUTUBE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center rounded-full p-2 border-2 border-black"
          >
            <FaYoutube className="text-black w-3 h-3 3xxl:w-5 3xxl:h-5" />
          </a>
          <a
            href={INSTAGRAM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center rounded-full p-2 border-2 border-black"
          >
            <GrInstagram className="text-black w-3 h-3 3xxl:w-5 3xxl:h-5" />
          </a>
        </div>

      </div>
    </div>

  );
};

export default observer(CourseDetailsCard);
