import React from 'react'

const ContactDetails = () => {
  return (
    <div className='md:block hidden py-6'>
        {/* <p className='text-[#111F58] font-poppinsBold text-[40px] pb-2'>Sales & Support</p> */}
        <div>
            <p className='font-poppinsRegula'>Email :  <a href="mailto:siva@maths.engineering" className='font-poppinsMedium text-[#115DE2] '>siva@maths.engineering</a></p>
            <p className='font-poppinsRegular py-1'>Mobile no : <a href={`tel:+91 9962001111}`}  className='font-poppinsMedium text-[#115DE2] '> +91 9962001111</a></p>
        </div>
    </div>
  )
}

export default ContactDetails