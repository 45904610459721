import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { StoreContext } from "../../../storeContext";
import icon1 from "../../../assets/Group 558.svg";
import icon2 from "../../../assets/Group 559.svg";
import icon3 from "../../../assets/Group 560.svg";
import icon4 from "../../../assets/Group 635.svg";
import MainCard from "./MainCard";
import ImageCollage from "./ImageCollage";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Slider from "react-slick";
import tick from "../../../assets/HomePage/Tick.png"

function Main() {
  const [isSlider, setIsSlider] = useState(window.innerWidth >= 1024);
  const { courseStore } = useContext(StoreContext);
  const navigate = useNavigate();

  const onLocationChange = (e: any) => {
    courseStore.selectedLocation.value = e.value;
    courseStore.selectedLocation.label = e.label;
    courseStore.universityFilterCode = "";
  };
  const navigateToCourse = () => {
    navigate("/course");
    courseStore.universityFilterCode = "";
    window.scrollTo(0, 0);
  };
  const states = courseStore.locations.map((proxyObj: any) => {
    return {
      value: proxyObj.code,
      label: proxyObj.name,
    };
  });
  const data = [
    {
      number: "50K",
      text: "Students trained",
      icon: icon2,
      textColor: "#EE3E09",
    },
    { number: "14+", text: "Universities", icon: icon1, textColor: "#F6AE35" },
    {
      number: "1000+",
      text: "Problems solved",
      icon: icon3,
      textColor: "#22AAE1",
    },
    { number: "100+", text: "Courses", icon: icon4, textColor: "#88C743" },
  ];
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSlider(window.innerWidth >= 1024);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="w-[100%] bg-[#F0F3FB] pt-[1%] pb-[3%] md:mt-[2%] xs:mt-[15%] flex flex-col lg:flex-row">
      <div className="lg:w-[50%] w-[100%]">
        <div
          className="xl:text-[100px] lxl:text-[95px] xlg:text-[88px] smlg:text-[80px] msm:text-[70px] md:text-[60px] xs:text-[40px] pl-[4%] pr-[18%] md:pr-[4%] lg:pl-[16%] md:mt-[10%] xs:mt-[4%] text-[#111F58] lg:w-[109%] font-bebasNeueRegular overflow-visible"
          style={{ lineHeight: ".85" }}
        >
          <span className="m-0">Crack Your College Maths Exams</span>
          {/* <br /> */}
          {/* <span className="m-0">BE YOUR BEST.</span> */}
        </div>
        <div className="lg:h-[50%] lg:mt-auto md:mt-[50px] xs:mt-[20px] block lg:hidden">
          {" "}
          <ImageCollage initialOpacity={1} />
        </div>
        <div className="xl:text-[30px] lxl:text-[28px] xlg:text-[26px] smlg:text-[24px] msm:text-[22px] md:text-[22px] xs:text-[20px] font-poppinsSemibold md:pt-16 pt-6 pl-[4%] lg:pl-[16%]">
          What we Offer?
        </div>

        <div className="xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px] rounded-lg w-[100%] md:w-[87%] my-4 px-[4%] lg:pl-[16%] font-poppinsRegular">
          <div className="flex items-center text-black bg-[#E1E7F7] p-2 mb-2">
            {/* <input type="checkbox" className="form-checkbox cursor-pointer h-5 w-5 text-gray-600"/> */}
            <img src={tick}  className="md:w-3 xs:w-2 text-gray-600"/>
            <span className="ml-2">Short, Step-by-Step Solved Problem Videos</span>
          </div>

          <div className="flex items-center text-black bg-[#E1E7F7] p-2 mb-2">
          <img src={tick}  className="md:w-3 xs:w-2 text-gray-600"/>
            <span className="ml-2">Courses as per your University Syllabus</span>
          </div>

          <div className="flex items-center text-black bg-[#E1E7F7] p-2 mb-2">
          <img src={tick}  className="md:w-3 xs:w-2 text-gray-600"/>
            <span className="ml-2">Solved Previous Year’s Question Papers</span>
          </div>

          {/* <div className="flex items-center text-black bg-[#E1E7F7] p-2 mb-2">
            <input type="checkbox" className="form-checkbox cursor-pointer h-5 w-5 text-gray-600"/>
            <span className="ml-2">To get the last-minute exam preparation</span>
          </div> */}
        </div>
        {/* <div className="w-[90%] pl-[8%] lg:pl-[16%] font-poppinsRegular hover:cursor-pointer">
          <Select
            placeholder="Select your state"
            options={states}
            onChange={onLocationChange}
            className="w-full rounded-md hidden md:block xs:block hover:cursor-pointer"
            styles={{
              control: (provided: any) => ({
                ...provided,
                backgroundColor: "#87ADF0",
                borderColor: "transparent",
                color: "black",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "5px",
                cursor: "pointer",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "white",
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: "white",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "white",
              }),
            }}
          />
        </div> */}
        <div className="w-full  lg:pl-[16%] flex lg:mx-0 lg:justify-start justify-center ">
          <div
            onClick={navigateToCourse}
            className="xl:text-[20px] lxl:text-[18px] xlg:text-[17px] smlg:text-[15px] msm:text-[13px] md:text-[11px] xs:text-[10px] p-3 bg-[#88C743] hover:cursor-pointer mt-4 msm:w-[160px] smlg:w-[175px] lxl:w-[190px] flex lg:mx-0 lg:justify-start justify-center text-center font-poppinsSemibold rounded-lg md:block xs:hidden"
          >
            Explore Courses
          </div>
        </div>
      </div>
      <div className="lg:w-[52%] smlg:w-[50%] lg:w-[100%] lg:pl-[5%] xs:pl-[4%] xs:pr-[4%] mt-[20px] flex flex-col">
        <div className="h-[50%] hidden lg:block">
          {" "}
          <ImageCollage initialOpacity={0.3} />
        </div>
        <div className=""></div>
        <div className="grid grid-cols-2 lg:gap-5 xs:gap-4 mt-[-20px] md:mt-8 md:ml-[-50px] md:pr-[8%]  xl:mt-[12%]  lxl:mt-[12%] xlg:mt-[10%] smlg:mt-[9%] slg:mt-[3%] lg:mt-[1%]">
            {data.map((item, index) => (
              <MainCard key={index} {...item} />
            ))}
          </div>
        {/* {isSlider ? (
          <div className="grid grid-cols-2 gap-4 mt-10 ml-[-50px] md:pr-[17.2%]  xl:mt-[12%]  lxl:mt-[12%] xlg:mt-[10%] smlg:mt-[9%] slg:mt-[3%] lg:mt-[1%]">
            {data.map((item, index) => (
              <MainCard key={index} {...item} />
            ))}
          </div>
        ) : (
          <Slider {...sliderSettings} className="w-[95%] mt-3 overflow-hidden">
            {data.map((item, index) => (
              <div  key={index} className="p-[15px] ml-[20px] ">
                <MainCard {...item} />
              </div>
            ))}
          </Slider>
        )} */}
      </div>
    </div>
  );
}

export default observer(Main);
