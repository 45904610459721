import React, { useContext, useEffect, useRef, useState } from 'react'
import { Menu } from '../components/Header';
import { Router } from "../components/Router";
import { Footer } from '../components/Footer';
import WhatsAppRedirect from '../components/StaticComponent/WhatsAppRedirect';
import { StoreContext } from '../storeContext';
import { observer } from 'mobx-react';

const Layout = () => {
  const { courseDetailStore } = useContext(StoreContext);
  const [isKickerVisible, setIsKickerVisible] = useState(true);
  const handleScroll = () => {
   

    if (courseDetailStore.customScroll.stickyCardRef.current && courseDetailStore.customScroll.footerRef.current) {
      const footerTop = courseDetailStore.customScroll.footerRef.current.getBoundingClientRect().top;
      const cardHeight = courseDetailStore.customScroll.stickyCardRef.current.offsetHeight;

      if (footerTop - cardHeight <= 100) {
        courseDetailStore.customScroll.isSticky = false
      } else if (!courseDetailStore.customScroll.isSticky) {
        courseDetailStore.customScroll.isSticky = true
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [courseDetailStore.customScroll.isSticky]);


  return (
    <div className='w-[100%] '>
      <Menu />
      <Router/>
      <Footer/>
      <WhatsAppRedirect />
    </div>
  )
}

export default observer(Layout);