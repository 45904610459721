import React, { useContext, useEffect, useRef, useState } from "react";
import main_logo from "../../assets/Logo/Maths.Engineering Logo (1).png";
import call_logo from "../../assets/call.svg";
import search_icon from "../../assets/search-normal.png";
import forwordIcon from "../../assets/forward_arrow.svg"
import path_down from "../../assets/Path_down.svg";
import hamberger_icon from "../../assets/hamburger.svg";
import close_menu from "../../assets/close-menu.svg";
import cart_icon from "../../assets/cart.svg";

import MegaMenu from "./MegaMenu";
import { Link, useNavigate } from "react-router-dom";
import { StoreContext } from "../../storeContext";
import BlurOverlay from './BlurOverlay';
import { observer } from "mobx-react";
import Kicker from "../Home/Kicker/Kicker";
import { Course } from "../../services/Utils";

// interface Course {
//     code: String,
//     name: String,
//     regulations: String,
//     semester: String,
//     aboutSubjects: String,
//     aboutChapters:String
// }

interface SearchCourses {
    course:Course,
    suggestion:String
}

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isKickerVisible, setIsKickerVisible] = useState(true);
  const [suggestionsData, setSuggestionsData] = useState<SearchCourses[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu bar on clicking on humberger icon
  const [isVisible, setIsVisible] = useState(false); // For mega Menu open close
  const [showSuggestions, setShowSuggestions] = useState(false); // for showing search suggestions
  const [showSearchPanel, setShowSearchPanel] = useState(false) // for showing search panel in mobile view
  const toggleButtonRef = useRef(null);
  const suggestionsRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
 

  const { searchStore } = useContext(StoreContext);

  //Function to api call for showing suggestions data 
  const searchCourses = async (query:String) => {
    await searchStore.searchCourses(query);
    let data = searchStore.searchedCoursesData;
    let uniqData = removeDuplicates(data);
    setSuggestionsData(uniqData);
  }

  // Function to remove duplicate item from suggestion data
  const removeDuplicates = (array:any) => {
    const uniqueKeys = new Set();
    return array.filter((obj:any) => {
      const keyValue = obj.course.code;
      if (!uniqueKeys.has(keyValue)) {
        uniqueKeys.add(keyValue);
        return true;
      }
      return false;
    });
  };

  //Function to call for bulding courses and it will be called before suggestions data api call happen
  const buildSearchIndex = async () => {
    await searchStore.buildSearchIndex();
  }

  //For hamburger--
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setShowSearchPanel(false);
  };

  //For megamenu;
  const handleButtonClick = () => {
    setIsVisible(!isVisible);
    setShowSearchPanel(false);
    setIsMenuOpen(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleNavigate = () =>{
    navigate("/")
    setIsVisible(false);
    setShowSearchPanel(false);
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  }

  // Function to show search panel on mobile view
  const toggleSearchPanel = () => {
    setShowSearchPanel(!showSearchPanel);
    setIsMenuOpen(false)
    setSuggestionsData([]);
  }

  // function to show suggestions when query enter in search bar
  const handleSearch = (e:any) => {
    setShowSuggestions(true)
    let query = e.target.value;
    searchStore.searchedQuery = query;
    setSearchQuery(e.target.value);
    if (query.length > 0 && query.length % 3 === 0) {
        searchCourses(query);
    }
    if(query.length === 0) {
        setSuggestionsData([]);
    }
  }

  // function to handle when Enter key pressed in search bar
  const handleKeyOnSearch = (e:any) => {
    if(e.keyCode === 13){
      navigate("/search-result");
      setShowSuggestions(false);
      setShowSearchPanel(false);
      setSearchQuery("")
    }
  }

  // Function to navigate to courseDetials when any course clicked in suggestions box
  const navigateFromSuggestions = (code:String,name:string) => {
    navigate(`/course/${code}/${name.split(" ").join("")}`);
    setShowSuggestions(false);
    setShowSearchPanel(false);
    setSearchQuery("");
    setSuggestionsData([]);
  }

  //Function to handle when { about-us } menu clicked on mobile view
  const handleAboutus = (value:any) => {
    window.open(`/${value}`, '_blank');
    window.scrollTo(0, 0);
    // toggleMenu()
  }

  //Function to handle when { courses and For Collages and Universities }  menu clicked on mobile view
  const handleCourses = () => {
    navigate("/course");
    toggleMenu();
  }
  const handleNavigateTOPages = (value: any) => {
    window.open(`/${value}`, '_blank');
    window.scrollTo(0, 0);
    toggleMenu();
  };

  // Event listener to close suggestions when clicking outside of open menubar
  const handleClickOutside = (event:any) => {
    if (suggestionsRef.current && !(suggestionsRef.current as HTMLElement).contains(event.target as Node) && inputRef.current !== event.target) {
      setShowSuggestions(false);
    }
  };



  useEffect(() => {

    // to build the courses for showing course suggestions on search happen
    buildSearchIndex();
 
    // Mega Menu Functionality
    const handleOutsideClick = (event: MouseEvent) => {
      const megaMenuNode = document.querySelector('.mega-menu'); 
      const megaMenuMobileNode = document.querySelector('.mobile-mega-menu-1')
      if (
        isVisible &&
        toggleButtonRef.current &&
        megaMenuNode &&
        !(toggleButtonRef.current as HTMLElement).contains(event.target as Node) &&
        !megaMenuNode.contains(event.target as Node) && !megaMenuMobileNode?.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    //Event listner to close the course suggestions box when outside of the box is clicked
    document.addEventListener('click', handleClickOutside);

    return () => {
      // For Mega Menu
      document.removeEventListener('mousedown', handleOutsideClick);

      //for course suggestions box 
      document.removeEventListener('click', handleClickOutside);
    };

  }, [isVisible]);

  return (
    <>
      <nav className="w-[100%] md:px-[8%] xs:px-[4%] fixed z-[1000] flex item-center top-0 left-0 right-0  bg-white h-[60px] shadow-md md:py-8">

        {/* blur effect for mobile view screen */}

        <div className="flex w-full items-center justify-between">
          {isMenuOpen && (
            <div
              className={`fixed inset-0 z-40 ${isMenuOpen
                  ? "opacity-100 top-[220px] backdrop-filter backdrop-blur-lg pointer-events-auto"
                  : "opacity-0 pointer-events-none"
                }`}
              onClick={toggleMenu}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} // Adjust opacity here
            ></div>
          )}

          {/* <!-- Logo --> */}

          <div className="  flex items-center xs:w-[25%] md:w-[15%]">
            <img src={main_logo} onClick={() => {
              handleNavigate();
            }} alt="Logo" className="md:h-[27px] xs:h-[27px]" />
          </div>

          {/* course button */}

          <div className="  relative xs:w-[27%] md:w-[15%] flex md:justify-end">
            <button
              ref={toggleButtonRef}
              onClick={handleButtonClick}
              className="md:w-[110px] xs:w-[100%] xs:h-[27px] md:h-[40px] text-white focus:outline-none bg-[#115DE2] px-[10px] rounded-[4px] md:mr-[5px] focus:outline-none flex items-center justify-between"
            >
              <span className="font-poppinsSemibold md:text-[15px] xs:text-[10px]">COURSE</span>
              <span><img src={path_down} alt="down" className="w-2.5 h-2.5" /></span>
            </button>
          </div>

          {/* mobile screen icons */}
          <div className="lg:hidden flex justify-between gap-3">
            <button className="flex items-center">
              <a href="tel:+919360922729"><img src={call_logo} alt="Phone" className="h-5" /></a>
            </button>
            <button className="flex items-center" onClick={toggleSearchPanel}>
              <img src={search_icon} alt="Phone" className="h-5" />
            </button>
            {/* <button className="flex items-center">
              <img src={cart_icon} alt="cart" className="h-5" />
            </button> */}
          </div>

          {/* Mobile screen search panel */}
          {showSearchPanel && (
            <div className="lg:hidden absolute right-0 bg-grey">
              <div className="fixed w-full right-0 top-[3.6rem] pl-6 pt-2 pb-2 z-[-1] bg-[#F4F4F7] pl-[10px] pr-[10px] h-[9vh] ">
                <div className="p-[0.5rem]">
                  <input
                    type="text"
                    ref={inputRef}
                    onChange={handleSearch}
                    onKeyDown={handleKeyOnSearch}
                    value={searchQuery}
                    onFocus={() => setShowSuggestions(true)}
                    className="w-[100%] h-[40px] p-[0.5rem] rounded-md font-poppinsRegular border border-gray-300 focus:outline-[#F6AE35]"
                    placeholder="Search Courses & Topics"
                  />
                </div>

                <div className="h-[42vh]">
                  {showSuggestions && suggestionsData.length > 0 && (
                    <div ref={suggestionsRef} className={`top-3 z-[1] rounded-md bg-white border border-gray-300 w-[100%] ${suggestionsData.length > 10 ? 'h-[100%]' : 'h-auto'} overflow-auto mt-2 rounded-b-lg shadow-lg`}>
                      <ul>
                        {suggestionsData.map((item: any, index: number) => (
                          <li key={index} className="py-2 px-4 hover:bg-gray-100 font-poppinsRegular cursor-pointer text-[0.9rem]" onClick={() => navigateFromSuggestions(item.course.code,item.course.name)}>
                            {item.suggestion}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {showSearchPanel && <div
                  className={`fixed inset-0 z-40 ${showSearchPanel
                      ? "opacity-100  top-[100px] z-[-1] backdrop-filter backdrop-blur-lg pointer-events-auto"
                      : "opacity-0 pointer-events-none"
                    }`}
                  onClick={() => setShowSuggestions(false)}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }} // Adjust opacity here
                ></div>}
              </div>
            </div>
          )}

          {/* <!-- Hamburger Icon for mobile --> */}

          <div className="block lg:hidden flex item-center">
            <button
              onClick={toggleMenu}
              id="menu-toggle"
              className="text-white focus:outline-none"
            >
              {isMenuOpen ? (
                <img
                  className="w-6 h-6 fill-current text-orange-500"
                  src={hamberger_icon}
                  alt="Opened Menu"
                />
              ) : (
                <img
                  className="w-6 h-6 fill-current text-orange-500"
                  src={hamberger_icon}
                  alt="Closed Menu"
                />
              )}
            </button>

            {/* Mobile Screen Menu bar when humberger button will be clicked */}

            {isMenuOpen && (
              <div className="absolute right-0 bg-grey h-[30vh] z-50 ">
                <div className="fixed w-full right-0 top-[3.6rem] pl-6 pt-2 pb-2 z-[-1] bg-[#F4F4F7] pl-[10px] pr-[10px]">
                  {/* <div className="flex items-center gap-4 p-[1rem]">
                    <button className="border border-[#1085C5] text-[#1085C5] px-3 py-1 rounded-md mr-2 focus:outline-none">
                      SIGNUP
                    </button>
                    <button className="border border-[#EC5C2B] text-[#EC5C2B] px-3 py-1 rounded-md focus:outline-none">
                      LOGIN
                    </button>
                  </div> */}
                  <div className="w-[100%] rounded-[4px] pt-[2%] bg-grey lg:hidden xs:block overflow-y-auto text-[15px]">
                    <div onClick={() => {handleAboutus("about-us"); }} className="w-[100%] h-[35px] border-b border-black-300 flex items-center justify-between mx-auto px-[8%] py-[1%] border-b-2 font-poppinsMedium cursor-pointer">
                      <div ><p className="text-[#000000]" >About us</p></div>
                      <img src={forwordIcon} alt="forword icon" />
                    </div>
                    <div onClick={handleCourses} className="w-[100%] h-[35px] border-b border-black-300 flex items-center justify-between  mx-auto px-[8%] py-[1%] border-b-2 font-poppinsMedium cursor-pointer">
                      <div ><p className="text-[#000000]">Courses</p></div>
                      <img src={forwordIcon} alt="forword icon" />
                    </div>
                    <div onClick={handleCourses} className="w-[100%] h-[35px] border-b border-black-300 flex items-center justify-between mx-auto px-[8%] py-[1%] border-b-2 font-poppinsMedium cursor-pointer">
                      <div ><p className="text-[#000000]">For Colleges and Universities</p></div>
                      <img src={forwordIcon} alt="forword icon" />
                    </div>
                    <Link to="/contact-us" target="_blank" onClick={() => handleNavigateTOPages("contact-us")}>
                      <div className="w-[100%] h-[35px]  flex items-center justify-between  mx-auto px-[8%] py-[1%]  font-poppinsMedium cursor-pointer">
                        <div>
                          <p className="text-[#000000]">Contact us</p></div>
                        <img src={forwordIcon} alt="forword icon" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* <!-- Navbar Items for large screen --> */}
          <div className="hidden flex justify-between gap-[1.5%] lg:flex lg:items-center md:w-[67%]" id="menu">
            {/* search input */}

            <div className="flex items-center relative w-[90%] xlg:w-[70%]">
              <input type="text" ref={inputRef} placeholder="Search Courses & Topics" className="w-[100%] h-[40px] bg-[#BACBE91F] text-black border border-[#D5DBE5] px-12 py-[2px] rounded-[4px] focus:outline-none placeholder:text-[0.75rem] placeholder:text-[#000000] placeholder:font-poppinsRegular  placeholder:text-[15px]" onChange={handleSearch} onKeyDown={handleKeyOnSearch} value={searchQuery} onFocus={() => setShowSuggestions(true)} />
              <img className="absolute left-3 top-3 h-4 w-4 pointer-events-none" src={search_icon} alt="Search" />
            </div>

            {/* cart icon */}
            {/* <div className="  w-[10%] flex justify-center">
              <div className="border-l-2 border-r-2 border-[#D5DBE5] px-[20%]">
                <img className="h-5 w-5" src={cart_icon} alt="Cart" />
              </div>
            </div> */}


            {/* Course suggestion when user search happen */}

            {showSuggestions && suggestionsData.length > 0 && (
              <div ref={suggestionsRef} className={`absolute top-full bg-white border border-gray-300 w-[30%] lxl:w-[30.5%]  ${suggestionsData.length > 10 ? 'h-[40vh]' : 'h-auto'} mt-0 overflow-auto  rounded-b-lg shadow-lg`}>
                <ul>
                  {suggestionsData.map((item: any, index: number) => (
                    <li key={index} className="py-2 px-4 hover:bg-gray-100 font-poppinsRegular cursor-pointer text-[0.9rem]" onClick={() => navigateFromSuggestions(item.course.code,item.course.name)}>
                      {item.suggestion}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* call to expert */}

            <div className="  flex items-center gap-[5%] text-black md:w-[45%] xlg:w-[40%] smlg:w-[50%]  justify-end">
              <img src={call_logo} alt="Phone" className="h-6" />
              <div className="flex flex-col">
                <p className="text-[15px] font-poppinsRegular mb-[-2px]">Talk to our experts</p>
                <p className="font-poppinsSemibold text-[#1085C5] mt-[-2px] text-[18px]">+91 9360922729</p>
              </div>
            </div>

            {/* Login and Signup Button */}

            <div className="  flex items-center justify-end w-[21%] xlg:w-[18%] xl:w-[15%]">
              {/* <button className="md:w-[80px] border font-poppinsSemibold text-[.7rem] border-[#1067E4] text-[#115DE2]  py-1 rounded-[4px] mr-2 focus:outline-none">LOGIN</button> */}
              <button onClick={() => {handleAboutus("about-us"); }} className=" border  border-[1px] border-[#0F5DE2] md:w-[116px] md:h-[40px] font-poppinsSemibold text-[15px] text-[#0F5DE2]  py-1 rounded-[5px] focus:outline-none">ABOUT US</button>
            </div>
          </div>
        </div>
      </nav>
      {isVisible && (
        <BlurOverlay isVisible={isVisible}>
          <div className="fixed md:top-[59px] xs:top-[3.8rem] left-0 w-full   flex items-center justify-center z-[900] mega-menu-desktopmain">
            <MegaMenu setIsVisible={setIsVisible} />
          </div>
        </BlurOverlay>
      )}
    </>
  );
};

export const Menu = observer(Navbar);