import styles from "./Collaboration.module.css";
import amity_logo from "../../../assets/HomePage/AMITY-Logo-640x210.png";
import madras_logo from "../../../assets/HomePage/madras.png";
import ashoka_logo from "../../../assets/HomePage/Ashoka.png";
import christ_logo from "../../../assets/HomePage/christ.png";
import { useNavigate } from "react-router-dom";
const Collaboration = () => {

  const navigate= useNavigate();
  const handlNavigate =(value:any)=>{
    navigate(`/${value}`)
    window.scrollTo(0, 0);
  }
 
  return (
    <div className="bg-[#EAEEF9] md:px-[8%] pt-[250px] md:pt-[250px] lg:pt-[17rem] xlg:pt-[23rem] lxl:pt-[25rem] z-0">
      <div className="flex xs:flexwrap flex-col md:w-[100%] xs:w-[90%] md:py-6 mx-auto border-2 md:mt-[6%] xs:pl-[4.5%] xs:mt-[25%] xl:mt-[20%] xxl:mt-[20%] md:pl-[9%] md:pb-[0rem] xs:pb-[0rem] md:rounded-[25px] xs:rounded-[10px] bg-white relative overflow-hidden">
        <div
          className={`${styles.bghalf} md:block xs:hidden absolute top-0 right-0 w-[55%] h-[100%] bg-cover bg-center  z-0`}
        ></div>

        <div className="flex  md:item-start xs:text-center md:text-start tracking-tight  md:w-[50%] xs:w-[90%] xs:m-auto md:m-0 font-poppinsBold text-[#111F58] xl:text-[60px] lxl:text-[55px] xlg:text-[50px] smlg:text-[45px] msm:text-[40px] md:text-[40px] xs:text-[35px] xs:mt-[10%] md:mt-[2%] z-0">
          <p className="lxl:leading-[4.5rem] xlg:leading-[4rem] dlg:leading-[3.5rem] slg:leading-[3.3rem] msm:leading-[3rem] md:leading-[2.8rem]">For Colleges and Universities</p>
        </div>
        <div className="tracking-tight leading-tight xl:text-[30px] lxl:text-[28px] xlg:text-[26px] smlg:text-[24px] msm:text-[22px] md:text-[22px] xs:text-[20px] text-[black] font-poppinsMedium md:w-[35%] xs:w-[85%] xs:m-auto md:m-0 md:pt-[3%] md:pb-[6%] mt-[3%] mb-[7%] md:item-start xs:text-center md:text-start">
          <p className="py-4">
            We collaborate and devise customized courses based on your syllabus
            which enable your University to be at the forefront of educational
            excellence.
          </p>
        </div>
        <div className="md:flex md:justify-between md:flex-nowrap xs:flex-wrap  items-center  mr-[2.5%] mb-[2%]  md:h-auto xs:h-[380px] lsm:h-[330px]">
          <div className="flex md:w-auto xs:w-[100%] xs:pt-[3%] md:pt-0">
            <button
              className="text-[#000000] xl:text-[20px] lxl:text-[19px] xlg:text-[18px] smlg:text-[17px] msm:text-[17px] md:text-[16px] xs:text-[15px] font-poppinsSemibold bg-[#88C743] rounded-[6px]  md:px-[3vw] px-[6vw] md:py-[1vw] py-[2vw]  m-auto "
              onClick={() => {
                handlNavigate("knowmore");
              }}
            >
              KNOW MORE
            </button>
          </div>
          <div
            className={`${styles.bghalf} md:hidden xs:block w-[90%]  md:h-[40%] h-[35%] top-[60%] left-[5%] absolute bg-cover rounded-[10px]`}
          ></div>
          {/* <div className="flex bg-transparent md:w-[70%] w-[98%] md:h-[6vw] gap-[1%] md:mt-auto xs:mt-[65%]">
            <div className="w-[35%]   border-2 border-[#90C3CD] rounded-[8px] backdrop-blur-[2rem] bg-white/25 -webkit-backdrop-blur-[40px] md:px-[5%] md:py-[3%] xs:px-[3.5vw] xs:py-[1.5vw]">
              <img
                src={amity_logo}
                className="w-[100%] h-[100%]  m-auto"
                alt="logo"
              />
            </div>
            <div className="w-[35%]   border-2 border-[#90C3CD] rounded-[8px] backdrop-blur-[2rem] bg-white/25 -webkit-backdrop-blur-[40px] md:px-[5%] md:py-[3%] xs:px-[3.5vw] xs:py-[1.5vw]">
              <img
                src={madras_logo}
                className="w-[100%] h-[100%] m-auto"
                alt="logo"
              />
            </div>
            <div className="w-[35%] border-2 border-[#90C3CD] rounded-[8px] backdrop-blur-[2rem] bg-white/25 -webkit-backdrop-blur-[40px] md:px-[3%] md:py-[1%] xs:px-[2vw]">
              <img
                src={ashoka_logo}
                className="w-[100%] h-[100%] m-auto"
                alt="logo"
              />
            </div>
            <div className="w-[35%]  border-2 border-[#90C3CD] rounded-[8px] backdrop-blur-[2rem] bg-white/25 -webkit-backdrop-blur-[40px] md:px-[5%] md:py-[3%] xs:px-[3.5vw] xs:py-[1.5vw]">
              <img
                src={christ_logo}
                className="w-[100%] h-[100%] m-auto"
                alt="logo"
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Collaboration;
