import React from 'react';
import whatsapp_logo from "../../assets/whatsapp_logo.svg";

import {WHATSAPP_URL} from "../../constants"; 
import { useLocation } from 'react-router-dom';

const WhatsAppRedirect = () => {
  const location = useLocation();
  
  return (
    <a
      href={WHATSAPP_URL}
      target="_blank"
      rel="noopener noreferrer"
      className={`z-[2000] fixed md:bottom-3 ${location.pathname !="/course" && location.pathname !="/topic" ? "xs:bottom-[4.5rem]" : "xs:bottom-2" }  right-4 bg-[#88C743] text-white rounded-full p-1 shadow-lg  hover:shadow-xl`}
    >
      <div className='md:w-[100px] xs:w-[35px] h-[35px] flex items-center gap-[10px] justify-center'>
        <div className='w-[60%] pl-[6px] xs:hidden md:block'>
          <p className='text-[0.7rem] m-0 font-poppinsMedium leading-[12px]'>CHAT WITH US</p>
        </div>
        <div className='md:w-[40%] xs:w-[60%] flex items-center justify-center'>
          <img className='w-[30px] h-[30px]' src={whatsapp_logo} alt="WhatsApp Logo" />
        </div>
      </div>
    </a>
  );
};

export default WhatsAppRedirect;