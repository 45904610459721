export const setTitle =(title:string,metaTag:string)=>{
    document.title= title;
    document.querySelector('meta[name="description"]')?.setAttribute('content', metaTag);

}

export const removeSubstring = (string: string) => {
    let subjectsArray = string.split(" "); 
    if (subjectsArray.length > 0) {
        subjectsArray.pop(); 
        subjectsArray.push("unit(s)")
    } else {
        subjectsArray = [string]
    }
    return subjectsArray.join(" "); // Join the array back into a string with spaces between words
}

export interface Course {
    code: string;
    name: string;
    regulations?: string; 
    semester?: string;    
    university?: string;
    aboutSubjects: string; 
    aboutChapters: string | null; 
    subjectCode?: string;
    urls?:string;
    autonomousCollegeName?:string|null;
  }