import { makeObservable, observable, action, computed } from "mobx";
import { apiHost } from "../../services/APIEndpoints";
import { getChapters, getReviewQuery, getCourseMeta, insertEnquiry } from "./Query";
import { RefObject, createRef } from "react";
import { setTitle } from "../../services/Utils";

const INIT: string = "init";
const PENDING: string = "pending";
const DONE: string = "done";
const ERROR: string = "error";

interface Review {
    id: Number,
    courseCode: String,
    description: String,
    name: String,
    role: String,
    org: String
    category: String
}

interface Chapter{
    code: string,
    name: string,
    meta: string,
    partCode: string,
    priority: number
}
interface Part{
    code: string,
    name: string,
    meta: string,
    courseCode: string,
    priority: number
}

interface Parts{
    part: Part;
    chapters: Chapter[]
}

interface SelectedCourse{
    code: String,
    name: String,
    universityName: String,
}

interface CourseMeta{
    id: number,
    courseCode: String,
    courseName: String,
    universityCode: String,
    universityName: String
    semester: String,
    regulations: String,
    price: number,
    duration: number,
    keywords: String,
    description: String
    faqs: string,
    advantages: string,
    urls:string,
    subjectCode:string,
    videoImageUrl:string
}

interface Enquiry {
    fullname: String
    email: String
    phoneNumber: String
    college_university:String
    city: String
    query: String
}

interface CourseDetailsCardScroll {
  stickyCardRef: RefObject<HTMLDivElement>;
  footerRef: RefObject<HTMLDivElement>;
  isSticky: boolean;
}


class CourseDetailStore {
    
    apiProxy: any;
    state: String = INIT;
    reviewsData: Review[] = [];
    partChapters: Parts[] = [];
    selectedCourse: SelectedCourse = {
        code: "",
        name: "",
        universityName: ""
    };
    courseMeta: CourseMeta[] = [];
    insertEnquiryStatus: String =  "";
    customScroll: CourseDetailsCardScroll = {
        stickyCardRef: createRef<HTMLDivElement>(),
        footerRef: createRef<HTMLDivElement>(),
        isSticky: true,
      };

    constructor(apiProxy: any) {
        this.apiProxy = apiProxy;

        makeObservable(this, {
            state: observable,
            reviewsData: observable,
            partChapters: observable,
            selectedCourse: observable,
            courseMeta: observable,
            insertEnquiryStatus: observable,
            customScroll: observable,

            isLoading: computed,
            isError: computed,
            isDone: computed,

            fetchReviews: action,
            fetchChapters: action,
            fetchCourseMeta: action,
            insertEnquiry: action
        });

    }

    get isLoading() {
        return this.state === PENDING;
    }

    get isError() {
        return this.state === ERROR;
    }

    get isDone() {
        return this.state === DONE;
    }

    fetchReviews = async (courseCode: String) => {
        this.state = PENDING;

        let variable = {
            input: "GLOBAL"
        }

        try {
            let response = await this.apiProxy.query(apiHost, getReviewQuery, variable);
            let result = await response.json();
            this.reviewsData = result.data.getReviews.reviews;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error)
        }
    }

    fetchChapters = async (courseCode: String) => {
        this.state = PENDING;

        let variable = {
            input: courseCode
        }

        try {
            let response = await this.apiProxy.query(apiHost, getChapters, variable);
            let result = await response.json();
            this.partChapters = result.data.getChapters.parts;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error)
        }
    }

    fetchCourseMeta = async (courseCode: String) => {
        this.state = PENDING;

        let variable = {
            input: courseCode
        }

        try {
            const response = await this.apiProxy.query(apiHost, getCourseMeta, variable);
            const result = await response.json();
            const data = result.data.getCourseMeta.courseMeta;
           
            const courseName = data[0].courseName;
            setTitle(courseName,courseName);

            this.courseMeta = data;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error)
        }
    }

    insertEnquiry = async (newEnquiry: Enquiry) => {
        this.state = PENDING;

        let variable = {
            input: newEnquiry
        }

        try {
            let response = await this.apiProxy.mutate(apiHost, insertEnquiry, variable);
            let result = await response.json();
            this.insertEnquiryStatus = result.data.saveLead.status;
            this.state = DONE;
        } catch (error) {
            this.state = ERROR;
            console.log(error)
        }
    }

}

export default CourseDetailStore;