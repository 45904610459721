import React from 'react'

const Terms = () => {
  return (
    <div className='w-[80%] m-auto md:mt-[10%] mt-[24%] font-poppinsLight text-[14px] bg-white leading-[2rem]' >
        <div><p className='text-center font-poppinsSemibold text-[2.7rem] mb-[1.3rem]'>Terms of Use</p></div>
        <p className='mb-[1.3rem]'>These Terms of Use set out the terms and conditions for use of this MATHS.ENGINEERING <span className='font-poppinsSemibold' >( “Website”)</span> and any content, Public Forums, or services offered on or through the Website and/or through any mobile application(s) <span className='font-poppinsSemibold' >( “Application”)</span> (collectively referred to as the <span className='font-poppinsSemibold' >“Platform”</span>).</p>
        <p className='mb-[1.3rem]'>These Terms of Use apply to end users of the Website (referred to as <span className='font-poppinsSemibold' >“Learners”, “You”, “Your”</span>). These Terms of Use, including the Privacy Policy and any other terms and conditions published on the Platform or communicated to you from time to time (collectively referred to as the “Agreement”), define the relationship and responsibilities between You and Creator (as defined herein) in using the Platform. Your access to the Platform is subject to Your acceptance of this Agreement. Hence, please take Your time to read this Agreement.</p>
        <p className='mb-[1.3rem]'>When we speak of <span className='font-poppinsSemibold' >“Creator”, 'we', ‘us’,</span> and <span className='font-poppinsSemibold' >‘our’</span>, we collectively mean MATHS.ENGINEERING being the creator of this Platform and the content/materials/services contained therein.</p>
        <p className='mb-[1.3rem]'>By accessing this Platform, You are agreeing to be bound by the terms of this Agreement, all applicable laws and regulations. From time-to-time, versions of the above-mentioned policies and terms are made available on the Platform for Your reference and to understand how we handle Your personal information. By using or visiting the Platform and services provided to You on, from, or through the Platform, You are expressly agreeing to the terms of the Agreement and any other terms that are updated from time to time.</p>
        <p className='mb-[1rem]' >If You disagree with any part of this Agreement or do not wish to be bound by the same, then please do not use the Platform in any manner.</p>
        <p className='mb-[1.3rem]'>1. <span className='font-poppinsSemibold' >Access and Registration:</span> If You’re an individual You must be at least 18 (eighteen) years of age, or, if You are between the ages of 13 and 18, You must have Your parent or legal guardian's permission to use the Platform. By using the Platform, You are, through Your actions, representing and warranting to us that You have obtained the appropriate consents/permissions to use the Platform. If You are under the age of 13 years or 16 years (depending on your country of reisdence), You may neither use our Platform in any manner nor may You register for any content or services offered therein.</p>
        <p className='mb-[1.3rem]' >To access any Content (as defined below) offered on the Platform, we require You to register for the same by providing Your name and email address. Please read our Privacy Policy to understand how we handle Your information. Further, You may also be required to make payment of a fee to access the Content, if applicable. For more information, please read our ‘Payments & Refunds’ section below. You represent that the information indicated by You during Your enrolment or registration for any Content on the Platform, is true and complete, that You meet the eligibility requirements for use of the Platform and the Content offered therein, and You agree to update us upon any changes to the information by writing to us at the contact information provided in the ‘Contact Us’ section below.</p>
        <p className='mb-[1.3rem]' >For the purpose of this Agreement, <span className='font-poppinsSemibold' >"Content"</span> shall mean and include any course or session (whether pre-recorded or live) published by the Creator on the Platform, including, but not limited to any reference materials and text files (if any) offered to You as part of the Content.</p>
        <p className='mb-[1.3rem]'>For the purpose of this Agreement, “Content” shall mean and include any course or session (whether pre-recorded or live) published by the Creator on the Platform, including, but not limited to any reference materials and text files (if any) offered to You as part of the Content.</p>
        <p className='mb-[1.3rem]'>When You register or enrol for any Content on the Platform, You may also have access to discussion forums that enables You to exchange Your thoughts, knowledge in relation to the Content or its subject-matter, with us and other registrants to the Content <span className='font-poppinsSemibold'>(“Public Forum”)</span>. Participating in the Public Forum is completely Your choice and by registering or enrolling to a Content on the Platform, You are not obligated to participate in such Public Forum.</p>
        <p className='mb-[1.3rem]'>We maintain and reserve the right to refuse access to the Platform or remove content posted by You in the Public Forums, at any time without notice to You, if, in our opinion, You have violated any provision of this Agreement.</p>
        <p className='mb-[1.3rem]'>Further, to access the Platform and/or view the content on the Platform, You will need to use a <span className='font-poppinsSemibold'>“Supported/Compatible Device”</span> which means a personal computer, mobile phone, portable media player, or other electronic device that meets the system and compatibility requirements and on which You are authorized to operate the Platform. The Supported/Compatible Devices to access the Platform may change from time to time and, in some cases, whether a device is (or remains) a Supported/Compatible Device may depend on software or systems provided or maintained by the device manufacturer or other third parties. Accordingly, devices that are Supported/Compatible Devices at one time may cease to be Supported/Compatible Devices in the future. Thus, kindly make sure that the device that You use is compatible with any of the systems/software used on the Platform to access the content/material offered therein.</p>
        <p className='mb-[1.3rem]'>2. <span className='font-poppinsSemibold'> License to Use:</span> You are granted a limited, non-exclusive license to access and view the Content on the Platform for Your own personal, non-commercial use only. Further, if so allowed on the Platform, You may temporarily download one copy of any downloadable Content [including Creator Content (defined below)] on the Platform for personal and non-commercial transitory viewing only.</p>
        <p className='mb-[0rem] leading-8'>This license does not grant You the right to assign or sublicense the license granted under this Agreement to anyone else. Further, You may not-</p>
        <p className='mb-[1.3rem] ml-[1%]'>a. modify, edit or copy the Content, Creator Content or any material made available on the Platform;</p>
        <p className='mb-[1rem] ml-[1%]'>b. create derivative works or exploit any material made available on the Platform (including the Content and Creator Content) or any portion thereof (including, but not limited to any copyrighted material, trademarks, or other proprietary information contained therein) in manner that is not permitted under this license granted to You;</p>
        <p className='mb-[1rem] ml-[1%]'>c. publicly display (commercially or non-commercially) the Content, Creator Content or any material made available on the Platform or otherwise use the same for any commercial purpose;</p>
        <p className='mb-[1rem] ml-[1%]'>d. attempt to decompile or reverse engineer any software contained in the Platform;</p>
        <p className='mb-[1rem] ml-[1%]'>e. remove any copyright or other proprietary notations from the Content, Creator Content or any material made available on the Platform; or</p>
        <p className='mb-[1rem] ml-[1%]'>f. transfer the any material made available on the Platform to another person or 'mirror' the same on any other server.</p>
    </div>
  )
}

export default Terms