import Aboutus from "../components/StaticComponent/AboutUs"

const AboutUs = () => {
  return (
    <div className='w-[100%] bg-white about' >
        <Aboutus/>
    </div>
  )
}

export default AboutUs