import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player';
import PlayIcon from "../../../assets/Subtraction 1.svg";
import integral_calculas from "../../../assets/Experience_Our_magic/Thumbnails/Integral calculus.png"
import linear_algebra from "../../../assets/Experience_Our_magic/Thumbnails/Linear Algebra.png"
import vector_calculas from "../../../assets/Experience_Our_magic/Thumbnails/Vector_Calculus.png"

interface Video {
  id: string;
  thumbnail: string;
  url: string;
}

export const VCmain: React.FC = () => {
  const sliderRef = useRef<Slider | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    dots: true,
    dotsClass: 'slick-dots flex justify-center items-center mt-4',
    focusOnSelect: true,
    beforeChange: (_: number, next: number) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePlay = (url: string, index: number) => {
    setIsPlaying(true);
    setVideoUrl(url);
    setCurrentSlide(index);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const videos: Video[] = [
    { id: '9S6Bs77Sa-E', thumbnail: integral_calculas, url: 'https://youtu.be/9S6Bs77Sa-E' },
    { id: 'Ev2yNhTGUY4', thumbnail: linear_algebra, url: 'https://youtu.be/Ev2yNhTGUY4' },
    { id: 'bPpk0wAfj-E', thumbnail: vector_calculas, url: 'https://www.youtube.com/watch?v=bPpk0wAfj-E&t=6s' },
  ];

  return (
    <div className="absolute max-w-[1600px] left-1/2 transform -translate-x-1/2 xs:-translate-y-1/2 md:-translate-y-1/2 xl:-translate-y-1/3 mt-[-5rem] md:mt-[-4rem] xlg:mt-[-2rem] lxl:mt-[-1.5rem] xs:w-full md:w-[95%] xl:w-[92%] max-w-screen-xl mx-auto">
      <div className="absolute w-[60%] opacity-50 hover:opacity-[.5] hover:cursor-pointer mt-7 ml-20 z-0" onClick={handlePrev}>
        <img src={vector_calculas} alt="Image 1" className="w-[100rem] xs:h-[40vw] md:h-[40vw] slg:h-[35vw] smlg:h-[30vw] xxl:h-[25vw] lxxl:h-[25vw] rounded-[25px] lg:h-[38vw] relative" />
      </div>
      <div className="h-1000 w-[90%] overflow-x-clip lg:w-[70%] mx-auto">
        <Slider {...settings} className="relative z-10" ref={sliderRef}>
          {videos.map((video, index) => (
            <div key={index} className={`w-[60%] relative ${currentSlide === index ? 'z-10' : ''}`}>
              <img src={video.thumbnail} alt="Video Thumbnail" className="w-[100rem] xs:h-[50vw] sm:h-[45vw] md:h-[50vw] lg:h-[38vw] slg:h-[40vw] smlg:h-[35vw] xxl:h-[30vw] lxxl:h-[30vw] xs:rounded-[12px] md:rounded-[25px] relative" />
              {isPlaying && currentSlide === index && (
                <ReactPlayer
                  url={video.url}
                  playing={isPlaying}
                  controls
                  muted
                  width="100%"
                  height="100%"
                  className="absolute inset-0"
                  onPause={handlePause}
                />
              )}
              <div className={`absolute inset-0 flex items-center justify-center ${isPlaying && currentSlide === index ? 'hidden' : ''}`}>
                <img
                  src={PlayIcon}
                  alt="Play Icon"
                  className="xl:w-[90px] lxl:w-[72px] xlg:w-[66px] smlg:w-[60px] msm:w-[54px] md:w-[48px] xs:w-[42px] hover:cursor-pointer"
                  onClick={() => handlePlay(video.url, index)}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="absolute w-[60%] top-0 right-0 opacity-50 hover:opacity-[.5] hover:cursor-pointer mt-7 mr-20 z-0" onClick={handleNext}>
        <img src={vector_calculas} alt="Image 1" className="w-[100rem] xs:h-[40vw] md:h-[40vw] slg:h-[35vw] smlg:h-[30vw] xxl:h-[25vw] lxxl:h-[25vw] rounded-[25px] lg:h-[38vw] relative" />
      </div>
    </div>
  );
};
