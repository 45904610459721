export const buildSearchIndex = `mutation {
    buildSearchIndex{
      status
      error{
        message
      }
    }
  }`;


  export const searchCourses = `query searchCourses($input:String!){
    searchCourse(term:$input){
      courses{
        course{
          code
          name
          regulations
          semester
          university
          aboutSubjects
          aboutSubjects
          aboutChapters
        }
        suggestion
      }
      error{
        message
      }
    }
  }`;