import React from 'react'
import Contactus from '../components/StaticComponent/ContactUs/Contactus'

const ContactUs = () => {
  return (
    <div className='bg-[white]'>
      <Contactus/>
    </div>
   
  )
}

export default ContactUs