import banner_img from "../../../assets/KnowMore/Rectangle 13.png";
import icon from "../../../assets/KnowMore/Vector.svg";
import img_1 from "../../../assets/KnowMore/Rectangle 17.png";
import img_2 from "../../../assets/KnowMore/Rectangle 20.png";
import img_4 from "../../../assets/KnowMore/Rectangle 22.png";
import img_3 from "../../../assets/KnowMore/Rectangle 21.png";
import Form from "./Form";
import ContactDetails from "./ContactDetails";

const KnowMore = () => {
  return (
    <>
      <div className="flex md:flex-row xs:flex-col md:px-[8%] xs:px-[4%] py-12 gap-4">
        <div className="mt-[4%] md:w-[75%] xs:w-[100%] xs:text-center md:text-start">
          <p className="text-[#111F58] font-poppinsBold fs-50w lxl:leading-[54.46px] lg:leading-[52px] leading-[50px] md:pr">
            Bringing Math to Life through Animation and Simplified Teaching
          </p>
          <p className="md:pr-10  xl:text-[30px] lxl:text-[26px] xlg:text-[22px] xs:text-[18px] sm:text-[18px] font-poppinsRegular xlg:leading-[40px] lg:leading-[30px] pt-[4%] ">
            At{" "}
            <span className="font-poppinsBold ">
              Promath Technology Pvt Ltd,
            </span>{" "}
            we're not just changing the way engineering math is taught; we're
            revolutionizing it. Our innovative approach brings complex math
            concepts to life, making learning not just easier but truly
            engaging.
          </p>
          <p className="md:pr-10 font-poppinsRegular xl:text-[30px] lxl:text-[26px] xlg:text-[22px] xs:text-[18px] sm:text-[18px] xlg:leading-[40px] lg:leading-[30px] pt-[4%]">
            The Promath Edge: A Comprehensive Curriculum with{" "}
            <span className="font-poppinsBold ">30+ Math Topics</span>
            Join the digital trend and embrace a curriculum designed to cover
            all your engineering math needs. From Differential Calculus to
            Complex Number Transformations, our platform offers a wide range of
            topics including.
          </p>
        </div>
        <div className="">
          <img src={banner_img} alt="" className="h-[70vh] mt-[10%]" />
        </div>
      </div>
      <div className="bg-[#F0F3FB] px-[8%] pt-[3%] text-center">
        <p className=" text-[#111F58] font-poppinsBold fs-50w lxl:leading-[50px] lg:leading-[45px] leading-[40px]">
          Interactive and Insightful Learning Experience
        </p>
        <p className="fs-30w font-poppinsRegular xlg:leading-[40px] lg:leading-[30px] pt-[2%]">
          Our platform is designed to empower understanding and retention
          through.
        </p>
        <div className="pt-[7%] pb-[4%] flex md:flex-row xs:flex-col gap-6 md:px-[8%]">
          <div className="md:w-[120%]  rounded-[30px] shadow-lg bg-white flex flex-col gap-10 justify-between">
            <p className=" fs-40w text-[#111F58] font-poppinsBold pt-[5%] px-[2%] leading-[35px]">
              ANIMATED VIDEO LECTURES
            </p>
            <img src={icon} alt="" className=" mx-auto" />
            <p className="pb-[5%] fs-25w font-poppinsRegular leading-[30px] h-[30%]">
              Visual and engaging explanations of complex concepts.
            </p>
          </div>
          <div className="md:w-[120%]  rounded-[30px] shadow-lg bg-white flex flex-col gap-10 justify-between">
            <p className="fs-40w text-[#111F58] font-poppinsBold pt-[5%] px-[4%] leading-[35px]">
              Interactive Quizzes
            </p>
            <img src={icon} alt="" className=" mx-auto" />
            <p className="text-center pb-[5%] fs-25w font-poppinsRegular leading-[30px]  h-[30%] px-[4%]">
              Reinforce learning with quizzes after every problem.
            </p>
          </div>
          <div className="md:w-[120%]  rounded-[30px] shadow-lg bg-white flex flex-col gap-10 justify-between">
            <p className="fs-40w text-[#111F58] font-poppinsBold pt-[5%] px-[4%] leading-[35px]">
              Quick Tips and Formulas
            </p>
            <img src={icon} alt="" className=" mx-auto" />
            <p className="pb-[5%] fs-25w font-poppinsRegular leading-[30px] h-[30%] px-[4%]">
              Key information embedded in videos at the right moments. Enhanced
              Interactive Teaching
            </p>
          </div>
        </div>
      </div>
      <div className="px-[8%]">
        <p className="md:w-[70%] w-[100%] mx-auto fs-50w text-center py-12 font-poppinsBold text-[#111F58]">
          Compelling Factors for Institutions to Choose{" "}
          <span className="underline">Maths.Engineering</span> Platform
        </p>
      </div>
      <div className="flex md:flex-row xs:flex-col md:px-[8%] xs:px-[4%] py-12 gap-6 lxl:gap-10 bg-[#F0F3FB]">
        <div className="xs:hidden md:block">
          <img src={img_1} alt="" className=" mt-[10%]" />
        </div>
        <div className="mt-[4%] md:w-[65%] lxl:w-[60%] xs:w-[100%] xs:text-center md:text-start md:pl-[2%]">
          <p className="text-[#111F58] font-poppinsBold fs-40w lxl:leading-[50px] lg:leading-[45px] leading-[40px]">
            Standardized Teaching Methodology
          </p>
          <p className="md:pr-10  fs-30w font-poppinsRegular leading-[50px]  pt-[4%] ">
            The platform employs a standardized teaching methodology, ensuring
            consistency in the delivery of content across all students.
          </p>
          <p className="md:pr-10 font-poppinsRegular fs-30w leading-[50px]  pt-[4%]">
            Faculties, regardless of experience levels, can align their teaching
            with the platform's standardized approach, promoting uniform
            learning outcomes.
          </p>
        </div>
        <div className="md:hidden">
          <img src={img_1} alt="" className=" mt-[10%]" />
        </div>
      </div>
      <div className="flex md:flex-row xs:flex-col md:px-[8%] xs:px-[4%] py-12 gap-4">
        <div className="mt-[4%] md:w-[75%] xs:w-[100%] xs:text-center md:text-start md:pl-[2%]">
          <p className="md:pr-10 lg:text-end text-[#111F58] font-poppinsBold fs-50w lxl:leading-[50px] lg:leading-[45px] leading-[40px]">
            Comprehensive Curriculum Coverage
          </p>
          <p className=" lg:text-end md:pr-10  fs-30w font-poppinsRegular xlg:leading-[40px] lg:leading-[30px] pt-[4%] ">
            <p>Ensure students receive a thorough understanding of the</p>
            <p>engineering mathematics syllabus. Cover all essential</p>
            <p>topics with expert-crafted content aligned to your</p>
            <p>institution curriculum.</p>
          </p>
          <p className=" lg:text-end md:pr-10 font-poppinsRegular fs-30w xlg:leading-[40px] lg:leading-[30px] pt-[4%]">
            <p>Tailor the platform to align with the specific mathematics</p>
            <p>papers offered in each university's engineering curriculum. </p>
            <p>Provide a customized learning experience that directly</p>
            <p>addresses the requirements of each institution</p>
          </p>
        </div>
        <div className="">
          <img src={img_2} alt="" className="h-[70vh] mt-[10%]" />
        </div>
      </div>
      <div className="flex md:flex-row xs:flex-col md:px-[8%] xs:px-[4%] py-12 gap-6 lxl:gap-10 bg-[#F0F3FB]">
        <div className="md:block xs:hidden">
          <img src={img_3} alt="" className="h-[71vh] mt-[10%]" />
        </div>
        <div className="mt-[1.8%] md:w-[65%] lxl:w-[60%] xs:w-[100%] xs:text-center md:text-start md:pl-[2%]">
          <p className="text-[#111F58] font-poppinsBold fs-50w lxl:leading-[50px] lg:leading-[45px] leading-[40px]">
            Continuous Assessment and Feedback
          </p>
          <p className="md:pr-10  fs-30w font-poppinsRegular xlg:leading-[50px] lg:leading-[30px] pt-[3%] ">
            MCQ assessments after Bite Videos provide continuous assessment,
            allowing students to gauge their understanding independently.Instant
            feedback from assessments assists faculties in identifying areas of
            improvement for individual students, enabling targeted mentorship.
          </p>
          <p className="md:pr-10 font-poppinsRegular fs-30w xlg:leading-[50px] lg:leading-[30px] pt-[3%]">
            The platform's performance analytics offer insights into student
            progress, helping faculties track the overall class's understanding.
            Data-driven decision-making enables faculties to address collective
            challenges and enhance teaching strategies.
          </p>
        </div>
        <div className="md:hidden">
          <img src={img_3} alt="" className="h-[71vh] mt-[10%]" />
        </div>
      </div>
      <div className="flex md:flex-row xs:flex-col md:px-[8%] xs:px-[4%] py-12 gap-4">
        <div className="mt-[4%] md:w-[75%] xs:w-[100%] xs:text-center md:text-start md:pl-[2%]">
          <p className="md:pr-10 lg:text-end lg:text-[#111F58] font-poppinsBold fs-50w lxl:leading-[50px] lg:leading-[45px] leading-[40px]">
            24/7 Accessibility
          </p>
          <p className=" lg:text-end md:pr-10  fs-30w font-poppinsRegular xlg:leading-[40px] lg:leading-[30px] pt-[4%] ">
            <p className="py-[2%]">
              {" "}
              The platform's 24/7 accessibility allows students to revisit
            </p>
            <p> lessons at their convenience, reducing the pressure on</p>
            <p className="py-[2%]">
              faculty members to provide constant guidance.
            </p>
          </p>
          <p className="lg:text-end md:pr-10 font-poppinsRegular fs-30w xlg:leading-[40px] lg:leading-[30px] pt-[4%]">
            <p className="py-[2%]">
              Faculties can focus on mentorship and addressing specific
            </p>
            <p>queries, knowing that the platform supports students'</p>
            <p className="py-[2%]"> continuous learning.</p>
          </p>
        </div>
        <div className="">
          <img src={img_4} alt="" className="h-[65vh] mt-[10%]" />
        </div>
      </div>
      <div className="md:bg-[#F0F3FB] md:px-[8%] pt-[3%] mb-[-2%]">
        <p className="bg-[#F0F3FB] text-center text-[#111F58] font-poppinsBold fs-40w lxl:leading-[50px] lg:leading-[45px] leading-[40px] py-[3%]">
          Partner with <span className="underline">Maths.Engineering</span> for
          Enhanced Learning Outcomes
        </p>
        <div className="flex justify-between  ">
          <ContactDetails />
          <Form />
        </div>
      </div>
    </>
  );
};

export default KnowMore;
