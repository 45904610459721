import React, { useContext } from 'react';
import play_store_logo from "../../assets/main-logo-half.png";
import { PLAY_STORE_URL } from "../../constants";
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../storeContext';

interface DownloadPopupProps {

}

const DownloadPopup: React.FC<DownloadPopupProps> = () => {
  const { courseStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const navigateToPlayStore = () => {
    window.open(PLAY_STORE_URL, "_blank");
  };
  const navigateToCourse = () => {
    navigate("/course");
      courseStore.universityFilterCode = "";
      window.scrollTo(0, 0);
  }

  return (
    
    <div className='z-[2000] w-[100%]   fixed bottom-0 '>
      <div className=' w-[90%] bg-[white] flex flex-row gap-2 justify-center mx-auto rounded-t-[10px] pb-2'>
        <div onClick={navigateToCourse} className="border border-[1px] border-[#464646] md:hidden w-[50%] h-[55px] rounded-[10px]  h-[55px] bg-[#F6AE35] flex justify-center items-center">
          <p className="text-[14px] text-[#111F58] font-poppinsSemibold mx-2 text-center leading-[14px]">EXPLORE OUR COURSES</p>
        </div>
        <div onClick={navigateToPlayStore} className="border border-[1px] border-[#464646] md:hidden w-[50%] h-[55px] rounded-[10px] h-[55px] bg-[#88C743] flex justify-center items-center">
          <p className="text-[14px] text-[#111F58] font-poppinsSemibold mx-2 text-center leading-[14px]">INSTALL OUR ANDROID APP NOW!</p>
        </div>
      </div>
    </div>
    
  );
};

export default DownloadPopup;