import React from "react";
import Slider from "react-slick";
import first from "../../../assets/HomePage/Group 1799.png";
import second from "../../../assets/Mask Group 13.png";
import seventh from "../../../assets/Mask Group 16.png";
import visvesvaraya from "../../../assets/HomePage/Group 1803.png";
import fourth from "../../../assets/Mask Group 14.png";
import fifth from "../../../assets/HomePage/Group 1802.png";
import sixth from "../../../assets/Mask Group 15.png";
import eight from "../../../assets/Mask Group 17.png";
import "./Slider.css"; // Import your custom styles

const ImageCollage = ({ initialOpacity }: { initialOpacity: number }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500, // Set the autoplay speed in milliseconds (adjust as needed)
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1700, // Set the autoplay speed in milliseconds (adjust as needed)
  };


  return (
    <div className="relative lg:ml-[-120px] md:mix-blend-multiply  flex flex-col gap-4 overflow-hidden">
      {/* First Row */}
      <div
        className="slider-container w-[100%] lg:pl-[120px] overflow-hidden lg:ml-[8%]"  
      >
        <Slider {...settings} className="your-slider-class">
          {[first, second,  seventh,visvesvaraya,first, second, seventh,first, second,seventh].map((image, index) => (
            <div key={index} className="pr-3">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="slider-image h-[17vw] lg:h-[9vw] 2xl:h-[9vw]  w-[40vw]"
              />
            </div>
          ))}
        </Slider>
        {/* <div className=" md:block hidden h-full bg-gradient-to-r from-white/40 from-10% to-transparent absolute top-0 left-0 right-0"></div> */}
        <div className="md:block hidden absolute inset-0 overflow-hidden">
          <div className="gradient-layer absolute inset-0"></div>
          <div className="blur-layer absolute inset-100"></div>
        </div>
      </div>

      {/* Second Row */}
      <div
        className="slider-container relative "
      >
        <Slider {...settings2} className="your-slider-class">
          {[fourth, fifth, sixth, eight,fourth,visvesvaraya, fifth, sixth, eight,fourth, fifth, sixth, eight].map((image, index) => (
            <div key={index} className="pr-3">
              <img
                src={image}
                alt={`Image ${index + 5}`}
                className="slider-image h-[17vw] lg:h-[9vw] 2xl:h-[9vw]  w-[40vw]"
              />
            </div>
          ))}
        </Slider>
        {/* <div className="md:block hidden h-full bg-gradient-to-r from-white/40 from-10% to-transparent absolute top-0 left-0 right-0"></div> */}
        <div className="md:block hidden absolute inset-0 overflow-hidden">
          <div className="gradient-layer absolute inset-0"></div>
          <div className="blur-layer absolute inset-100"></div>
        </div>
      </div>
    </div>
  );
};

export default ImageCollage;
