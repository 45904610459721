import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../storeContext'
import { observer } from 'mobx-react';
import forwordIcon from "../../assets/MegaMenu/Group 1651.svg"
import hoverIcon from "../../assets/MegaMenu/Group 1649.svg"
import backArrow from "../../assets/MegaMenu/Group 1593.svg"
import { useNavigate } from 'react-router-dom';

interface MegaMenuProps {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  
}
const MegaMenu: React.FC<MegaMenuProps> = ({setIsVisible}) => {
  const { courseStore } = useContext(StoreContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState("Tamil Nadu");
  const [selectedUniversity, setSelectedUniversity] = useState<string|null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleNavigate = (e: any) => {
    navigate(`/${e}`)
    setIsVisible(false)
    window.scrollTo(0, 0);
  };

  const fetchUniversities = async (stateCode: string) => {
    await courseStore.fetchUniversities(stateCode);
  };

  const onLocationChange = (value: any) => {
    setSelectedLocation(value);
    setSelectedUniversity(null);
    courseStore.universityFilterCode = "";
    fetchUniversities(value);
  };
  const onUniversityChange = (value: any) => {
    setSelectedUniversity(value);
    courseStore.universityFilterCode = value;
  };


  const handleLocationClick = (locationCode: any) => {
    setSelectedLocation(locationCode);
    setSelectedUniversity(null);
    setCurrentStep(2);
    onLocationChange(locationCode);
  };

  const handleUniversityClick = (universityCode: any) => {
    setCurrentStep(3);
    onUniversityChange(universityCode);
  };

  const handleBackClick = () => {
    setSelectedLocation("Tamil Nadu");
    setSelectedUniversity(null);
    setCurrentStep(currentStep - 1);
  };
  useEffect(()=>{
    if(courseStore.locations.length<1){
      courseStore.fetchAllLocations();
      courseStore.fetchUniversities(selectedLocation);
    }
  },[])
  useEffect(()=>{setSelectedUniversity(courseStore.universityFilterCode)},[courseStore.universityFilterCode])


  return (
    <>
      <div className="w-[60%] mega-menu md:block xs:hidden">
        <div className="w-[100%] h-[45vh] flex rounded-[5px] mx-auto bg-white mega-menu-desktop">
          <div className="w-[30%] h-[100%] flex flex-col gap-[1.5px] rounded-[4px] overflow-y-auto">
            {courseStore.locations?.slice().sort((a, b) => {
              const sequence = ["Tamil nadu", "Karnataka", "Telangana", "Andhra Pradesh"];
              const indexA = sequence.indexOf(a.name);
              const indexB = sequence.indexOf(b.name);
              return indexA - indexB;
            })
            .map((el, ind) => {
              return (
                <div
                  key={ind}
                  className={` pl-4 pr-4 p-3  fs-15w font-poppinsMedium cursor-pointer ${
                    selectedLocation === el.code
                      ? "bg-[#FFFFFF] text-[#1067E4]"
                      : " bg-[#22AAE1] text-[#FFFFFF]"
                  }`}
                  onClick={() => {
                    onLocationChange(el.code);
                  }}
                >
                  <p>{el.name}</p>
                </div>
              );
            })}
          </div>
          <div className="w-[40%] h-[100%] overflow-y-auto">
            {courseStore.universities.length === 0 ? (
              <div className='font-poppinsSemibold text-[#111F58] bg-[#F0F3FB]  rounded-[5px] my-[2%] mx-[4%] fs-15w p-3 pl-lg-4 pr-lg-4  overflow-hidden cursor-pointer'><p>Other Universities</p></div>
              
            ) : (
              courseStore.universities?.map((el, ind) => (
                <div
                  key={ind}
                  className={`font-poppinsSemibold text-[#111F58] rounded-[5px] my-[2%] mx-[4%] fs-15w p-3 pl-lg-4 pr-lg-4 overflow-hidden cursor-pointer ${
                    selectedUniversity === el.university.code
                      ? "bg-[#E1E7F7] underline"
                      : "bg-[#F0F3FB]"
                  }`}
                  onClick={() => {
                    onUniversityChange(el.university.code);
                  }}
                >
                  <p>{el.university.name}</p>
                </div>
              ))
            )}
            <p onClick={()=>handleNavigate("topic")} className='bg-[#E1E7F7] font-poppinsSemibold text-[#111F58] rounded-[5px] my-[2%] mx-[4%] fs-15w p-3 pl-lg-4 pr-lg-4 overflow-hidden cursor-pointer'>Other Universities</p>
          </div>
          <div className="w-[40%] h-[100%] bg-[#E1E7F7] flex flex-col  justify-between mb-[3rem] ">
            <div className="h-[75%] overflow-y-auto">
              { courseStore.universities.length != 0 ? courseStore.universities
                .filter((ele) =>
                  courseStore.isUniversitySelected
                    ? ele.university.code === courseStore.universityFilterCode
                    : ele
                )
                .map((filteredUniversity, index) => (
                  <div key={filteredUniversity.university.code}>
                    {filteredUniversity.courses?.map((item, id) => {

                      return (
                        <div
                          key={id}
                          className="font-poppinsSemibold bg-[#FFFFFF] rounded-[5px] my-[2%] mx-[4%] fs-15w text-[#111F58] p-3 pl-lg-4 pr-lg-4  cursor-pointer"
                          onClick={() => {
                            handleNavigate(`course/${item.code}/${item.name.split(" ").join("")}`);
                            setIsVisible(false);
                          }}
                        >
                          {item.name  + " (" + item.subjectCode + ")"}
                        </div>
                      );
                    })}
                  </div>
                )):courseStore.topics.map((topic, index) => (
                  <div
                    key={index}
                    className="font-poppinsSemibold bg-[#FFFFFF] rounded-[5px] my-[2%] mx-[4%] fs-15w text-[#111F58] p-3 pl-lg-4 pr-lg-4  cursor-pointer"
                    onClick={() => {
                      handleNavigate(`course/${topic.code}/${topic.name.split(" ").join("")}`);
                      setIsVisible(false);
                    }}
                  >
                    {topic.name  + ` ${topic.subjectCode ?(` + ${topic.subjectCode} + `) : ""}` }
                  </div>))}
            </div>
            <div className="flex justify-center ">
              <button className="bg-[#88C743] w-[90%] px-[1.5rem] py-[0.7rem] mb-[3%] font-poppinsSemibold rounded-[10px] fs-20w" onClick={() => {
                        handleNavigate('topic');
                        setIsVisible(false);
                      }} >
                VIEW ALL TOPICS
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ------------Mobile View --------------------------------------*/}
      {currentStep === 1 && (
        <div className="w-[100%] h-[70vh] mobile-mega-menu-1 rounded-[4px] pt-[2%] bg-[#22AAE1] md:hidden xs:block overflow-y-auto">
          {courseStore.locations?.slice().sort((a, b) => {
              const sequence = ["Tamil nadu", "Karnataka", "Telangana", "Andhra Pradesh"];
              const indexA = sequence.indexOf(a.name);
              const indexB = sequence.indexOf(b.name);
              return indexA - indexB;
            })?.map((el, ind) => {
            return (
              <div
                key={ind}
                className="w-[90%] flex items-center justify-between text-[#FFFFFF] fs-25w mx-auto px-[8%] py-[2%] border-b-2 font-poppinsMedium cursor-pointer"
                onClick={() => handleLocationClick(el.code)}
              >
                <div>
                  <p>{el.name}</p>
                </div>
                <img src={forwordIcon} alt="forword icon" />
              </div>
            );
          })}
        </div>
      )}

      {currentStep === 2 && (
        <div className="w-[100%] h-[50vh] mobile-mega-menu-1 pt-[2%] bg-white md:hidden xs:block  overflow-y-auto">
          <img
            src={backArrow}
            className="mx-[4%] pt-[2%] "
            onClick={handleBackClick}
            alt="Back-Arrow"
          />
          {/* Universities section */}
          {courseStore.universities.length === 0 ?  <div
              className="flex items-center justify-between font-poppinsSemibold hover:bg-[#E1E7F7] hover:underline cursor-pointer text-[#111F58] bg-[#F0F3FB] rounded-[5px] my-[3%] mx-[4%] fs-25w p-[0.2rem] px-[1rem] py-[2%] overflow-hidden"
              onClick={() => handleUniversityClick(null)}
            ><p>Others Universities</p></div> :courseStore.universities?.map((el, ind) => (
            <div
              key={ind}
              className="flex items-center justify-between font-poppinsSemibold hover:bg-[#E1E7F7] hover:underline cursor-pointer text-[#111F58] bg-[#F0F3FB] rounded-[5px] my-[3%] mx-[4%] fs-25w p-[0.2rem] px-[1rem] py-[2%] overflow-hidden"
              onMouseEnter={() => setHoveredRow(ind)}
              onMouseLeave={() => setHoveredRow(null)}
              onClick={() => handleUniversityClick(el.university.code)}
            >
              <div>
                <p>{el.university.name}</p>
              </div>
              <img
                src={hoveredRow === ind ? hoverIcon : forwordIcon}
                className="text-blue"
                alt="forword icon"
              />
            </div>
          ))}
          <div
              className="flex items-center justify-between font-poppinsSemibold hover:bg-[#E1E7F7] hover:underline cursor-pointer text-[#111F58] bg-[#F0F3FB] rounded-[5px] my-[3%] mx-[4%] fs-25w p-[0.2rem] px-[1rem] py-[2%] overflow-hidden"
              onClick={() => handleNavigate("topic")}
            ><p>Others Universities</p></div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="w-[100%] h-[50vh] mobile-mega-menu-1 pt-[2%] bg-[#E1E7F7] flex flex-col  justify-between overflow-y-auto">
          <div>
            <img
              src={backArrow}
              className="mx-[4%] pt-[2%]"
              onClick={handleBackClick}
              alt="Back-Arrow"
            />
            {/* Courses section */}
            {courseStore.universities.length === 0 ? courseStore.topics.map((topic, index) => (
                  <div
                    key={index}
                    className="font-poppinsSemibold bg-[#FFFFFF] rounded-[5px] my-[2%] mx-[4%] fs-25w text-[#111F58] px-[0.6rem] py-[0.4rem] cursor-pointer"
                    onClick={() => {
                      handleNavigate(`course/${topic.code}`);
                      setIsVisible(false);
                    }}
                  >
                    {topic.name + " (" + topic.code + ")"}
                  </div>)) : courseStore.universities
              .filter((ele) =>
                courseStore.isUniversitySelected
                  ? ele.university.code === courseStore.universityFilterCode
                  : ele
              )
              .map((filteredUniversity, index) => (
                <div key={filteredUniversity.university.code}>
                  {filteredUniversity.courses?.map((item, id) => (
                    <div
                      key={id}
                      className="h-[10%] font-poppinsSemibold bg-[#FFFFFF] rounded-[5px] my-[3%] mx-[4%] fs-25w text-[#111F58] p-[0.6rem]"
                      onClick={() => {
                        handleNavigate(`course/${item.code}/${item.name.split(" ").join("")}`);
                        setIsVisible(false);
                      }}
                    >
                      {item.name + " (" + item.subjectCode + ")" }
                    </div>
                  ))}
                </div>
              ))}
          </div>
          <div className="flex justify-center ">
            <button
              className="bg-[#88C743] w-[90%] px-[1.5rem] py-[0.7rem] mb-[5%] font-poppinsSemibold rounded-[10px]"
              onClick={() => {
                handleNavigate("topic");
                setIsVisible(false);
              }}
            >
              VIEW ALL TOPICS
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(MegaMenu)