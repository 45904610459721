import React, { useEffect, useRef, useState } from 'react'
import Banner from '../components/CourseDetails/Banner/Banner'
import { EnquiryForm } from '../components/CourseDetails/Enquiry'
import CourseCurriculum from '../components/CourseDetails/CourseCurriculum/CourseCurriculum';
import CourseDetailsCard from '../components/CourseDetails/CourseDetailsCard/CourseDetailsCard';
import Advantages from '../components/CourseDetails/Advantages/Advantages';
import FAQ from '../components/CourseDetails/FAQ/FAQ';
import LeanerSpeak from '../components/CourseDetails/learnersSpeak/LeanerSpeak';
import { Footer } from '../components/Footer/Footer';
import FloatButton from '../components/StaticComponent/FloatButton';


const CourseDetails = () => {

  return (
    <div className='bg-white relative' >
      <Banner/>
      <CourseDetailsCard/>
      <CourseCurriculum/>
      <Advantages/>
      <FAQ/>
      <LeanerSpeak/>
      <EnquiryForm/>
      <FloatButton prop="DOWNLOAD THE APP AND START LEARNING FOR FREE" path=""/>
    </div>
  )
}

export default CourseDetails